import {
  Button,
  Grid,
  Popover,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import { DateRange } from "react-date-range";
import {
  customDayContent,
  getHolidayAdjustedEndDate,
} from "utils/helpers/dateHelper";
import { ReactComponent as CalendarIcon } from "icons/calendar-2.svg";

const DateRangePicker = ({
  readOnly,
  startDate,
  endDate,
  holidayList,
  duration,
  hanldeDataChange,
  label,
  isDeliverable,
  index,
  orignalStartDate,
  orignalEndDate,
  dateLabel
}) => {
  const [dateRange, setDateRange] = useState([]);
  const [delStart, setDelStart] = useState();
  const [delEnd, setDelEnd] = useState();
  const [expectedDateRange, setExpectedDateRange] = useState();
  const [annchorEl1, setAnchorEl] = useState(null);

  useEffect(() => {
    setExpectedDateRange(null);
  }, [duration, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange([
        {
          startDate: new Date(
            moment(startDate).format("YYYY-MM-DDT00:00:00.000")
          ),
          endDate: new Date(moment(endDate).format("YYYY-MM-DDT00:00:00.000")),
          key: "selection",
        },
      ]);
      setDelStart(startDate);
      setDelEnd(endDate);
    }
  }, [startDate, endDate]);

  const handleDateChange = (selection) => {
    const endDate = new Date(
      getHolidayAdjustedEndDate(
        selection.startDate,
        Number(duration),
        holidayList
      )
    );
    setExpectedDateRange({
      startDate: new Date(moment(selection.startDate)),
      endDate: new Date(moment(endDate)),
    });
    setDateRange([
      {
        startDate: new Date(moment(selection.startDate)),
        endDate: new Date(moment(selection.endDate)),
        key: "selection",
      },
    ]);
  };

  const handleOpenDateRange = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setDateChange = () => {
    hanldeDataChange(
      moment(dateRange[0].startDate).format("YYYY-MM-DDT00:00:00.000[Z]"),
      moment(dateRange[0].endDate).format("YYYY-MM-DDT00:00:00.000[Z]"),
      isDeliverable ? index : label
    );
    setAnchorEl(null);
  };

  return (
    <div className="wd-100">
      <Grid container columns={12} spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label={orignalStartDate? `New ${dateLabel?dateLabel:""} Start Date`:"Start Date"}
            onClick={(e) => (!readOnly ? handleOpenDateRange(e) : null)}
            value={moment(delStart)?.format("MM/DD/YY")}
            helperText={orignalStartDate?`Orignal Date: ${orignalStartDate}`:""}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label={orignalEndDate? `New ${dateLabel?dateLabel:""} End Date`:"End Date"}
            onClick={(e) => (!readOnly ? handleOpenDateRange(e) : null)}
            value={moment(delEnd)?.format("MM/DD/YY")}
            helperText={orignalEndDate?`Orignal Date: ${orignalEndDate}`:""}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      </Grid>

      <Popover
        open={Boolean(annchorEl1)}
        anchorEl={annchorEl1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div>
          <DateRange
            editableDateInputs={true}
            onClose={() => setAnchorEl(null)}
            disabledDates={holidayList?.map(
              (holiday) =>
                new Date(moment(holiday).format("YYYY-MM-DDT00:00:00.000[Z]"))
            )}
            dayContentRenderer={(day) =>
              customDayContent(day, expectedDateRange)
            }
            onChange={({ selection }) => {
              handleDateChange(selection);
            }}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
        </div>
        <div className="d-jsb-r mb-5">
          <Button variant="contained" onClick={() => setAnchorEl(null)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-5 mr-5"
            onClick={setDateChange}
          >
            Save
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default memo(DateRangePicker);
