import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setAccessToken,
  setRefreshToken,
  setCurrentUser,
} from "redux/actions/authActions";
import { getUsers } from "redux/actions/users";
import { getOrganizations } from "redux/actions/organizations";
import { PERMISSION_TYPES } from "utils/constants";
import { getFilteringData } from "redux/actions/dashboards";
import { fetchNotifications } from "redux/actions/notificationActions";
import { getModulePreferences } from "redux/actions/modulePreferenceActions";

const InitProvider = () => {
  const dispatch = useDispatch();

  const { accessToken, permissions, currentUser, isHrActive } = useSelector(({ auth }) => {
    const { accessToken, currentUser } = auth;
    const { permissions, organization } = currentUser;

    return { accessToken, permissions, organization, currentUser };
  });

  useEffect(() => {
    const remembered = localStorage.accessToken && localStorage.refreshToken;
    const accessToken = localStorage.accessToken || sessionStorage.accessToken;
    const refreshToken =
      localStorage.refreshToken || sessionStorage.refreshToken;
    const currentUser = localStorage.currentUser;

    if (!!accessToken) {
      dispatch(setAccessToken(accessToken, remembered));
    }

    if (!!refreshToken) {
      dispatch(setRefreshToken(refreshToken, remembered));
    }

    if (!!currentUser) {
      dispatch(setCurrentUser(JSON.parse(currentUser)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getUsers());
      // dispatch(getModulePreferences(currentUser._id))
      dispatch(getFilteringData());
    }
  }, [dispatch, accessToken, currentUser._id]);

  useEffect(() => {
    if (
      accessToken &&
     ( [
        PERMISSION_TYPES.superAdmin,
        PERMISSION_TYPES.admin,
      ].includes(permissions) || isHrActive)
    ) {
      dispatch(getOrganizations());
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToken, permissions]);

  useEffect(() => {
    if (accessToken && currentUser?._id) {
      dispatch(fetchNotifications({ userId: currentUser._id, skip: 0 }));
    }
  }, [dispatch, accessToken, currentUser._id]);

  useEffect(() => {
    if (currentUser?._id && currentUser?.organization && accessToken) {
      dispatch(getModulePreferences(currentUser?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?._id, currentUser?.organization, accessToken]);

  return <div />;
};

export default memo(InitProvider);
