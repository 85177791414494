import React, { memo } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff',
    backgroundColor: '#808080c2'
  },
}));


function Loader() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}

export default memo(Loader);
