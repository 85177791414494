import React, { memo, useState } from "react";
import {
  MenuItem,
  Select,
  Typography,
  ListItemText,
  Checkbox,
  Input,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Tooltip } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 148,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  disabledSelect: {
    "& .MuiSelect-select.Mui-disabled": {
      color: "black",
    },
  },
}));

const FilterSelect = React.forwardRef(
  (
    {
      label,
      placeholder,
      emptyValue,
      items,
      error,
      fullWidth = false,
      multiple = false,
      nullable = false,
      isResource = false,
      keys = { label: "LABEL", value: "VALUE" },
      className,
      value,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const getSelectedItemEmail = (value) => {
      if (!value || !isResource) return null;
      const selectedItem = items.find((item) => item[keys.value] === value);
      return selectedItem ? selectedItem.email : null; 
    };

    return (
      <Box
        className={clsx(classes.root, className, {
          [classes.fullWidth]: fullWidth,
        })}
      >
        {!!label && (
          <Typography color="textSecondary" className={classes.label}>
            {label}
          </Typography>
        )}
        <Tooltip title={isResource && !isDropdownOpen ? getSelectedItemEmail(value) : null}>
        <Select
          ref={ref}
          className={classes.disabledSelect}
          labelId="demo-simple-select-placeholder-label-label-1"
          id={`demo-simple-select-placeholder-label-${value}`}
          displayEmpty
          multiple={multiple}
          error={!!error}
          input={<Input />}
          value={value || (multiple ? [] : "")}
          onOpen={() => setIsDropdownOpen(true)} 
          onClose={() => setIsDropdownOpen(false)} 
          renderValue={(value) =>
            multiple
              ? value
                  .map(
                    (v) =>
                      items.find((item) => item[keys.value] === v)?.[keys.label]
                  )
                  .filter((d) => d)
                  .join(", ")
              : isResource? items.find((item) => item[keys.value] === value)?.name:

               items.find((item) => item[keys.value] === value)?.[keys.label]
          }
          {...rest}
        >
          {placeholder && (
            <MenuItem value="" disabled={!nullable}>
              {placeholder}
            </MenuItem>
          )}
          {emptyValue && <MenuItem value="">None</MenuItem>}
          {items.map((item, index) => (
            <MenuItem key={index} value={item[keys.value]}>
              {multiple && (
                <Checkbox
                  checked={value?.includes(item[keys.value]) || false}
                />
              )}
              <ListItemText primary={item[keys.label]} />
            </MenuItem>
          ))}
        </Select>
        </Tooltip>


        {!!error && (
          <Typography color="error" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </Box>
    );
  }
);

export default memo(FilterSelect);
