import TYPES from "utils/constants/action-types";
import * as API from "services/api-notification";

export const fetchNotifications =
  (params = {}) =>
  (dispatch) => {
    API.getNotifications(params)
      .then((response) => {
        dispatch({ type: TYPES.FETCH_NOTIFICATION, payload: response });
      })
      .catch((error) => console.log("[getNotification] error => ", error));
  };

export const updateNotification =
  (params = {}, readStatus) =>
  (dispatch) => {
    dispatch({
      event_name: "update_notification",
      payload: {
        params,
      },
      type: "socket/emit",
    });
    if (!readStatus) {
      dispatch({ type: TYPES.UPDATE_NOTIFICATION, payload: params });
    }
  };
