import { getJQ, put, del, post, get, patch } from "services/axios";

const getUsers = async (data) => await getJQ("/v2/users", data);

const getUser = async (id) => await getJQ(`/v2/users/${id}`);
const getUserProfile = async (id) => await getJQ(`/v2/users/${id}/profile`);

const createUser = async (params) => {
  return await post("users", params);
};

const getAccessibleFeatures = async (id, projectId) =>
  await getJQ(`users/${id}/accessibleFeatures`, { project: projectId });

const getUserModulePreferences = async (id) =>
  await get(["users", id, "modulePreference"]);

const updateUserModulePreferences = async (id, params) =>
  await patch(`/users/${id}/modulePreference`, params);

const updateUser = async (params) => {
  return await put("users", params);
};

const deleteUser = async (params) => {
  return await del("users", params);
};

const changeUserPassword = async (params) => {
  return await post(["users", "change-password"], params);
};

const getWorkloadReportEntries = async (orgId, start, end) =>
  await get(`/v2/workloadReport/organization/${orgId}/${start}/${end}`);

export {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  changeUserPassword,
  getUser,
  getAccessibleFeatures,
  getUserModulePreferences,
  updateUserModulePreferences,
  getWorkloadReportEntries,
  getUserProfile,
};
