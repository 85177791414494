import { keyMirror } from "utils/helpers/utility";

const ActionTypes = keyMirror({
  // auth
  SET_CURRENT_USER: null,
  SET_ACCESS_TOKEN: null,
  SET_REFRESH_TOKEN: null,
  SET_PASSWORD_RESET_TOKEN: null,

  // loading
  SET_LOADING_STATUS: null,

  // users
  FETCH_USERS: null,

  // organizations
  FETCH_ORGANIZATIONS: null,
  SET_SELECTED_ORGANIZATION: null,
  SET_SELECTED_DEPARTMENTS: null,

  // events
  FETCH_EVENTS: null,
  FETCH_LATEST_EVENTS: null,

  // dashboard
  FETCH_DASHBOARDS: null,
  FETCH_REPORTS: null,
  GET_FILTERING_DATA: null,

  // sows
  FETCH_SOWS: null,
  SET_SELECTED_SOW: null,
  SET_SOW_FILE_URL: null,

  // projects
  FETCH_PROJECTS: null,
  ADD_PROJECT: null,
  EDIT_PROJECT: null,
  DELETE_PROJECT: null,
  FETCH_SYSTEM_TRENDS: null,
  CREATE_META_SYSTEM: null,
  FETCH_META_SYSTEMS: null,
  FETCH_SYSTEMS_WORKFLOWS: null,
  DELETE_META_SYSTEM: null,
  UPDATE_META_SYSTEM: null,
  UPDATE_MULTIPLE_META_SYSTEMS: null,
  DUPLICATE_META_SYSTEM: null,
  UPDATE_DELIVERABLES: null,
  UPDATE_DELIVERABLE_NOTES: null,
  UPDATE_TASKS: null,
  UPDATE_SUBTASK: null,
  GET_INSIGHTS: null,
  GET_PHASES: null,
  GET_PHASE_DETAIL: null,
  GET_MULTI_PHASE_DETAIL: null,
  FETCH_CUSTOM_NOTIFICATIONS: null,
  CLEAR_CUSTOM_NOTIFICATIONS: null,
  DELETE_CUSTOM_NOTIFICATIONS: null,
  UPDATE_ESCALATIONS: null,
  UPDATE_SUBESCALATION: null,
  SET_ACTIVE_PROJECT: null,
  SET_QMS_PROJECT: null,

  // popup
  SET_POPUP: null,
  SET_POPUP_TEXT: null,

  // customPopup
  SET_CUSTOM_POPUP: null,
  SET_CUSTOM_POPUP_TEXT: null,

  // workflowTemplates
  CREATE_WT: null,
  UPDATE_WT: null,
  DELETE_WT: null,
  FETCH_WT: null,
  DUPLICATE_WT: null,
  CREATE_WTD: null,
  UPDATE_WTD: null,
  DUPLICATE_TEMPLATE: null,

  // GENERAL
  INITIALIZE_SOCKET: null,
  DISCONNECT_SOCKET: null,

  //notifications
  FETCH_NOTIFICATION: null,
  ADD_NOTIFICATION: null,
  UPDATE_NOTIFICATION: null,

  //modulePreferences
  SET_MODULE_PREFERENCE: null,
});

export default ActionTypes;
