import _ from "lodash";

export const updatePool = (oldSet, newSet) =>
  newSet.reduce((acc, updated) => {
    const old = acc.find((old) => old._id === updated._id);
    if (old) {
      return acc.map((old) => (old._id === updated._id ? updated : old));
    } else {
      return [...acc, updated];
    }
  }, oldSet);

export const roundMainSystem = (s) => ({
  ...s,
  status: _.round(s.status, 2),
  deliverables: s.deliverables.map((d) => ({
    ...d,
    calculated: {
      ...d?.calculated,
      weight: _.round(d?.calculated?.weight || 0, 2),
      EV: _.round(d?.calculated?.EV || 0, 2),
      PV: _.round(d?.calculated?.PV || 0, 2),
      totalPlannedHours: d?.calculated?.totalPlannedHours || 0,
      systemEV: _.round(d?.calculated?.systemEV || 0, 2),
      systemPV: _.round(d?.calculated?.systemPV || 0, 2),
      systemStatus: _.round(d?.calculated?.systemStatus || 0, 2),
    },
  })),
  calculated: {
    ...s?.calculated,
    EV: _.round(s?.calculated?.EV || 0, 2),
    PV: _.round(s?.calculated?.PV || 0, 2),
    effort: _.round(s?.calculated?.effort || 0, 2),
  },
});

export const roundMainSystemTasks = (s) => ({
  ...s,
  status: _.round(s?.status || 0, 2),
  tasks: s?.tasks,
  escalations: s?.escalations,
});
