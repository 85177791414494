import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";
import theme from "theme";
import Routes from "routes";
// import ReactGA from "react-ga4"
import "App.css";
// import { APP_GTAG_ID } from "config";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  // intialising google analytics tracker
  // const GA_ID = APP_GTAG_ID;
  // ReactGA.initialize(GA_ID);
  useEffect(()=>{
    const isWindows = navigator.platform.toLowerCase().startsWith("win");
    if(isWindows){
      const css = `
      ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;  
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #c1c1c1;
      }
      `
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      document.head.appendChild(style)
    }
  },[])

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Vektor DynamixE"
        defaultTitle="Vektor DynamixE - Admin Dashboard"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
