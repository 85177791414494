import moment from "moment";
import _ from "lodash";

export const DeviationHelper = () => {
  const DEVIATIONS_LIST_TABLE_COLUMN = [
    { id: "deviationNumber", label: "Deviation No.", minWidth: 100 },
    { id: "title", label: "Deviation Title", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    {
      id: "actualDeviationNumber",
      label: "Actual Deviation No.",
      minWidth: 100,
    },
  ];

  const DEVIATION_STATUS = [
    { value: "DRAFT", label: "Draft" },
    { value: "REVIEW_PENDING", label: "Pending Review" },
    { value: "REVIEWED", label: "Reviewed" },
    { value: "APPROVAL_PENDING", label: "Pending Pre-Approval" },
    { value: "APPROVED", label: "Pre-Approved" },
    { value: "INITIATION_COMPLETED", label: "Initiation Completed" },
    { value: "APPROVAL_REJECTED", label: "Pre-Approval Rejected" },
    { value: "INVESTIGATION_DRAFT", label: "Investigation Draft" },
    {
      value: "INVESTIGATION_REVIEW_PENDING",
      label: "Investigation Review Pending",
    },
    {
      value: "INVESTIGATION_REVIEWED",
      label: "Investigation Reviewed",
    },
    {
      value: "INVESTIGATION_APPROVAL_PENDING",
      label: "Investigation Approval Pending",
    },
    {
      value: "INVESTIGATION_APPROVAL_REJECTED",
      label: "Investigation Approval Rejected",
    },
    {
      value: "INVESTIGATION_APPROVED",
      label: "Investigation Approved",
    },
    { value: "INVESTIGATION_COMPLETED", label: "Investigation Completed" },
    { value: "CAPA_EXECUTION_PENDING", label: "CAPA Execution Pending" },
    {
      value: "CAPA_EXECUTION_REVIEW_PENDING",
      label: "CAPA Execution Review Pending",
    },
    {
      value: "CAPA_EXECUTION_REVIEWED",
      label: "CAPA Execution Reviewed",
    },
    {
      value: "CAPA_EXECUTION_APPROVAL_PENDING",
      label: "CAPA Execution Approval Pending",
    },
    { value: "CAPA_EXECUTED", label: "CAPA Executed" },
    { value: "CAPA_COMPLETED", label: "CAPA Execution Completed" },
    { value: "CLOSURE_DRAFT", label: "Closure Draft" },
    { value: "CLOSURE_REVIEW_PENDING", label: "Closure Review Pending" },
    { value: "CLOSURE_REVIEWED", label: "Closure Reviewed" },
    {
      value: "CLOSURE_APPROVAL_PENDING",
      label: "Closure Approval Pending",
    },
    { value: "CLOSURE_REJECTED", label: "Closure Rejected" },
    { value: "CLOSED", label: "Closed" },
  ];

  const DEVIATION_DEFAULT_VALUE = {
    deviationNumber: undefined,
    actualDeviationNumber: "",
    creationDate: null,
    incidentDate: null,
    deviationType: null,
    department: null,
    workflow: null,
    status: "DRAFT",
    cqv: false,
    title: "",
    impactedSystems: [],
    associatedDeliverables: [],
    impactedDocuments: [],
    deviationDescription: "",
    initialInvestigation: "",
    impactRequirement: "",
    initialAssessment: "",
    associatedUsers: { draft: [] },
  };

  return {
    DEVIATIONS_LIST_TABLE_COLUMN,
    DEVIATION_STATUS,
    DEVIATION_DEFAULT_VALUE,
  };
};

export const DEVIATION_STAGE = [
  {
    id: 0,
    label: "Initiation",
    status: [
      "DRAFT",
      "REVIEW_PENDING",
      "REVIEWED",
      "APPROVAL_PENDING",
      "APPROVED",
      "INITIATION_COMPLETED",
      "APPROVAL_REJECTED",
    ],
  },
  {
    id: 1,
    label: "Investigation",
    status: [
      "APPROVED",
      "INITIATION_COMPLETED",
      "INVESTIGATION_DRAFT",
      "INVESTIGATION_REVIEW_PENDING",
      "INVESTIGATION_REVIEWED",
      "INVESTIGATION_APPROVAL_PENDING",
      "INVESTIGATION_APPROVED",
      "INVESTIGATION_COMPLETED",
      "INVESTIGATION_APPROVAL_REJECTED",
    ],
  },
  {
    id: 2,
    label: "CAPA Execution",
    status: [
      "INVESTIGATION_APPROVED",
      "INVESTIGATION_COMPLETED",
      "CAPA_EXECUTION_PENDING",
      "CAPA_EXECUTION_REVIEW_PENDING",
      "CAPA_EXECUTION_REVIEWED",
      "CAPA_EXECUTION_APPROVAL_PENDING",
      "CAPA_EXECUTED",
      "CAPA_COMPLETED",
    ],
  },
  {
    id: 3,
    label: "Closure",
    status: [
      "CAPA_EXECUTED",
      "CAPA_COMPLETED",
      "CLOSURE_DRAFT",
      "CLOSURE_REVIEW_PENDING",
      "CLOSURE_REVIEWED",
      "CLOSURE_APPROVAL_PENDING",
      "CLOSURE_REJECTED",
      "CLOSED",
    ],
  },
];

export const INITIATION_MANDATORY_FIELDS_ERROR = {
  deviationNumber: true,
  actualDeviationNumber: true,
  creationDate: false,
  incidentDate: true,
  deviationType: true,
  department: true,
  workflow: true,
  status: true,
  cqv: true,
  title: true,
  impactedSystems: true,
  associatedDeliverables: true,
  impactedDocuments: true,
  deviationDescription: true,
  initialInvestigation: true,
  impactRequirement: true,
  initialAssessment: true,
  associatedUsers: true,
};

export const INVESTIGATION_MANDATORY_FIELDS_ERROR = {
  deviationNumber: true,
  actualDeviationNumber: true,
  creationDate: true,
  incidentDate: true,
  deviationType: true,
  department: true,
  workflow: true,
  status: true,
  cqv: true,
  title: true,
  impactedSystems: true,
  associatedDeliverables: true,
  impactedDocuments: true,
  deviationDescription: true,
  initialInvestigation: true,
  impactRequirement: true,
  initialAssessment: true,
  associatedUsers: true,
  category: true,
  rootCause: true,
  criticality: true,
  conclusion: true,
};

export const deviationTypes = [
  {
    label: "GMP",
    value: "GMP",
  },
  { label: "Non GMP", value: "NON_GMP" },
];

export const rootCause = [
  { label: "Inactive", value: "INACTIVE" },
  { label: "Design Error", value: "DESIGN_ERROR" },
  { label: "Document Generation Error", value: "DOCUMENT_ERROR" },
  { label: "Measurement", value: "MEASUREMENT" },
  { label: "Environment", value: "ENVIRONMENT" },
  { label: "Method", value: "METHOD" },
  { label: "Machinery", value: "MACHINERY" },
  { label: "Materials", value: "MATERIALS" },
  { label: "Human-Related", value: "HUMAN_RELATED" },
];

export const criticality = [
  { label: "Minor", value: "MINOR" },
  { label: "Major", value: "MAJOR" },
  { label: "Critical", value: "CRITICAL" },
];

export const category = [
  { label: "Reference to source", value: "REF_SOURCE" },
  { label: "Instructions", value: "INSTRUCTIONS" },
  { label: "Acceptance Criteria", value: "ACCEPTANCE_CRITERIA" },
  { label: "Interconnected System Error", value: "INTER_SYS_ERR" },
  { label: "System Fabrication Error", value: "SYS_FAB_ERROR" },
  { label: "System Performance Error", value: "SYS_PER_ERROR" },
  { label: "Requirements Error", value: "REQ_ERROR" },
  { label: "ETOP Error", value: "ETOP_ERR" },
  { label: "Turnover Gap (Qualification readiness)", value: "TURNOVER_GAP" },
  { label: "Tester Error - Execution Error", value: "TESTER_EXE_ERR" },
  {
    label: "Tester Error - Training Curriculum Gap",
    value: "TESTER_TRAINING_GAP",
  },
  { label: "Tester Error - GDP issue", value: "TESTER_GDP" },
  { label: "Tester Error - SOP non-andherence", value: "TESTER_SOP" },
  { label: "Installation Error", value: "INSTALLATION_ERR" },
  { label: "System Configuration Error", value: "SYS_CONFIG_ERR" },
  { label: "Equipement Spec Error", value: "EQP_SPEC_ERR" },
];

export const statusTypes = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent for Pre-Approval", value: "SENT_FOR_APPROVAL" },
  { label: "Pre-Approved", value: "APPROVED" },
  { label: "Sent for Approval", value: "SENT_FOR_APPROVAL" },
  { label: "Approved", value: "APPROVED" },
  { label: "Executing", value: "EXECUTING" },
  { label: "Executed", value: "EXECUTED" },
  { label: "Sent for Closure", value: "SENT_FOR_CLOSURE" },
  { label: "Closed", value: "CLOSED" },
];

export const USER_ROLE = [
  { value: "INITIATOR", label: "Initiator" },
  { value: "CO_INITIATOR", label: "Co Initiator" },
  { value: "REVIEWER", label: "Reviewer" },
  { value: "APPROVER", label: "Approver" },
  { value: "BOTH", label: "Reviewer & Approver" },
];

export const USER_STATUS = [
  { value: "INITIATED", label: "Initiated" },
  { value: "ASSIGNED", label: "Assigned" },
  { value: "APPROVAL_PENDING", label: "Approval Pending" },
  { value: "APPROVAL_REJECTED", label: "Approval Rejected" },
  { value: "APPROVED", label: "Approved" },
  { value: "REVIEW_PENDING", label: "Review Pending" },
  { value: "REVIEWED", label: "Reviewed" },
];

export const COMPLETED_TASKS_ACTION = [
  { value: "REVIEWED", label: "Reviewed" },
  { value: "APPROVED", label: "Approved" },
  { value: "APPROVAL_REJECTED", label: "Rejected" },
  { value: "COMPLETED", label: "Action Items Completed" },
];
export const TABLE_COLUMN_ATTACHMENTS = [
  { key: "fileName", label: "Name", minWidth: 100 },
  { key: "title", label: "Description", minWidth: 100 },
  { key: "user", label: "Uploaded By", minWidth: 100 },
  { key: "action", label: "Action", minWidth: 100 },
];

export const TABLE_COLUMN_IMPACTED_SYSTEMS = [
  { key: "phase", label: "Phase", minWidth: 100 },
  { key: "name", label: "System Name", minWidth: 100 },
  { key: "equipmentNumber", label: "Equipment Number", minWidth: 100 },
  { key: "action", label: "", minWidth: 100 },
];

export const TABLE_COLUMN_IMPACTED_DOCUMENTS = [
  { key: "documentNumber", label: "Document Number", minWidth: 150 },
  { key: "documentTitle", label: "Document Title", minWidth: 200 },
  { key: "version", label: "Version", minWidth: 80 },
  { key: "documentStatus", label: "Document Status", minWidth: 100 },
  { key: "action", label: "", minWidth: 100 },
];

export const TABLE_COLUMN_USERS = [
  { key: "userName", label: "User", minWidth: 100 },
  { key: "department", label: "Department", minWidth: 60 },
  { key: "role", label: "Role", minWidth: 60 },
];

export const TABLE_COLUMN_REVIEW_APPROVAL = [
  { key: "department", label: "Department", minWidth: 60 },
  { key: "userName", label: "User", minWidth: 100 },
  { key: "role", label: "Role", minWidth: 60 },
  { key: "status", label: "Status", minWidth: 60 },
  { key: "note", label: "Notes", minWidth: 100 },
];

export const TABLE_COLUMN_REVISION_HISTORY = [
  { key: "date", label: "Date" },
  { key: "userName", label: "User", minWidth: 100 },
  { key: "note", label: "Action", minWidth: 100 },
  { key: "actionNote", label: "Notes", minWidth: 100 },
];

export const TABLE_COLUMN_CAPA_ATTACHMENTS = [
  { key: "fileName", label: "fileName", minWidth: 100 },
  { key: "actions", label: "Actions", minWidth: 100 },
];

export const TABLE_COLUMN_CAPA_COMMENTS = [
  { key: "user", label: "User", minWidth: 100 },
  { key: "email", label: "Email Id", minWidth: 100 },
  { key: "date", label: "Date", minWidth: 100 },
  { key: "comment", label: "Comment", minWidth: 100 },
];

export const TABLE_CAPA = [
  { key: "checkBox", label: "Select All", minWidth: 50 },
  { key: "sequence", label: "Action Item Number", minWidth: 50 },
  { key: "name", label: "Action", minWidth: 100 },
  { key: "date", label: "Due Date", minWidth: 100 },
  { key: "system", label: "System", minWidth: 100 },
  { key: "deliverable", label: "Deliverable", minWidth: 100 },
  { key: "others", label: "Others", minWidth: 100 },
  { key: "actionOwner", label: "Action Owner", minWidth: 100 },
  { key: "comments", label: "Comments", minWidth: 100 },
];

export const TABLE_CAPA_EXECUTION = [
  { key: "checkBox", label: "Select All", minWidth: 50 },
  { key: "sequence", label: "Action Item Number", minWidth: 50 },
  { key: "name", label: "Action", minWidth: 100 },
  { key: "date", label: "Due Date", minWidth: 50 },
  { key: "system", label: "System", minWidth: 100 },
  { key: "deliverable", label: "Deliverable", minWidth: 100 },
  { key: "others", label: "Others", minWidth: 100 },
  { key: "actionOwner", label: "Action Owner", minWidth: 100 },
  { key: "description", label: "Description", minWidth: 100 },
  { key: "status", label: "Status", minWidth: 100 },
  { key: "completionDate", label: "Completion Date", minWidth: 100 },
  { key: "attachment", label: "Attachment", minWidth: 100 },
  { key: "comments", label: "Comments", minWidth: 100 },
];

export const SUBMIT_ERROR_MESSAGE = {
  SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
  INVESTIGATION_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  INVESTIGATION_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
  CAPA_EXECUTION_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  CAPA_EXECUTION_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
  CLOSURE_SUBMIT_FOR_REVIEW: "Atleast one Reviewer required",
  CLOSURE_SUBMIT_FOR_APPROVAL: "Atleast one Approver required",
};

export const ACTION_LIST_STATUS = {
  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
};

export const resetUserStatus = (userList) => {
  let list = [...userList];
  list = list.map((user) => {
    if (!["INITIATOR", "CO_INITIATOR"].includes(user.role)) {
      user = {
        ...user,
        status: "ASSIGNED",
        note: "",
      };
    }
    return user;
  });
  return list;
};

export const checkAccess = (userList, currentUser) => {
  const user = userList?.find((data) => data.user === currentUser._id);
  if (["INITIATOR", "CO_INITIATOR"].includes(user?.role)) {
    return true;
  }
  return false;
};

export const getUserRole = (userList, deviationStatus, currentUser) => {
  let user = userList.filter((data) => data?.userInfo?._id === currentUser._id);
  if (user.length > 1) {
    if (
      [
        "REVIEW_PENDING",
        "REVIEWED",
        "INVESTIGATION_REVIEW_PENDING",
        "INVESTIGATION_REVIEWED",
        "CAPA_EXECUTION_REVIEW_PENDING",
        "CAPA_EXECUTION_REVIEWED",
        "CLOSURE_REVIEWED",
      ].includes(deviationStatus) &&
      user.filter((data) => data.role === "REVIEWER")
    ) {
      user = user.filter((data) => data.role === "REVIEWER");
    }
    if (
      [
        "APPROVAL_PENDING",
        "APPROVAL_REJECTED",
        "APPROVED",
        "INVESTIGATION_APPROVAL_PENDING",
        "INVESTIGATION_APPROVAL_REJECTED",
        "INVESTIGATION_APPROVED",
        "CAPA_EXECUTION_APPROVAL_PENDING",
        "CAPA_EXECUTED",
        "CLOSURE_APPROVAL_PENDING",
        "CLOSURE_REJECTED",
      ].includes(deviationStatus) &&
      user.filter((data) => data.role === "APPROVER")
    ) {
      user = user.filter((data) => data.role === "APPROVER");
    }
  }
  return user[0]?.role;
};

export const setDeviationValues = (deviation) => {
  let deliverables =
    [].concat(
      ...deviation?.associatedDeliverables?.map((value) => {
        return value.deliverables.map(
          (deliverable) => value.system + "-" + deliverable.deliverable
        );
      })
    ) || [];
  let documents = deviation?.impactedDocuments?.map((document) => {
    return { value: document.document, label: document.documentNumber };
  });
  const data = {
    ..._.cloneDeep(deviation),
    associatedDeliverables: deliverables,
    impactedDocuments: documents,
  };
  return data;
};

export const handleDeviationChange = (
  deviation,
  documents,
  type,
  value,
  name,
  value1 = "",
  value2 = "",
  devSettings
) => {
  if (type === "date" && value !== null) {
    value = moment(value).format("YYYY-MM-DD[T00:00:00.000Z]");
  }
  let updatedData = { ...deviation, [name]: value };
  if (name === "workflow") {
    updatedData.workflowRef = value;
    updatedData.workflow = devSettings?.workflows.find(
      (flow) => flow?._id === value
    );
  }
  if (name === "cqv") {
    updatedData.cqv = !deviation.cqv;
  }
  if (name === "impactedSystemsRemove") {
    updatedData.impactedSystems = value;
    updatedData.associatedDeliverables = value1;
    updatedData.impactedDocuments = value2;
  }
  if (name === "impactedSystems") {
    updatedData.impactedSystems = value;
    let prevArray = value1.map((data) => data.system);
    let nextArray = value.map((data) => data.system);
    let difference = prevArray
      .filter((x) => !nextArray.includes(x))
      .concat(nextArray.filter((x) => !prevArray.includes(x)));
    let documentsValue = [...deviation.impactedDocuments];
    difference.forEach((system) => {
      if (prevArray.includes(system)) {
        documentsValue = documentsValue.filter(
          (document) => document.system !== system
        );
      } else {
        let systemDocuments = documents.filter(
          (document) => document?.system?._id === system
        );
        let documentsIds = documentsValue.map((document) => document.value);
        systemDocuments.forEach((data) => {
          if (!documentsIds.includes(data.system._id)) {
            documentsValue.push({
              label: data.documentNumber,
              value: data._id,
              system: data.system._id,
            });
          }
        });
      }
    });
    updatedData.impactedDocuments = documentsValue;
  }
  return updatedData;
};

export const arrangeData = (
  submitType,
  formData,
  files,
  systems,
  documents
) => {
  const data = {
    ...formData,
    files: files,
    associatedDeliverables: systems
      .filter((system) =>
        formData?.impactedSystems
          ?.map((system) => system.system)
          .includes(system._id)
      )
      .map((system) => {
        return {
          system: system._id,
          systemName: system.name,
          deliverables: system.deliverables
            .map((deliverable) => {
              if (
                formData?.associatedDeliverables.includes(
                  system._id + "-" + deliverable._id
                )
              ) {
                return {
                  deliverable: deliverable._id,
                  deliverableName: deliverable.name,
                };
              } else {
                return null;
              }
            })
            .filter((deliverable) => deliverable !== null),
        };
      })
      .filter((system) => system.deliverables.length),
    impactedDocuments: documents
      .filter((document) =>
        formData.impactedDocuments
          .map((document) => document.value)
          .includes(document._id)
      )
      .map((document) => {
        return {
          document: document._id,
          documentTitle: document.documentTitle,
          documentNumber: document.documentNumber,
          documentStatus: document.documentStatus,
        };
      }),
    associatedUsers: {
      draft: formData.associatedUsers?.draft?.map((user) =>
        _.omit(user, "department")
      ),
      investigation: formData.associatedUsers?.investigation?.map((user) =>
        _.omit(user, "department")
      ),
      capa: formData.associatedUsers?.capa?.map((user) =>
        _.omit(user, "department")
      ),
      closure: formData.associatedUsers?.closure?.map((user) =>
        _.omit(user, "department")
      ),
    },
  };
  if (submitType) {
    data.action = submitType;
  }
  return data;
};

export const checkUserStatus = (
  status,
  users,
  currentUser,
  currentUserRole
) => {
  if (
    [
      "REVIEW_PENDING",
      "INVESTIGATION_REVIEW_PENDING",
      "CAPA_EXECUTION_REVIEW_PENDING",
      "CLOSURE_REVIEW_PENDING",
    ].includes(status) &&
    currentUserRole === "REVIEWER" &&
    users?.find(
      (user) =>
        user.user === currentUser._id && user.status === "REVIEW_PENDING"
    )
  ) {
    return true;
  }
  if (
    [
      "APPROVAL_PENDING",
      "INVESTIGATION_APPROVAL_PENDING",
      "CAPA_EXECUTION_APPROVAL_PENDING",
      "CLOSURE_APPROVAL_PENDING",
    ].includes(status) &&
    currentUserRole === "APPROVER" &&
    users?.find(
      (user) =>
        user.user === currentUser._id && user.status === "APPROVAL_PENDING"
    )
  ) {
    return true;
  }
  return false;
};

export const checkCAPACompleted = (data) => {
  const list = data.filter((val) => val.status === "COMPLETED");
  if (list.length === data.length || data.length === 0) {
    return true;
  }
  return false;
};

export const WORKFLOW_TABLE_COLUMN = [
  { id: "name", label: "Name", minWidth: 200 },
  { id: "workflow", label: "Workflow", minWidth: 100 },
  { id: "activate", label: "Activate", minWidth: 50 },
  { id: "action", label: "Action", minWidth: 150 },
];

export const NEW_WORKFLOW_TEMPLATE = {
  name: "",
  active: true,
  stages: [
    {
      name: "INITIATION",
      stage: true,
      review: true,
      approve: true,
    },
    {
      name: "INVESTIGATION",
      stage: true,
      review: true,
      approve: true,
    },
    {
      name: "CAPA",
      stage: true,
      review: true,
      approve: true,
    },
    {
      name: "CLOSURE",
      stage: true,
      review: true,
      approve: true,
    },
  ],
};

export const stageLabels = [
  {
    name: "INITIATION",
    label: "Initiation",
  },
  {
    name: "INVESTIGATION",
    label: "Investigation",
  },
  {
    name: "CAPA",
    label: "CAPA Execution",
  },
  {
    name: "CLOSURE",
    label: "Closure",
  },
];

export const stages = [
  {
    name: "REVIEW",
    label: "Review",
  },
  {
    name: "APPROVE",
    label: "Approval",
  },
];
