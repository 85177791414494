import { getProjectDetails } from "services/api-project";

export const DocumentHelper = () => {
  const getProject = async (projectId) => {
    await getProjectDetails(projectId)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log("error >>>>> ", err);
      });
  };

  const TABLE_MYTASK_COLUMN = [
    { id: "documentNumber", label: "Document No.", minWidth: 100 },
    { id: "documentTitle", label: "Document Title", minWidth: 100 },
    { id: "documentStatus", label: "Status", minWidth: 100 },
    { id: "system", label: "Associated System", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "actualVersion", label: "Actual Version", minWidth: 100 },
  ];
  const TABLE_COMPLETEDTASK_COLUMN = [
    { id: "documentNumber", label: "Document No.", minWidth: 100 },
    { id: "documentTitle", label: "Document Title", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
    { id: "documentStatus", label: "Document Status", minWidth: 100 },
    { id: "system", label: "Associated System", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "actualVersion", label: "Actual Version", minWidth: 100 },
  ];
  const TABLE_COLUMN_DRAFT = [
    { id: "documentNumber", label: "Document No.", minWidth: 100 },
    { id: "documentTitle", label: "Document Title", minWidth: 100 },
    { id: "documentStatus", label: "Status", minWidth: 100 },
    { id: "system", label: "Associated System", minWidth: 100 },
    { id: "deliverable", label: "Deliverable", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "actualVersion", label: "Actual Version", minWidth: 100 },
  ];

  const TABLE_COLUMN_ASSOCIATED_DOCUMENTS = [
    { id: "documentNumber", label: "Document Number", minWidth: 100 },
    { id: "documentTitle", label: "Document Title", minWidth: 100 },
    { id: "documentStatus", label: "Status", minWidth: 100 },
    { id: "department", label: "Department", minWidth: 100 },
  ];

  const TABLE_COLUMN_TEMPLATE = [
    { id: "name", label: "Template Name", minWidth: 100 },
    { id: "documentType", label: "Document Type", minWidth: 100 },
    { id: "fileName", label: "File Name", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
  ];
  const TABLE_COLUMN_TEMPLATE_HISTORY = [
    { id: "fileName", label: "File Name", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "author", label: "Author", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100 },
  ];

  const TABLE_COLUMN = [
    { id: "documentNumber", label: "Document No.", minWidth: 100 },
    { id: "documentTitle", label: "Document Title", minWidth: 100 },
    { id: "documentStatus", label: "Status", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "system", label: "Associated System", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "actualVersion", label: "Actual Version", minWidth: 100 },
  ];

  const DOC_STATUS = [
    { value: "DRAFT", label: "Draft" },
    { value: "REVIEW_PENDING", label: "Review Pending" },
    { value: "APPROVAL_PENDING", label: "Approval Pending" },
    { value: "REVIEWED", label: "Reviewed" },
    { value: "APPROVED", label: "Approved" },
    { value: "EFFECTIVE", label: "Effective" },
    { value: "OBSOLETE", label: "Obsolete" },
  ];

  const DOC_TYPE = [
    { value: "ENGINEERING", label: "Engineering" },
    { value: "SPECIFICATION", label: "Specification" },
    { value: "PLAN", label: "Plan" },
    { value: "TEST_PROTOCOL", label: "Test Protocol" },
    { value: "OTHER", label: "Other" },
  ];

  const USER_ROLE = [
    { value: "AUTHOR", label: "Author" },
    { value: "CO_AUTHOR", label: "Co-Author" },
    { value: "OWNER", label: "Document Owner" },
    { value: "APPROVER", label: "Approver" },
    { value: "REVIEWER", label: "Reviewer" },
    { value: "BOTH", label: "Reviewer & Approver" },
  ];

  const TABLE_COLUMN_FILE = [
    { id: "createdAt", label: "Date", minWidth: 100 },
    { id: "user", label: "User", minWidth: 100 },
    { id: "role", label: "Role", minWidth: 100 },
    { id: "fileName", label: "Document", minWidth: 100 },
    { id: "version", label: "Version", minWidth: 100 },
    { id: "view", label: "Action", minWidth: 100 },
  ];

  const TABLE_COLUMN_APPROVAL_HISTORY = [
    { id: "date", label: "Date", minWidth: 100 },
    { id: "user", label: "User", minWidth: 100 },
    { id: "department", label: "Department", minWidth: 100 },
    { id: "role", label: "Role", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "note", label: "Notes", minWidth: 100 },
  ];

  const TABLE_AUTO_APPROVAL = [
    { id: "department", label: "Department", minWidth: 100 },
    { id: "user", label: "User", minWidth: 100 },
    { id: "role", label: "Role", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "date", label: "Enter Date", minWidth: 100 },
  ];

  const TABLE_COLUMN_REVISION_HISTORY = [
    { id: "version", label: "Version", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "note", label: "Status", minWidth: 100 },
    { id: "user", label: "User", minWidth: 100 },
    { id: "actionNote", label: "Notes", minWidth: 100 },
  ];

  const TABLE_COLUMN_USERS_TABLE = [
    { id: "user", label: "User", minWidth: 100 },
    { id: "department", label: "Department", minWidth: 100 },
    { id: "role", label: "Role", minWidth: 100 },
  ];

  const CHANGE_REQUEST_STATUS = [
    { value: "DRAFT", label: "Draft" },
    { value: "INITIATED", label: "Initiated" },
    { value: "APPROVAL_PENDING", label: "Approval Pending" },
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
    { value: "CLOSED", label: "Closed" },
    { value: "CANCELLED", label: "Cancelled" },
  ];

  const CHANGE_REQUEST_USER_STATUS = [
    { value: "ASSIGNED", label: "Assigned" },
    { value: "APPROVAL_PENDING", label: "Approval Pending" },
    { value: "APPROVED", label: "Approved" },
    { value: "APPROVAL_REJECTED", label: "Approval Rejected" },
  ];

  const TABLE_COLUMN_CHANGE_REQUESTS = [
    { id: "documentVersion", label: "Document Version", minWidth: 100 },
    { id: "dcrNumber", label: "DCR No.", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "department", label: "Department", minWidth: 100 },
    { id: "initiator", label: "Initiator", minWidth: 100 },
    { id: "proposedDueDate", label: "Proposed Due Date", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Actions", minWidth: 100 },
  ];

  const DOCUMENT_CR_TABLE_MYTASK_COLUMN = [
    { id: "dcrNumber", label: "DCR No.", minWidth: 100 },
    { id: "description", label: "DCR Title", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
  ];

  return {
    getProject,
    TABLE_COLUMN_DRAFT,
    TABLE_COLUMN,
    TABLE_COLUMN_FILE,
    TABLE_COLUMN_APPROVAL_HISTORY,
    TABLE_AUTO_APPROVAL,
    DOC_STATUS,
    DOC_TYPE,
    USER_ROLE,
    TABLE_COLUMN_USERS_TABLE,
    TABLE_COLUMN_REVISION_HISTORY,
    TABLE_MYTASK_COLUMN,
    TABLE_COMPLETEDTASK_COLUMN,
    TABLE_COLUMN_TEMPLATE,
    TABLE_COLUMN_TEMPLATE_HISTORY,
    TABLE_COLUMN_ASSOCIATED_DOCUMENTS,
    CHANGE_REQUEST_STATUS,
    CHANGE_REQUEST_USER_STATUS,
    TABLE_COLUMN_CHANGE_REQUESTS,
    DOCUMENT_CR_TABLE_MYTASK_COLUMN,
  };
};
