import {
  Card,
  makeStyles,
  Grid,
  Box,
  Divider,
  Button,
  Typography,
} from "@material-ui/core";
// import * as Icon from "react-feather";
import VektorCheckbox from "components/UI/VektorCheckbox";
import { useEffect, useState } from "react";
import { ReactComponent as ProjectManagement } from "icons/project management.svg";
import { ReactComponent as CalendarViewIcon } from "icons/calendar_view.svg";
import { ReactComponent as LibraryIcon } from "icons/library_1.svg";
import { ReactComponent as DocumentIcon } from "icons/document.svg";
import { ReactComponent as DeviationIcon } from "icons/deviations.svg";
import { ReactComponent as ChangeRequestIcon } from "icons/change requests.svg";
import { ReactComponent as FinanceIcon } from "icons/finance.svg";
import { ReactComponent as PatientDataIcon } from "icons/patient data.svg";
import { ReactComponent as WorkLoadIcon } from "icons/work_report.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    width: "100%",
  },
  areaBtn: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
}));

const ModuleIcons = {
  projectManagement: <ProjectManagement />,
  calendarView: <CalendarViewIcon />,
  library: <LibraryIcon />,
  documentManagement: <DocumentIcon />,
  deviationManagement: <DeviationIcon />,
  changeRequestManagement: <ChangeRequestIcon />,
  documentChangeRequest: <ChangeRequestIcon />,
  finance: <FinanceIcon />,
  cellGlass: <PatientDataIcon />,
  workloadManagement: <WorkLoadIcon />,
};

export default function ModulePreferenceForm({
  mode,
  permissions,
  preferencesData,
  updatePreference,
}) {
  const classes = useStyles();
  const [modulePreference, setModulePreference] = useState([]);
  const [modulePreferenceHeader, setModulePreferenceHeader] = useState({});

  useEffect(() => {
    setModulePreference(preferencesData?.modules);
    setModulePreferenceHeader(preferencesData?.headers);
  }, [preferencesData]);

  const handleModuleSelection = (moduleId, key) => {
    const updatedConfiguration = modulePreference.map((module) => {
      if (module.key === moduleId) {
        module[key] = !module[key];
        module.features = module.features.map((feature) => ({
          ...feature,
          [key]: module[key],
        }));
      }
      return module;
    });
    setModulePreference(updatedConfiguration);
  };

  const handleGroupSelection = (type) => {
    const updatedConfiguration = modulePreference.map((module) => {
      const moduleAdminAccess =
        type === "clear"
          ? false
          : type === "select_all"
          ? true
          : module["admin"];
      modulePreferenceHeader.columns.forEach((column) => {
        module[column.key] = moduleAdminAccess;
      });
      module.features = module.features.map((feature) => {
        const featureAdminAccess =
          type === "clear"
            ? false
            : type === "select_all"
            ? true
            : feature["admin"];

        modulePreferenceHeader.columns.forEach((column) => {
          feature[column.key] = featureAdminAccess;
        });
        return feature;
      });
      return module;
    });
    setModulePreference(updatedConfiguration);
  };

  const handleFeatureSelection = (moduleId, featureId, key) => {
    const updatedConfiguration = modulePreference.map((module) => {
      if (module.key === moduleId) {
        module.features = module.features.map((feature) => {
          if (feature.key === featureId) {
            feature[key] = !feature[key];
          }
          return feature;
        });
      }
      modulePreferenceHeader.columns.forEach((column) => {
        if (module.features.some((feature) => feature[column.key])) {
          module[column.key] = true;
        } else module[column.key] = false;
      });

      return module;
    });
    setModulePreference(updatedConfiguration);
  };

  const handleSave = () => {
    updatePreference({
      modules: modulePreference,
      headers: modulePreferenceHeader,
    });
  };

  if (!modulePreference || !modulePreferenceHeader) {
    return <></>;
  }

  return (
    <>
      <Card className={classes.root}>
        {mode !== "USER" && (
          <Box p={3}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <Grid container alignItems="center" justify="flex-end">
                  <Box mr={1}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleGroupSelection("clear")}
                    >
                      Unselect All
                    </Button>
                  </Box>
                  <Box mr={1}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleGroupSelection("select_all")}
                    >
                      Select All
                    </Button>
                  </Box>

                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleGroupSelection}
                  >
                    Copy Admin Access to Other Role
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box p={3} bgcolor="lightGray">
          <Grid container justify="space-between" xs={mode === "USER" ? 6 : 12}>
            <Grid item xs={4}>
              {modulePreferenceHeader.label}
            </Grid>
            <Grid item xs={8}>
              <Grid container xs={12} alignItems="center" justify="center">
                {modulePreferenceHeader?.columns
                  ?.filter((col) =>
                    mode === "USER" ? col?.key.includes(permissions) : col
                  )
                  .map((column) => (
                    <Grid item xs={2} key={column.key}>
                      {column.label}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        {modulePreference?.map((module, index) => {
          // const ModuleIcon = Icon[module.icon];
          const ModuleIcon = ModuleIcons[`${module.key}`];

          // console.log(
          //   "$ Icon Names =",
          //   module.icon,
          //   module.label,
          //   ModuleIcons[module.icon]
          // );

          const disabled =
            module?.disabled &&
            Object.values(module?.disabled).every((item) => item === true);
          return (
            <>
              {!disabled && (
                <div>
                  <Box p={3} key={index}>
                    <Grid
                      container
                      justify="space-between"
                      xs={mode === "USER" ? 6 : 12}
                    >
                      <Grid container xs={4} alignItems="center">
                        {" "}
                        {/* <ModuleIcon size={16} />{" "} */}
                        {ModuleIcon}
                        <Box ml={2}>
                          <Typography variant="subtitle2">
                            {module.label}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid
                          container
                          xs={12}
                          alignItems="center"
                          justify="center"
                        >
                          {modulePreferenceHeader?.columns
                            ?.filter((col) =>
                              mode === "USER"
                                ? col.key.includes(permissions)
                                : col
                            )
                            .map((column, index) => (
                              <Grid item xs={2} key={index}>
                                {" "}
                                <VektorCheckbox
                                  onChange={(e) =>
                                    handleModuleSelection(
                                      module.key,
                                      column.key
                                    )
                                  }
                                  disabled={
                                    module?.disabled &&
                                    module?.disabled[column.key]
                                  }
                                  checked={
                                    module?.disabled &&
                                    module?.disabled[column.key]
                                      ? false
                                      : module[column.key]
                                  }
                                />{" "}
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  {module?.features.length > 1 &&
                    module?.features?.map((feature, index) => {
                      return (
                        <>
                          <Box p={3} key={index}>
                            <Grid
                              container
                              justify="space-between"
                              xs={mode === "USER" ? 6 : 12}
                            >
                              <Grid container xs={4} alignItems="center">
                                <Box ml={6}>{feature.label}</Box>
                              </Grid>
                              <Grid item xs={8}>
                                <Grid
                                  container
                                  xs={12}
                                  alignItems="center"
                                  justify="center"
                                >
                                  {modulePreferenceHeader?.columns
                                    ?.filter((col) =>
                                      mode === "USER"
                                        ? col.key.includes(permissions)
                                        : col
                                    )
                                    .map((column, index) => (
                                      <Grid
                                        item
                                        xs={2}
                                        key={`${feature.key}${column.key}${index}`}
                                      >
                                        {" "}
                                        <VektorCheckbox
                                          onChange={(e) =>
                                            handleFeatureSelection(
                                              module.key,
                                              feature.key,
                                              column.key
                                            )
                                          }
                                          disabled={
                                            feature?.disabled &&
                                            feature?.disabled[column.key]
                                          }
                                          checked={
                                            feature?.disabled &&
                                            feature?.disabled[column.key]
                                              ? false
                                              : feature[column.key]
                                          }
                                        />{" "}
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider />
                        </>
                      );
                    })}
                </div>
              )}
            </>
          );
        })}
        <Box p={3}>
          <Grid container justify="flex-end">
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
