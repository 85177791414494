import ModulePreference from "pages/Organizations/ModulePreference";
import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as UserManagement } from "icons/user management.svg";
import { FEATURE_FLAGS } from "../feature-flags";

const UserList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'UserList' */ "pages/Users/UserList")
  )
);
const AddUser = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'AddUser' */ "pages/Users/AddUser")
  )
);
const EditUser = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'EditUser' */ "pages/Users/EditUser")
  )
);
const UserModulePreference = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'UserModulePreference' */ "pages/Users/Shared/UserModulePreference"
    )
  )
);
const UserHistory = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'WorkflowTemplateHistory' */ "pages/Users/UserHistory"
    )
  )
);
const OrganizationList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'OrganizationList' */ "pages/Organizations/OrganizationList"
    )
  )
);
const AddOrganization = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddOrganization' */ "pages/Organizations/AddOrganization"
    )
  )
);
const EditOrganization = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditWorkflowTemplate' */ "pages/Organizations/EditOrganization"
    )
  )
);
const OrganizationHistory = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'WorkflowTemplateHistory' */ "pages/Organizations/OrganizationHistory"
    )
  )
);
const AuditTrailLogList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AuditTrailLogList' */ "pages/AuditTrailLogs/AuditTrailLogList"
    )
  )
);
const AuditTrailLogDetail = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AuditTrailLogDetail' */ "pages/AuditTrailLogs/AuditTrailLogDetail"
    )
  )
);
const AuditTrailLogHistory = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AuditTrailLogHistory' */ "pages/AuditTrailLogs/AuditTrailLogHistory"
    )
  )
);
const WorkloadReport = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/WorkloadReport")
  )
);

const userManagementRoutes = Object.freeze({
  id: LINKS.USER_MANAGEMENT.TITLE,
  path: LINKS.USER_MANAGEMENT.HREF,
  icon: <UserManagement />,
  component: null,
  roles: LINKS.USER_MANAGEMENT.ROLES,
  children: [
    {
      path: LINKS.USERS.HREF,
      name: LINKS.USERS.TITLE,
      component: UserList,
    },
    {
      path: LINKS.ADD_USER.HREF,
      name: LINKS.ADD_USER.TITLE,
      component: AddUser,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_USER.HREF,
      name: LINKS.EDIT_USER.TITLE,
      component: EditUser,
      isNotSlide: true,
    },
    {
      path: LINKS.USER_MODULE_PREFERENCES.HREF,
      name: LINKS.USER_MODULE_PREFERENCES.TITLE,
      component: UserModulePreference,
      isNotSlide: true,
    },
    {
      path: LINKS.USER_HISTORY.HREF,
      name: LINKS.USER_HISTORY.TITLE,
      component: UserHistory,
      isNotSlide: true,
    },
    {
      path: LINKS.ORGANIZATIONS.HREF,
      name: LINKS.ORGANIZATIONS.TITLE,
      component: OrganizationList,
    },
    {
      path: LINKS.ADD_ORGANIZATION.HREF,
      name: LINKS.ADD_ORGANIZATION.TITLE,
      component: AddOrganization,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_ORGANIZATION.HREF,
      name: LINKS.EDIT_ORGANIZATION.TITLE,
      component: EditOrganization,
      isNotSlide: true,
    },
    {
      path: LINKS.ORGANIZATION_MODULE_PREFERENCES.HREF,
      name: LINKS.ORGANIZATION_MODULE_PREFERENCES.TITLE,
      component: ModulePreference,
      isNotSlide: true,
    },
    {
      path: LINKS.ORGANIZATION_HISTORY.HREF,
      name: LINKS.ORGANIZATION_HISTORY.TITLE,
      component: OrganizationHistory,
      isNotSlide: true,
    },
    {
      path: LINKS.AUDIT_TRAIL_LOGS.HREF,
      name: LINKS.AUDIT_TRAIL_LOGS.TITLE,
      component: AuditTrailLogList,
      roles:LINKS.AUDIT_TRAIL_LOGS.ROLES
    },
    {
      path: LINKS.AUDIT_TRAIL_LOG_DETAIL.HREF,
      name: LINKS.AUDIT_TRAIL_LOG_DETAIL.TITLE,
      component: AuditTrailLogDetail,
      isNotSlide: true,
    },
    {
      path: LINKS.AUDIT_TRAIL_LOG_HISTORY.HREF,
      name: LINKS.AUDIT_TRAIL_LOG_HISTORY.TITLE,
      component: AuditTrailLogHistory,
      isNotSlide: true,
    },
    {
      path: LINKS.WORKLOAD_REPORT.HREF,
      name: LINKS.WORKLOAD_REPORT.TITLE,
      component: WorkloadReport,
      feature: FEATURE_FLAGS.WorkloadReport,
    },
  ],
});

export default userManagementRoutes;
