import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as Home} from 'icons/home.svg';

const Overview = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'Overview' */ "pages/Overview"))
);

const overviewRoutes = Object.freeze({
  id: LINKS.OVERVIEW.TITLE,
  path: LINKS.OVERVIEW.HREF,
  header: "Navigation",
  icon: <Home/>,
  containsHome: true,
  component: Overview,
  children: null,
});

export default overviewRoutes;
