import TYPES from 'utils/constants/action-types';

const initialState = {
  accessibleFeature: [],
  isInitialized: false,
};

export default function modulePreferenceReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_MODULE_PREFERENCE:
      return {
        ...state,
        accessibleFeature: action.payload,
        isInitialized: true
      };
    default:
      return state;
  }
}
