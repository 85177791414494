import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";

const Notification = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'WorkflowTemplateList' */ "pages/Notification")
  )
);

const notificationRoutes = Object.freeze({
  id: LINKS.NOTIFICATIONS.TITLE,
  path: LINKS.NOTIFICATIONS.HREF,
  component: Notification,
});

export default notificationRoutes;
