import { API_PROXY_URL } from "config"
import { io } from "socket.io-client"

class Socket {
    constructor() {
      this.socket = null
    }
  
    connect(userInfo) {
      this.socket = io(API_PROXY_URL, {
          transports: ["websocket", "polling"], // use WebSocket first, if available
          auth: {
            token: userInfo
          }
        })
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    }
  
    emit(event, data) {
      if (this.socket) {
        this.socket.emit(event, data)
      }
    }
  
    on(eventName, callback) {
      if (this.socket) {
        this.socket.addEventListener(eventName, callback)
      }
    }

    off(eventName, callback) {
      if (this.socket) {
        this.socket.removeListener(eventName, callback)
      }
    }
  }
  
  export { Socket }