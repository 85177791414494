import { PERMISSION_TYPES } from "utils/constants";

export const ALLOWED_ROLES = [
  PERMISSION_TYPES.superAdmin,
  PERMISSION_TYPES.admin,
  PERMISSION_TYPES.supervisor,
  PERMISSION_TYPES.projectManager,
  PERMISSION_TYPES.user,
];

export const ALLOWED_ROLES_SYSTEM_FORM = [
  PERMISSION_TYPES.superAdmin,
  PERMISSION_TYPES.admin,
  PERMISSION_TYPES.supervisor,
  PERMISSION_TYPES.projectManager,
];
