import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { Socket } from "utils/socket";
import socketMiddleware from "../middlewares/socketMiddelware";

import reducers from "../reducers";

const bindMiddleWares = (middleWares) => {
  middleWares.push(socketMiddleware(new Socket()));
  if (process.env.NODE_ENV !== "production" || process.env.NODE_ENV !== "qms") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    const { logger } = require(`redux-logger`);
    middleWares.push(logger);

    return composeWithDevTools(applyMiddleware(...middleWares));
  }
  return applyMiddleware(...middleWares);
};

export default createStore(reducers, bindMiddleWares([thunkMiddleware]));
