import React, { memo, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Grid,
  Popover,
  IconButton,
  Divider,
  Avatar,
  Chip,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { useFocusElement } from "utils/hooks";
import { noop } from "utils/constants";
import {
  createDeliverableNote,
  deleteDeliverableNote,
  updateDeliverableOrder,
  updateDeliverableNote,
  updateDeliverables,
} from "redux/actions/metaSystem";
import { DescriptionRounded } from "@material-ui/icons";
import { ReactComponent as CalendarTodayIcon } from "icons/calendar_today.svg";
import { ReactComponent as MilestoneIcon } from "icons/milestone_icon.svg";
import { ReactComponent as CheckCircleIcon } from "icons/check_circle_filled.svg";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AvatarGroup } from "@material-ui/lab";
import EditDeliverable from "./components/editDeliverable";
import DeliverableNoteList from "./components/deliverableNoteList";
import { PERMISSION_TYPES } from "utils/constants";
import Loader from "components/Loader";
import EditDeliverableNotes from "./components/editDeliverableNotes";
import DeliverableStatusTimeline from "./components/deliverableStatusTimeline";
import useUserPermissions from "utils/hooks/useUserPermission";
import { ALLOWED_ROLES } from "pages/MetaSystems/EditMetaSystem/constant";
import _ from "lodash";
import { colorRA } from "pages/Projects/ProjectInsight/components/DeliverableSnapshot/snapshotHelper";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { restrict } from "utils/helpers/utility";
import {
  customDayContent,
  getHolidayAdjustedEndDate,
} from "utils/helpers/dateHelper";
import StarIcon from "@mui/icons-material/Star";
import "./deliverableList.css";
import SetDuration from "components/SetDuration";
import UserConfirmDialog from "./components/UserConfirmDialog";
import UserTimeEntryModal from "./components/UserTimeEntryModal";
import {
  autoScheduleDeliverables,
  handleDelDurationChange,
  handleChangedDate,
} from "pages/WorkflowTemplates/EditWorkflowTemplate/helper";
import { postWorkedHours } from "services/api-workload-report";
import {
  HOUR_MINUTE_REGEX,
  HOUR_REGEX,
  MINUTE_REGEX,
  checkHourMinuteRegex,
} from "pages/Timesheet/helpers";
import { ReactComponent as RescheduleIcon } from "icons/reschedule_icon.svg";
import ReScheduleDeliverable from "components/ReScheduleDeliverable";
import { ReactComponent as PendingCircleIcon } from "icons/pending_circle_filled.svg";
import CustomHourCircle from "pages/WorkloadReport/components/CustomHourCircle";
import { ReactComponent as ReorderIcon } from "icons/reorder_icon.svg";
import { ReactComponent as AutoScheduleIcon } from "icons/autoschedule_icon.svg";
import { ReactComponent as ReorderGrabIcon } from "icons/reorder_grab_icon.svg";

export const getChipColor = (document = [], user, scheduledDate) => {
  if (document?.length === 0 || document === null) {
    if (user.approved) {
      return user?.date?.split("T")[0] > scheduledDate?.split("T")[0]
        ? "chip-delay-deliverable"
        : "chip-success-deliverable";
    } else if (user.rejected) {
      return "chip-error-deliverable";
    } else {
      return "chip-primary-deliverable";
    }
  } else {
    if (
      ["DRAFT", "REVIEW_PENDING", "REVIEWED"].includes(
        document[0].documentStatus
      )
    ) {
      return "chip-primary-deliverable";
    } else {
      if (user.approved) {
        return user?.date?.split("T")[0] > scheduledDate?.split("T")[0]
          ? "chip-delay-deliverable"
          : "chip-success-deliverable";
      } else if (user.rejected) {
        return "chip-error-deliverable";
      } else {
        return "chip-primary-deliverable";
      }
    }
  }
};

const getChipIconColor = (className) => {
  const iconColorMap = {
    "chip-primary-deliverable": "primary-checkcircle",
    "chip-success-deliverable": "success-checkcircle",
    "chip-delay-deliverable": "delay-checkcircle",
    "chip-error-deliverable": "error-checkcircle",
  };

  return iconColorMap[className] || null;
};

export const milestoneStar = (type = "small") => {
  return (
    <Tooltip title="Milestone">
      <StarIcon
        className={
          type === "small" ? "milestone-star-small" : "milestone-star-medium"
        }
        style={{ color: " #FFD700" }}
      />
    </Tooltip>
  );
};

export const milestoneDiamond = (type = "small", color = "#FFD700") => {
  return (
    <Tooltip title="Milestone">
      <svg
        width={18}
        height={18}
        viewBox="0 0 14 18"
        fill="none"
        className={
          type === "small"
            ? "milestone-diamond-small"
            : "milestone-diamond-medium"
        }
        style={{
          position: "relative",
        }}
      >
        <path d="m6.825 0 6.825 9-6.825 9L0 9l6.825-9Z" fill={color} />
      </svg>
    </Tooltip>
  );
};

const DragHandle = SortableHandle(({ style }) => (
  <ReorderGrabIcon style={{ ...style, ...{ cursor: "move" } }} />
));

const BodySortable = SortableContainer(({ children }) => <div>{children}</div>);
const colorClassMap = {
  "#bdbdbd": "grey_icon",
  "#5E5E5E": "darkgrey_icon",
  "#D32F2F": "red_icon",
  "#5A83BB": "primary_icon",
  "#27AE60": "green_icon",
  "#F39C12": "orange_icon",
};

const Row = SortableElement(
  ({
    permissions,
    deliverable,
    indexValue,
    editData,
    editCard,
    showDetails,
    timelineType,
    excluded,
    getNamebyId,
    handleApprove,
    handleReject,
    handleExecuted,
    openCalendarPopup,
    closeCalendarPopup,
    showNotes,
    calendar_id,
    anchorEl,
    setDeliverableDates,
    deliverableDates,
    saveDeliverableDates,
    open,
    reorder,
    project,
    selectedDeliverable,
    setSelectedDeliverable,
    dispatch,
    noteList,
    organization,
    expectedDateRange,
    setExpectedDateRange,
    handleDateChange,
    isWorkflowCall,
    setOpenReSchedule,
    setDurationData,
    deviationList,
    ...other
  }) => {
    return (
      <div {...other}>
        <Grid
          container
          spacing={2}
          columns={20}
          key={indexValue}
          alignItems="center"
          className="card-sdw mb-10"
          style={
            editData && deliverable._id === editData._id
              ? { backgroundColor: "aliceblue" }
              : { backgroundColor: "white" }
          }
        >
          {reorder && (
            <div className="pd-5 mt-5 txt-grey">
              <DragHandle />
            </div>
          )}

          {/* --------------- row 1 start  --------------- */}
          <Grid item xs={3}>
            <Grid container className="al-c">
              <Grid xs={8}>
                <Typography
                  className="pointer"
                  color="primary"
                  variant="subtitle2"
                  onClick={() =>
                    !reorder ? showDetails(indexValue) : () => {}
                  }
                  style={{ overflowWrap: "break-word" }}
                >
                  {deliverable.name}
                </Typography>
              </Grid>
              <Grid xs={4} className="d-flex al-c pd-5 wd-100">
                {deliverable?.milestone && (
                  <MilestoneIcon
                    className={
                      colorClassMap[
                        colorRA(
                          deliverable?.completion,
                          deliverable?.end,
                          deliverable?.status,
                          "timeline",
                          isWorkflowCall
                        )
                      ]
                    }
                  />
                )}
                <div style={{ marginLeft: "auto" }}>
                  <CustomHourCircle
                    size={40}
                    width={4}
                    progressValue={deliverable?.status}
                    progressColor={"#01b3c4"}
                    trackColor={"#cff1f4"}
                    roundedCorners
                    showTextPercentage
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* --------------- row 1 end  --------------- */}
          {/* --------------- row 2 start  --------------- */}
          <Grid item xs={editCard || noteList ? 5 : 4} columns={12}>
            <DeliverableStatusTimeline
              deliverable={deliverable}
              editCard={editCard || noteList}
              showStatus={isWorkflowCall ? false : true}
              fullScreen={false}
              timelineType={timelineType}
              isWorkflowCall={isWorkflowCall}
              deviationList={deviationList}
            />
          </Grid>
          {/* --------------- row 2 end  --------------- */}

          {/* --------------- row 3 start  --------------- */}
          <Grid item xs={2}>
            <Grid container justify="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    setOpenReSchedule(true);
                    setDurationData(deliverable);
                  }}
                >
                  <RescheduleIcon />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                {![PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                  permissions
                ) && (
                  <IconButton
                    disabled={excluded}
                    aria-describedby={
                      Boolean(anchorEl[deliverable?._id])
                        ? "simple-popover"
                        : undefined
                    }
                    onClick={(event) => {
                      if (!reorder) {
                        openCalendarPopup(event, indexValue, deliverable._id);
                        setSelectedDeliverable(deliverable);
                      }
                    }}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                )}

                <Popover
                  id={
                    Boolean(anchorEl[deliverable?._id])
                      ? "simple-popover"
                      : undefined
                  }
                  open={Boolean(anchorEl[`${deliverable?._id}`])}
                  anchorEl={anchorEl[`${deliverable?._id}`]}
                  onClose={() => closeCalendarPopup(deliverable?._id)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div>
                    <DateRange
                      editableDateInputs={true}
                      disabledDates={organization?.organizationHoliday?.map(
                        (holiday) =>
                          new Date(
                            moment(holiday).format("YYYY-MM-DDT00:00:00.000")
                          )
                      )}
                      dayContentRenderer={(day) =>
                        customDayContent(day, expectedDateRange)
                      }
                      onChange={({ selection }) => {
                        handleDateChange(selection);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={deliverableDates}
                    />
                  </div>
                  <div className="d-jsb-r mb-5">
                    <Button
                      variant="contained"
                      onClick={() => closeCalendarPopup(deliverable?._id)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-5 mr-5"
                      onClick={() => saveDeliverableDates(deliverable._id)}
                    >
                      Save
                    </Button>
                  </div>
                </Popover>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ marginLeft: editCard || noteList ? "5px" : "0px" }}
              >
                {!isWorkflowCall && (
                  <IconButton
                    onClick={() =>
                      !reorder ? showNotes(indexValue) : () => {}
                    }
                  >
                    <DescriptionRounded
                      className={
                        deliverable?.notes?.length > 0
                          ? "txt-black"
                          : "txt-grey"
                      }
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* --------------- row 3 end  --------------- */}
          {/* --------------- row 4 start  --------------- */}
          <Grid item xs={editCard || noteList ? 1 : 3}>
            {deliverable?.resource?.length > 0 && (
              <>
                {!isWorkflowCall && (
                  <Grid item>
                    <div>Resources</div>
                    <AvatarGroup
                      max={6}
                      spacing={0}
                      className="avatar-deliverable"
                    >
                      {deliverable.resource.map((uid) => (
                        <Tooltip
                          title={getNamebyId("users", uid) || ""}
                          key={uid}
                        >
                          <Avatar
                            alt={getNamebyId("users", uid)}
                            sx={{ bgcolor: "#5A83BB" }}
                          >
                            {getNamebyId("users", uid)?.replace(/[^A-Z]/g, "")}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </Grid>
                )}
                {!isWorkflowCall &&
                  ((deliverable?.reviewer?.length > 0 &&
                    deliverable.type !== "EXECUTION") ||
                    (deliverable?.approver?.length > 0 &&
                      deliverable.type !== "EXECUTION") ||
                    deliverable?.executed) && <Divider className="mt-5 mb-5" />}
              </>
            )}
            {deliverable?.reviewer?.length > 0 &&
              deliverable.type !== "EXECUTION" && (
                <>
                  {!isWorkflowCall && (
                    <Grid>
                      <div>Reviewer</div>
                      {deliverable.reviewer?.map((data, reviewerIndex) =>
                        data.users?.map((user, userIndex) => (
                          <Tooltip
                            title={
                              getNamebyId("approver", data.department) || ""
                            }
                            key={user._id + "Reviewer"}
                          >
                            <Chip
                              clickable={false}
                              icon={
                                user?.approved ? (
                                  <CheckCircleIcon
                                    className={getChipIconColor(
                                      getChipColor(
                                        null,
                                        user,
                                        deliverable?.review?.end
                                      )
                                    )}
                                  />
                                ) : (
                                  <PendingCircleIcon
                                    className={getChipIconColor(
                                      getChipColor(
                                        null,
                                        user,
                                        deliverable?.review?.end
                                      )
                                    )}
                                  />
                                )
                              }
                              key={user.userid}
                              className={
                                "mr-5 mb-5 border-r-20 deliverable-chip " +
                                getChipColor(
                                  null,
                                  user,
                                  deliverable?.review?.end
                                )
                              }
                              onClick={() => {
                                if (
                                  deliverable?.doc?.length === 0 &&
                                  !excluded
                                ) {
                                  handleApprove(
                                    indexValue,
                                    "reviewer",
                                    reviewerIndex,
                                    userIndex
                                  );
                                }
                              }}
                              label={getNamebyId("users", user.userid)}
                            />
                          </Tooltip>
                        ))
                      )}
                    </Grid>
                  )}
                  {!isWorkflowCall && deliverable.approver.length > 0 && (
                    <Divider className="mt-5 mb-5" />
                  )}
                </>
              )}
            {deliverable?.approver?.length > 0 &&
              deliverable.type !== "EXECUTION" && (
                <>
                  {!isWorkflowCall && (
                    <Grid>
                      <div>Approver</div>
                      {deliverable.approver?.map((data, approverIndex) =>
                        data.users?.map((user, userIndex) => (
                          <Tooltip
                            title={
                              getNamebyId("approver", data.department) || ""
                            }
                            key={user._id + "Approver"}
                          >
                            <Chip
                              clickable={false}
                              icon={
                                user?.approved ? (
                                  <CheckCircleIcon
                                    className={getChipIconColor(
                                      getChipColor(
                                        deliverable?.doc,
                                        user,
                                        deliverable?.approval?.end
                                      )
                                    )}
                                  />
                                ) : (
                                  <PendingCircleIcon
                                    className={getChipIconColor(
                                      getChipColor(
                                        deliverable?.doc,
                                        user,
                                        deliverable?.approval?.end
                                      )
                                    )}
                                  />
                                )
                              }
                              key={user.userid}
                              className={
                                "mr-5 mb-5 border-r-20 deliverable-chip " +
                                getChipColor(
                                  deliverable?.doc,
                                  user,
                                  deliverable?.approval?.end
                                )
                              }
                              onClick={() => {
                                if (
                                  deliverable?.doc?.length === 0 &&
                                  !excluded
                                ) {
                                  if (
                                    !deliverable?.ifa?.issued &&
                                    (!deliverable["approver"][approverIndex]
                                      .users[userIndex].approved ||
                                      !deliverable["approver"][approverIndex]
                                        .users[userIndex].rejected)
                                  ) {
                                    dispatch(
                                      setPopup({
                                        popupType: POPUP_TYPE.error,
                                        popupText: `${deliverable.name} not Issued for Approval`,
                                      })
                                    );
                                    return;
                                  } else {
                                    handleApprove(
                                      indexValue,
                                      "approver",
                                      approverIndex,
                                      userIndex
                                    );
                                  }
                                }
                              }}
                              label={getNamebyId("users", user.userid)}
                            />
                          </Tooltip>
                        ))
                      )}
                    </Grid>
                  )}
                </>
              )}

            {deliverable?.executed && (
              <>
                <Grid>
                  <div>Execution</div>
                  <Tooltip
                    title={
                      getNamebyId("users", deliverable.executed?.userid) || ""
                    }
                  >
                    <Chip
                      className={
                        "mr-5 border-r-20 chip " +
                        (deliverable.executed?.approved
                          ? "chip-success"
                          : "chip-error")
                      }
                      onClick={() => {
                        if (!excluded) {
                          handleExecuted(indexValue, "executed");
                        }
                      }}
                      label={getNamebyId("users", deliverable.executed?.userid)}
                    />
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
          {/* --------------- row 4 end  --------------- */}
        </Grid>
      </div>
    );
  }
);

const DeliverablesList = ({
  deliverables = [],
  isWorkflowCall,
  handleDeliverablSave,
  delDepartments,
  mainSystem,
  systemTrend = {},
  departments = [],
  users = [],
  documentList = [],
  onRowChange = noop,
  projectId = null,
  systemId,
  mainSystemId,
  deviationList,
}) => {
  const { permissions } = useSelector(({ auth }) => auth.currentUser);
  useFocusElement(deliverables);
  const dispatch = useDispatch();
  useMemo(
    () => deliverables?.sort((a, b) => a.orderIndex - b.orderIndex),
    [deliverables]
  );
  const [loading, setLoading] = useState(false);
  // const { sortedRows } = useTableSort(graphOrderRows);
  const [editData, setEditData] = useState({});
  const [selectedDeliverable, setSelectedDeliverable] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  // const [editable, setEditable] = useState(false);
  // const [toggledNoteDialog, setToggledNoteDialog] = useState(false);
  // const [toggledTrendChart, setToggledTrendChart] = useState(false);
  // const [trendChartData, setTrendChartData] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});
  const [editCard, setEditCard] = useState(false);
  const [noteList, setNoteList] = useState(false);
  const [noteDetails, setNoteDetails] = useState(false);
  const [scheduledDeliverable, setScheduledDeliverable] = useState(null);
  const [editNote, setEditNote] = useState({});
  const [deliverableDates, setDeliverableDates] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [expectedDateRange, setExpectedDateRange] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const [reorder, setReorder] = useState(false);
  const [deliverableList, setDeliverableList] = useState([]);
  const [openDuration, setOpenDuration] = useState(false);
  const [openReSchedule, setOpenReSchedule] = useState(false);
  const [durationData, setDurationData] = useState();
  const [autoScheduledDel, setAutoScheduledDel] = useState();
  const [prevAutoScheduledDel, setPrevAutoScheduledDel] =
    useState(deliverables);
  const [isDeliverable, setIsDeliverable] = useState(false);
  const [isAutoSchedule, setIsAutoSchedule] = useState(false);

  // Deliverable Approve/Review
  const [openReviewApprove, setOpenReviewApprove] = useState(false);
  const [selectedDel, setSelectedDel] = useState(null);

  // User TimeEntry Popup
  const [openTimeEntryModal, setOpenTimeEntryModal] = useState(false);
  const [deliverableUserList, setDeliverableUserList] = useState([]);
  const [savingError, setSavingError] = useState(false);

  useEffect(() => {
    setDeliverableList(deliverables);
  }, [deliverables]);

  useEffect(() => {
    let anchor = {};
    deliverables.forEach((deliverable) => {
      anchor[`${deliverable?._id}`] = null;
    });
    setAnchorEl(anchor);
  }, [deliverables]);

  // const renderRows = useMemo(() => {
  //   if (editable) {
  //     return sortedRows
  //       .map((row, idx) => ({
  //         ...row,
  //         ...(idx === editIndex && editData),
  //         predecessors: row.predecessors
  //           ?.map((pre) => deliverables.find((d) => d._id === pre)?.name)
  //           .join(", "),
  //       }))
  //       .map((row) => ({
  //         ...row,
  //         start: row.start && moment(row.start).format("MM/DD/YY"),
  //         end: row.end && moment(row.end).format("MM/DD/YY"),
  //         completion:
  //           row.completion && moment(row.completion).format("MM/DD/YY"),
  //       }));
  //   } else {
  //     return sortedRows
  //       .map((row, idx) => ({
  //         ...row,
  //         ...(idx === editIndex && editData),
  //         predecessors: row.predecessors
  //           ?.map((pre) => deliverables.find((d) => d._id === pre)?.name)
  //           .join(", "),
  //         department: departments.find((d) => d._id === row.department)?.label,
  //         resource: row.resource.map(
  //           (uid) => users.find((u) => u._id === uid)?.name
  //         ),
  //         approver: row.approver.map(
  //           (did) => departments.find((d) => d._id === did)?.label
  //         ),
  //         reviewer: row.reviewer.map(
  //           (did) => departments.find((d) => d._id === did)?.label
  //         ),
  //       }))
  //       .map((row) => ({
  //         ...row,
  //         start: row.start && moment(row.start).format("MM/DD/YY"),
  //         end: row.end && moment(row.end).format("MM/DD/YY"),
  //         completion:
  //           row.completion && moment(row.completion).format("MM/DD/YY"),
  //       }));
  //   }
  //   if (editData) {
  //   }
  // }, [
  //   sortedRows,
  //   editIndex,
  //   editData,
  //   editable,
  //   deliverables,
  //   departments,
  //   users,
  // ]);

  useEffect(() => {
    if (editCard || noteList || noteDetails) {
      setEditData(deliverables.find((del) => del._id === editData._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverables]);

  useEffect(() => {
    deliverables.map((del) => {
      const docData = documentList.filter((doc) => doc.deliverable === del._id);
      if (docData) {
        del.doc = docData;
      } else {
        del.doc = [];
      }
      return del;
    });
  }, [deliverables, documentList]);

  // const handleEditButton = (idx) => {
  //   if (editIndex === idx) {
  //     setEditIndex(-1);
  //     onRowChange(editData);
  //   } else {
  //     setEditIndex(idx);
  //     setEditData(deliverables[idx]);
  //   }
  // };
  const projects = useSelector((state) => state.projects.results);
  const project = projects.find((project) => project._id === projectId);
  const organization = useSelector((state) =>
    state.organizations.results.find(
      (result) => result._id === project?.organization
    )
  );

  const handleNoteUpdate = async (note) => {
    setLoading(true);
    await dispatch(
      updateDeliverableNote({
        ...note,
        mainId: mainSystem ? mainSystem : selectedDeliverable._id,
        _id: editData._id,
      })
    );
    setLoading(false);
  };

  const handleNoteCreate = async (note) => {
    note.date = moment(note.date).format("YYYY-MM-DD[T00:00:00.000Z]");
    setLoading(true);
    await dispatch(
      createDeliverableNote({
        ...note,
        mainId: mainSystem ? mainSystem : selectedDeliverable._id,
        _id: editData._id,
      })
    );
    setLoading(false);
  };

  const handleNoteDelete = async (noteId) => {
    setLoading(true);
    await dispatch(
      deleteDeliverableNote({
        noteId,
        mainId: mainSystem ? mainSystem : selectedDeliverable._id,
        _id: editData._id,
      })
    );
    setLoading(false);
  };

  // const showTrendChart = (deliverable) => {
  //   const samples = systemTrend.samples
  //     .map((s) => ({
  //       ...s,
  //       deliverable: s.deliverables.find(
  //         (d) => d.deliverable === deliverable._id
  //       ),
  //     }))
  //     .sort((a, b) => moment(a.date) > moment(b.date));
  //   const labels = ["Date", `${deliverable.name} EV`, `${deliverable.name} PV`];
  //   const data = samples.map(({ date, deliverable: { EV, PV } }) => [
  //     moment(date).format("MM/DD/YY"),
  //     EV,
  //     PV,
  //   ]);
  //   setTrendChartData([labels, ...data]);
  //   setToggledTrendChart(true);
  // };

  const openCalendarPopup = (event, index, deliverable) => {
    setEditIndex(index);
    setDeliverableDates([
      {
        startDate: new Date(moment(deliverables[index]?.start?.split("T")[0])),
        endDate: new Date(moment(deliverables[index]?.end?.split("T")[0])),
        key: "selection",
      },
    ]);
    setAnchorEl({ ...anchorEl, [`${deliverable}`]: event.currentTarget });
  };

  const closeCalendarPopup = (deliverable) => {
    setAnchorEl({
      ...anchorEl,
      [deliverable]: null,
    });
    setExpectedDateRange({
      startDate: null,
      endDate: null,
    });
  };

  const saveDates = (data) => {
    closeCalendarPopup();
    handleDurationClose();
    handleRescheduleClose();
    let updatedData = {
      _id: data._id,
      scheduled: true,
      start: data.start,
      end: data.end,
      draft: {
        start: data.draft.start,
        end: data.draft.end,
        status: data?.draft?.status,
        completion: data?.draft?.completion,
      },
      ifr: {
        start: data.ifr.start,
        end: data.ifr.end,
        issued: data?.ifr?.issued,
        completion: data?.ifr?.completion,
      },
      review: {
        start: data.review.start,
        end: data.review.end,
        status: data?.review?.status,
        completion: data?.review?.completion,
      },
      ifa: {
        start: data.ifa.start,
        end: data.ifa.end,
        issued: data?.ifa?.issued,
        completion: data?.ifa?.completion,
      },
      approval: {
        start: data.approval.start,
        end: data.approval.end,
        status: data?.approval?.status,
        completion: data?.approval?.completion,
      },
      duration: data.duration,
      draftDuration: data.draftDuration,
      ifrDuration: data.ifrDuration,
      reviewDuration: data.reviewDuration,
      ifaDuration: data.ifaDuration,
      approvalDuration: data.approvalDuration,
    };
    if (data.master) {
      updatedData = {
        ...updatedData,
        masterDuration: data.masterDuration,
        masterDate: {
          start: data.masterDate.start,
          end: data.masterDate.end,
          completion: data?.masterDate?.completion,
        },
      };
    }
    onRowChange(updatedData, undefined, true);
  };

  const saveDeliverableDates = (id) => {
    let data = { ...deliverables[editIndex] };

    data.start = moment(deliverableDates[0].startDate).format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    data.end = moment(deliverableDates[0].endDate).format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );

    if (data.completion) {
      data.completion = moment(data.completion).format(
        "YYYY-MM-DDT00:00:00.000[Z]"
      );
    } else {
      data.completion = null;
    }
    if (data.type === "EXECUTION") {
      onRowChange(data);
      closeCalendarPopup();
      if (isWorkflowCall) {
        handleDeliverablSave(data);
      }
    } else {
      if (
        moment(data.end).diff(moment(data.start), "days") !== data.duration &&
        project?.autoSchedulerEnabled
      ) {
        setOpenDuration(true);
        setDurationData(data);
      } else {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.confirm,
            popupText:
              "Changing dates will reset deliverable approval schedule",
            confirmText: "Proceed",
            cancelText: "Cancel",
            onConfirm: () => {
              data.ifr.start = data.start;
              data.ifr.end = data.start;
              data.draft.start = data.start;
              data.draft.end = data.start;
              data.ifa.start = data.start;
              data.ifa.end = data.start;
              data.review.start = data.start;
              data.review.end = data.start;
              data.approval.start = data.start;
              data.approval.end = data.end;
              if (data.master) {
                data.masterDate.start = data.end;
                data.masterDate.end = data.end;
              }
              if (isWorkflowCall) {
                handleDeliverablSave(data);
              } else {
                onRowChange(data, "Date Reset");
              }
              closeCalendarPopup();
            },
            onCancel: () => {
              closeCalendarPopup();
            },
          })
        );
      }
    }
  };

  const handleDurationClose = () => {
    setOpenDuration(false);
  };

  const handleRescheduleClose = () => {
    setOpenReSchedule(false);
  };

  const handleConfirm = (userAction, userActionDate) => {
    const { delIndex, key, index, userIndex } = selectedDel;

    if (userAction === "reject") {
      if (
        key === "approver" &&
        !deliverables[delIndex]?.ifa?.issued &&
        !deliverables[delIndex][key][index].users[userIndex].rejected
      ) {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: `${deliverables[delIndex].name} not Issued for Approval`,
          })
        );
        return;
      }

      let data = _.cloneDeep(deliverables[delIndex]);
      data[key][index].users[userIndex].rejected =
        !data[key][index].users[userIndex].rejected;
      data[key][index].users[userIndex].approved = false;
      data[key][index].users[userIndex].date = userActionDate;
      setOpenReviewApprove(false);
      onRowChange(data);
      return;
    }

    if (
      key === "reviewer" &&
      !deliverables[delIndex]?.ifr?.issued &&
      !deliverables[delIndex][key][index].users[userIndex].approved
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: `${deliverables[delIndex].name} not Issued for Review`,
        })
      );
      return;
    }

    if (
      key === "approver" &&
      !deliverables[delIndex]?.ifa?.issued &&
      (!deliverables[delIndex][key][index].users[userIndex].approved ||
        !deliverables[delIndex][key][index].users[userIndex].rejected)
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: `${deliverables[delIndex].name} not Issued for Approval`,
        })
      );
      return;
    }

    let data = _.cloneDeep(deliverables[delIndex]);
    data[key][index].users[userIndex].approved =
      !data[key][index].users[userIndex].approved;

    if (key === "approver") {
      data[key][index].users[userIndex].rejected = false;
    }

    data[key][index].users[userIndex].date = userActionDate;
    setOpenReviewApprove(false);
    onRowChange(data);
  };

  const handleApprove = (delIndex, key, index, userIndex) => {
    let data = _.cloneDeep(deliverables[delIndex]);
    const userActionDate = data[key][index].users[userIndex].date;
    const isApproved = data[key][index].users[userIndex].approved;
    setSelectedDel({
      delIndex,
      key,
      index,
      userIndex,
      userActionDate,
      isApproved,
    });
    setOpenReviewApprove(true);
  };

  const handleReject = (delIndex, key = "approver", index, userIndex) => {
    console.log({ delIndex, key, index, userIndex });
    let data = _.cloneDeep(deliverables[delIndex]);
    const userActionDate = data[key][index].users[userIndex].date;
    setSelectedDel({ delIndex, key, index, userIndex, userActionDate });
    setSelectedDel({ delIndex, key, index, userIndex });
    setOpenReviewApprove(true);
  };

  const handleExecuted = (delIndex, key) => {
    let data = deliverables[delIndex];
    data[key].approved = !data[key].approved;
    onRowChange(data);
  };

  const getNamebyId = (key, uid) => {
    if (key === "users") {
      return users.find((user) => user._id === uid)?.name;
    }
    if (key === "approver" || key === "reviewer") {
      return !isWorkflowCall
        ? departments.find((dept) => dept._id === uid)?.label
        : delDepartments.find((dept) => dept._id === uid)?.label;
    }
  };

  const getDepartmentUsers = () => {
    let groupedData = [];
    departments.forEach((dept) => {
      const obj = {
        label: dept.label,
        options: [],
      };
      users.forEach((usr) => {
        if (usr.department === dept._id) {
          const userData = {
            label: usr.name,
            value: usr._id,
            department: usr.department,
          };
          obj.options.push(userData);
        }
      });
      groupedData.push(obj);
    });
    return groupedData;
  };

  const getUsers = () => {
    let userList = [];
    users.forEach((user) => {
      const obj = {
        label: user.name,
        value: user._id,
        department: user.department,
      };
      userList.push(obj);
    });
    return userList;
  };

  const getDepartments = () => {
    let deptList = [];
    (!isWorkflowCall ? departments : delDepartments).forEach((dept) => {
      const obj = {
        label: dept.label,
        value: dept._id,
      };
      deptList.push(obj);
    });
    return deptList;
  };

  const getDefaultDept = (deliverable) => {
    const dept = {
      value: deliverable.department,
      label: getNamebyId("approver", deliverable.department),
    };
    return dept;
  };

  const getDefaultExec = (deliverable) => {
    let executed = null;
    if (deliverable.executed) {
      executed = {
        value: deliverable.executed?.userid,
        label: getNamebyId("users", deliverable.executed?.userid),
        approved: deliverable.executed?.approved,
      };
    } else {
      executed = {
        value: "",
        label: "",
        approved: false,
      };
    }
    return executed;
  };

  const getDefaultResources = (deliverable) => {
    const userList = [];
    deliverable?.resource?.forEach((usr) => {
      const obj = { label: getNamebyId("users", usr), value: usr };
      userList.push(obj);
    });
    return userList;
  };

  const getDefaultApprover = (deliverable, key) => {
    const userList = [];
    deliverable[key]?.forEach(async (data) => {
      data.users.forEach(async (user) => {
        const obj = {
          label: await getNamebyId("users", user.userid),
          value: user.userid,
          approved: user.approved,
          department: data.department,
        };
        userList.push(obj);
      });
    });
    return userList;
  };

  const handleDataChange = async ({
    target: { type, value, name, value1 = "" },
  }) => {
    if (type === "date" && value !== null) {
      value = moment(value).format("YYYY-MM-DDT00:00:00.000[Z]");
      value1 = moment(value1).format("YYYY-MM-DDT00:00:00.000[Z]");
    }

    const updatedData = {
      ...editData,
      [name]:
        name === "status"
          ? value >= 0 && value <= 100
            ? value
            : editData?.status
          : value,
      draft: editData.draft ? editData.draft : {},
    };
    if (name === "startEnd") {
      updatedData.start = value;
      updatedData.draft.start = value;
      updatedData.end = value1;
      updatedData.approval.end = value1;
      if (
        moment(value1).diff(moment(value), "days") !== updatedData.duration &&
        project?.autoSchedulerEnabled &&
        updatedData.type !== "EXECUTION"
      ) {
        setOpenDuration(true);
        setDurationData(updatedData);
      } else {
        if (project?.autoSchedulerEnabled) {
          setScheduledDeliverable(updatedData._id);
          updatedData.draft.start = updatedData.start;
          updatedData.draft.end = getHolidayAdjustedEndDate(
            updatedData.draft.start,
            editData.draftDuration || 0,
            organization?.organizationHoliday
          );
          updatedData.ifr.start = updatedData.draft.end;
          updatedData.ifr.end = getHolidayAdjustedEndDate(
            updatedData.ifr.start,
            editData.ifrDuration || 0,
            organization?.organizationHoliday
          );
          updatedData.review.start = updatedData.ifr.end;
          updatedData.review.end = getHolidayAdjustedEndDate(
            updatedData.review.start,
            editData.reviewDuration || 0,
            organization?.organizationHoliday
          );
          updatedData.ifa.start = updatedData.review.end;
          updatedData.ifa.end = getHolidayAdjustedEndDate(
            updatedData.ifa.start,
            editData.ifaDuration || 0,
            organization?.organizationHoliday
          );
          updatedData.approval.start = updatedData.ifa.end;
          updatedData.approval.end = getHolidayAdjustedEndDate(
            updatedData.approval.start,
            editData.approvalDuration || 0,
            organization?.organizationHoliday
          );
          updatedData.masterDate.start = updatedData.approval.end;
          updatedData.masterDate.end = getHolidayAdjustedEndDate(
            updatedData.masterDate.start,
            editData.masterDuration || 0,
            organization?.organizationHoliday
          );
          saveDates(updatedData);
        }
      }
    }

    if (name === "department") {
      updatedData.defaultDepartment = await getDefaultDept(updatedData);
      updatedData.resource = [];
      updatedData.defaultResource = [];
    }

    if (name === "executed") {
      if (value) {
        const selected = value;
        updatedData.defaultExecuted = {
          ...updatedData.defaultExecuted,
          value: selected.value,
          label: selected.label,
        };
        updatedData.executed = {
          userid: updatedData.defaultExecuted.value,
          approved: updatedData.defaultExecuted.approved,
        };
      } else {
        updatedData.defaultExecuted = {
          value: "",
          label: "",
          approved: false,
        };
        updatedData.executed = null;
      }
    }
    if (name === "resource") {
      updatedData.resource = [];
      updatedData.defaultResource = value;
      value.forEach((val) => updatedData.resource.push(val.value));
    }

    if (name === "approver") {
      updatedData.approver = [];
      updatedData.defaultApprover = value;
      departments.forEach((dept) => {
        const usersList = value.filter((val) => val.department === dept._id);
        if (usersList.length > 0) {
          const data = {
            department: dept._id,
            users: [],
          };
          usersList.forEach((user) => {
            const userData = {
              userid: user.value,
              approved: user.approved,
            };
            data.users.push(userData);
          });
          updatedData.approver.push(data);
        }
      });
    }
    if (name === "type") {
      updatedData.type = value;
      if (value !== "EXECUTION") {
        updatedData.executed = null;
      }
    }
    if (name === "draftEnd") {
      updatedData.draft.end = value;
    }
    if (name === "ifrStart") {
      updatedData.ifr.start = value;
    }
    if (name === "ifrEnd") {
      updatedData.ifr.end = value;
      if (project?.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.review.start = updatedData.ifr.end;
        updatedData.review.end = getHolidayAdjustedEndDate(
          updatedData.review.start,
          editData.reviewDuration || 0,
          organization?.organizationHoliday
        );
        updatedData.ifa.start = updatedData.review.end;
        updatedData.ifa.end = getHolidayAdjustedEndDate(
          updatedData.ifa.start,
          editData.ifaDuration || 0,
          organization?.organizationHoliday
        );
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          editData.approvalDuration || 0,
          organization?.organizationHoliday
        );
      }
    }
    if (name === "reviewStart") {
      updatedData.review.start = value;
    }
    if (name === "reviewEnd") {
      updatedData.review.end = value;
      if (project?.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.ifa.start = updatedData.review.end;
        updatedData.ifa.end = getHolidayAdjustedEndDate(
          updatedData.ifa.start,
          editData.ifaDuration || 0,
          organization?.organizationHoliday
        );
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          editData.approvalDuration || 0,
          organization?.organizationHoliday
        );
      }
    }
    if (name === "ifaStart") {
      updatedData.ifa.start = value;
    }
    if (name === "ifaEnd") {
      updatedData.ifa.end = value;
      if (project?.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          editData.approvalDuration || 0,
          organization?.organizationHoliday
        );
      }
    }
    if (name === "approvalStart") {
      updatedData.approval.start = value;
    }
    if (name === "approvalEnd") {
      updatedData.approval.end = value;
    }
    if (name === "masterDateStart") {
      updatedData.masterDate.start = value;
    }
    if (name === "masterDateEnd") {
      updatedData.masterDate.end = value;
    }
    if (name === "ifrIssued") {
      updatedData.ifr.issued = value;
    }
    if (name === "ifaIssued") {
      updatedData.ifa.issued = value;
    }
    if (name === "draftCompletion") {
      updatedData.draft.completion = value;
    }
    if (name === "ifrCompletion") {
      updatedData.ifr.completion = value;
    }
    if (name === "reviewCompletion") {
      updatedData.review.completion = value;
    }
    if (name === "ifaCompletion") {
      updatedData.ifa.completion = value;
    }
    if (name === "approvalCompletion") {
      updatedData.approval.completion = value;
    }
    if (name === "masterDateCompletion") {
      updatedData.masterDate.completion = value;
    }
    if (name === "draftStatus") {
      if (value >= 0 && value <= 100) updatedData.draft.status = value;
    }
    if (name === "reviewStatus") {
      if (value >= 0 && value <= 100) updatedData.review.status = value;
    }
    if (name === "approvalStatus") {
      if (value >= 0 && value <= 100) updatedData.approval.status = value;
    }

    if (name === "reviewer") {
      updatedData.reviewer = [];
      updatedData.defaultReviewer = value;
      departments.forEach((dept) => {
        const usersList = value.filter((val) => val.department === dept._id);
        if (usersList.length > 0) {
          const data = {
            department: dept._id,
            users: [],
          };
          usersList.forEach((user) => {
            const userData = {
              userid: user.value,
              approved: user.approved,
            };
            data.users.push(userData);
          });
          updatedData.reviewer.push(data);
        }
      });
    }
    if (name === "masterFile") {
      updatedData[name] = value;
    }
    if (name === "removeMasterFile") {
      updatedData["masterFile"] = null;
    }
    setEditData(updatedData);
  };

  const handleDateChange = (selection) => {
    const endDate = new Date(
      getHolidayAdjustedEndDate(
        selection.startDate,
        selectedDeliverable.duration,
        organization?.organizationHoliday
      )
    );
    setDeliverableDates([
      {
        startDate: new Date(moment(selection.startDate)),
        endDate: new Date(moment(selection.endDate)),
        key: "selection",
      },
    ]);
    setExpectedDateRange({
      startDate: new Date(moment(selection.startDate)),
      endDate: new Date(moment(endDate)),
    });
  };

  const showDetails = async (index) => {
    deliverables[index].defaultDepartment = await getDefaultDept(
      deliverables[index]
    );
    deliverables[index].defaultResource = await getDefaultResources(
      deliverables[index]
    );
    deliverables[index].defaultApprover = await getDefaultApprover(
      deliverables[index],
      "approver"
    );
    deliverables[index].defaultReviewer = await getDefaultApprover(
      deliverables[index],
      "reviewer"
    );
    deliverables[index].defaultExecuted = await getDefaultExec(
      deliverables[index]
    );
    if (deliverables[index].master && !deliverables[index].masterDate) {
      deliverables[index].masterDate = {
        start: moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]"),
        end: moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]"),
        completion: null,
      };
    }
    setEditData(_.cloneDeep(deliverables[index]));
    setEditCard(true);
    setNoteList(false);
    setNoteDetails(false);
  };

  const showNotes = (index) => {
    setEditData({ ...deliverables[index] });
    setEditCard(false);
    setNoteDetails(false);
    setNoteList(true);
  };

  const showNotesFromDel = () => {
    var index = deliverables.findIndex((del) => del._id === editData._id);
    showNotes(index);
  };

  const showNotesDetail = (index) => {
    setEditNote(editData.notes[index]);
    setNoteDetails(true);
    setEditCard(false);
    setNoteList(false);
  };

  const closeEditDelCard = () => {
    setEditData(null);
    setEditCard(false);
    setNoteList(false);
    setNoteDetails(false);
  };

  const saveEditData = () => {
    onRowChange(
      editData,
      undefined,
      editData._id === scheduledDeliverable ? true : false
    );
    setScheduledDeliverable(false);
    closeEditDelCard();
  };

  const backToDel = () => {
    setEditCard(true);
    setNoteList(false);
    setNoteDetails(false);
  };

  const backToNotes = () => {
    setNoteList(true);
    setEditCard(false);
    setNoteDetails(false);
  };

  const open = Boolean(anchorEl);
  const calendar_id = open ? "simple-popover" : undefined;
  const { excluded } = useUserPermissions(ALLOWED_ROLES);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const movedArray = arrayMove(deliverableList, oldIndex, newIndex).map(
        (deliverable, index) => {
          return {
            ...deliverable,
            orderIndex: index + 1,
          };
        }
      );
      setDeliverableList(movedArray);
    }
  };

  const handleSave = () => {
    setLoading(true);
    if (isWorkflowCall) {
      handleDeliverablSave(deliverableList, true);
      setLoading(false);
    } else {
      dispatch(
        updateDeliverableOrder(
          {
            deliverables: (isAutoSchedule
              ? addScheduled(autoScheduledDel)
              : deliverableList
            ).map((val) => {
              return {
                ...restrict(val, [
                  "_id",
                  "orderIndex",
                  "name",
                  "start",
                  "end",
                  "completion",
                  "status",
                  "plannedHours",
                  "workedHours",
                  "predecessors",
                  "chartData",
                  "extended_date",
                  "activity",
                  "department",
                  "resource",
                  "comment",
                  "milestone",
                  "executed",
                  "approver",
                  "reviewer",
                  "type",
                  "draft",
                  "ifr",
                  "ifa",
                  "review",
                  "approval",
                  "duration",
                  "draftDuration",
                  "ifrDuration",
                  "reviewDuration",
                  "ifaDuration",
                  "approvalDuration",
                  "notes",
                  "masterDate",
                  "masterDuration",
                  "scheduled",
                ]),
              };
            }),
            _id: mainSystem,
          },
          setLoading
        )
      );
    }
    setReorder(false);
  };

  const handleOpenTimeEntryModal = () => {
    getDeliverableUsers();
    setOpenTimeEntryModal(true);
  };

  const handleCloseTimeEntryModal = () => {
    setDeliverableUserList(undefined);
    setOpenTimeEntryModal(false);
  };

  const handleUserHourChange = ({ name, value, index }) => {
    const userData = [...deliverableUserList];
    if (
      name.split(" ")[0] === "plannedMinutesText" ||
      name.split(" ")[0] === "workedMinutesText"
    ) {
      userData[index][name.split(" ")[0]] = value;
      let totalMinutes = 0;
      let newVal = value?.trim().match(HOUR_MINUTE_REGEX)
        ? value?.trim()
        : value?.trim()?.match(HOUR_REGEX)
        ? `${value?.trim()} 0m`
        : value?.trim()?.match(MINUTE_REGEX)
        ? `0h ${value?.trim()}`
        : null;
      if (newVal) {
        const match = newVal.match(HOUR_MINUTE_REGEX);
        if (match) {
          const hours = parseInt(match[1]);
          const minutes = match[2] ? parseInt(match[2]) : 0;
          totalMinutes += hours * 60 + minutes;
        }
        userData[index][name.split(" ")[1]] = totalMinutes;
      } else {
        userData[index][name.split(" ")[1]] = 0;
      }
    } else {
      userData[index][name] = value;
    }

    setDeliverableUserList(userData);
  };

  const getDeliverableUsers = () => {
    // console.log("$ workedMinutes =", editData?.workedMinutes);
    setDeliverableUserList(
      editData?.workedMinutes?.map((m) => ({
        id: m.user,
        name: users.find((user) => user._id === m.user)?.name,
        plannedMinutes: 0,
        plannedMinutesText: "",
        workedMinutes: 0,
        workedMinutesText: "",
        date: moment().format("YYYY-MM-DD[T00:00:00.000Z]"),
        totalWorkedMinutes: m.totalWorkedMinutes,
        totalWorkedMinutesText: `${parseInt(m.totalWorkedMinutes / 60)}h ${
          m.totalWorkedMinutes % 60
        }m`,
      }))
    );
  };

  const handleSaveUserTimeData = async () => {
    let isError = false;
    deliverableUserList
      ?.filter(
        (f) => f.plannedMinutesText?.trim() || f.workedMinutesText?.trim()
      )
      ?.forEach((m) => {
        let tempError = false;
        if (m.workedMinutesText) {
          tempError = !checkHourMinuteRegex(m.workedMinutesText);
        }
        if (m.plannedMinutesText) {
          tempError = !checkHourMinuteRegex(m.plannedMinutesText);
        }
        if (tempError) {
          isError = tempError;
          setSavingError(tempError);
        }
      });
    if (!isError) {
      const data = deliverableUserList
        ?.filter((f) => f.plannedMinutes || f.workedMinutes)
        ?.map((m) => {
          return {
            userId: m.id,
            ...(m.plannedMinutes ? { plannedMinutes: m.plannedMinutes } : {}),
            ...(m.workedMinutes
              ? { workedMinutes: m.workedMinutes, date: m.date }
              : {}),
          };
        });
      // console.log("$ data = ", data);
      if (data?.length) {
        setLoading(true);
        await postWorkedHours(projectId, mainSystemId, editData?._id, data)
          .then(async (resp) => {
            await dispatch(updateDeliverables(resp?.data));
            // console.log("$ return response =", resp);
            // if (!isWorkflowCall) {
            //   const saveData = {
            //     ...editData,
            //     workedMinutes: editData?.workedMinutes?.map((m) => {
            //       const userData = resp?.find((f) => f.user === m.user);
            //       return userData ? userData : m;
            //     }),
            //   };
            //   const newMetaSystem = {
            //     ...metaSystem?.mainSystem,
            //     deliverables: metaSystem?.mainSystem?.deliverables?.map((m) =>
            //       m._id === saveData?._id ? saveData : m
            //     ),
            //   };
            //   console.log("$ response newMetaSystem = ", newMetaSystem);
            // }
            setLoading(false);
          })
          ?.catch((e) => {
            console.log("some error occured in handleSaveUserTimeData ", e);
            dispatch(
              setPopup({
                popupType: POPUP_TYPE.error,
                popupText: e?.response?.data?.info
                  ? e?.response?.data?.info
                  : "Some error occured",
              })
            );
            setLoading(false);
          });
        handleCloseTimeEntryModal();
      }
    }
  };

  const saveAutoDel = (isWorkflowDel) => {
    if (isWorkflowDel) {
      handleDeliverablSave(addScheduled(autoScheduledDel), false, false);
    } else {
      handleSave();
    }
    setOpenDuration(false);
  };
  const autoScheduleDeliverablesHandler = () => {
    let data = autoScheduleDeliverables(deliverables);
    setAutoScheduledDel(data);
    setPrevAutoScheduledDel(data);
    setOpenDuration(true);
    setIsDeliverable(true);
    setIsAutoSchedule(true);
  };

  const durationChangeHandler = (duration, index) => {
    let data = handleDelDurationChange(autoScheduledDel, duration, index);
    setAutoScheduledDel(data);
  };

  const changedDatehandler = (startDate, endDate, index) => {
    let data = handleChangedDate(
      autoScheduledDel,
      startDate,
      endDate,
      index,
      true,
      null,
      isDeliverable
    );
    setAutoScheduledDel(data);
  };

  const updateIsDeliverable = (key, index = null) => {
    if (index !== null) {
      let data = _.cloneDeep(autoScheduledDel[index]);
      setDurationData(data);
    }
    setIsDeliverable(key);
  };

  const saveSubTaskdata = (data) => {
    setIsDeliverable(true);
    let updatedData = autoScheduledDel;
    const unModifiedData = _.cloneDeep(autoScheduledDel);
    const delIndex = autoScheduledDel.findIndex((del) => data._id === del._id);
    updatedData[delIndex] = data;

    let newData = handleChangedDate(
      updatedData,
      data.start,
      data.end,
      delIndex,
      false,
      unModifiedData
    );

    setAutoScheduledDel(newData);
    setPrevAutoScheduledDel(newData);
  };

  const addScheduled = (data) => {
    let deliverables = data.map((del) => {
      return {
        ...del,
        scheduled: true,
      };
    });

    return deliverables;
  };

  const getAssociatedDeviation = (deliverableIdToMatch) => {
    const filteredDeviationList = deviationList.filter((deviation) =>
      deviation.deliverables.some(
        (deliverable) => deliverable.deliverableId === deliverableIdToMatch
      )
    );
    return filteredDeviationList;
  };

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={editCard || noteList || noteDetails ? 7 : 12}>
        <Card>
          <CardHeader
            title={
              <div className="d-jsb-c">
                <Typography variant="h6">Deliverables</Typography>
                <div>
                  {!reorder && deliverables.length !== 0 && (
                    <IconButton
                      onClick={() => {
                        setReorder(true);
                        closeEditDelCard();
                      }}
                      className="round-icon-button"
                    >
                      <ReorderIcon />
                    </IconButton>
                  )}
                  {(project?.autoSchedulerEnabled === false ||
                    isWorkflowCall === true) &&
                    !reorder &&
                    deliverables.length !== 0 && (
                      <IconButton
                        onClick={() => {
                          autoScheduleDeliverablesHandler();
                        }}
                        className="round-icon-button ml-10"
                      >
                        <AutoScheduleIcon />
                      </IconButton>
                    )}
                </div>
                {reorder && deliverables.length !== 0 && (
                  <div>
                    <Button
                      size="small"
                      colour="default"
                      variant="contained"
                      onClick={() => {
                        setReorder(false);
                        setDeliverableList(deliverables);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="ml-10"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            }
          />
          <CardContent className="max-ht-700 scroll-y">
            <BodySortable
              onSortEnd={onSortEnd}
              useDragHandle
              displayRowCheckbox={false}
            >
              {/* --------------- deliverable list start  --------------- */}
              {deliverableList.map((deliverable, index) => {
                const timelineType =
                  deliverable?.type !== undefined &&
                  deliverable?.type !== "" &&
                  deliverable?.type !== "EXECUTION";
                return (
                  <Row
                    permissions={permissions}
                    key={deliverable._id}
                    deliverable={deliverable}
                    index={index}
                    indexValue={index}
                    editData={editData}
                    editCard={editCard}
                    showDetails={showDetails}
                    open={open}
                    timelineType={timelineType}
                    excluded={excluded}
                    getNamebyId={getNamebyId}
                    handleApprove={handleApprove}
                    handleExecuted={handleExecuted}
                    openCalendarPopup={openCalendarPopup}
                    closeCalendarPopup={closeCalendarPopup}
                    showNotes={showNotes}
                    calendar_id={calendar_id}
                    anchorEl={anchorEl}
                    setDeliverableDates={setDeliverableDates}
                    deliverableDates={deliverableDates}
                    saveDeliverableDates={saveDeliverableDates}
                    reorder={reorder}
                    project={project}
                    selectedDeliverable={selectedDeliverable}
                    setSelectedDeliverable={setSelectedDeliverable}
                    dispatch={dispatch}
                    handleReject={handleReject}
                    noteList={noteList}
                    organization={organization}
                    expectedDateRange={expectedDateRange}
                    setExpectedDateRange={setExpectedDateRange}
                    handleDateChange={handleDateChange}
                    isWorkflowCall={isWorkflowCall}
                    setOpenReSchedule={setOpenReSchedule}
                    setDurationData={setDurationData}
                    deviationList={getAssociatedDeviation(deliverable?._id)}
                  ></Row>
                );
              })}
            </BodySortable>
            {/* --------------- deliverable list end  --------------- */}
          </CardContent>
        </Card>
      </Grid>
      {/* --------------- deliverable details start  --------------- */}
      {editCard && (
        <Grid item xs={5}>
          <EditDeliverable
            permissions={permissions}
            deliverable={editData}
            mainSystem={mainSystem}
            systemTrend={systemTrend}
            departments={departments}
            users={users}
            onRowChange={saveEditData}
            close={closeEditDelCard}
            handleDataChange={handleDataChange}
            getNamebyId={getNamebyId}
            deliverables={deliverables}
            showNotes={showNotesFromDel}
            getDepartmentUsers={getDepartmentUsers}
            getUsers={getUsers}
            getDepartments={getDepartments}
            projectId={projectId}
            excluded={excluded}
            project={project}
            isWorkflowCall={isWorkflowCall}
            handleDeliverablSave={handleDeliverablSave}
            handleOpenTimeEntryModal={handleOpenTimeEntryModal}
            deviationList={getAssociatedDeviation(editData?._id)}
          ></EditDeliverable>
        </Grid>
      )}
      {/* --------------- deliverable details end  --------------- */}
      {/* --------------- user time entry start  --------------- */}
      {openTimeEntryModal && (
        <UserTimeEntryModal
          isOpen={openTimeEntryModal}
          close={handleCloseTimeEntryModal}
          userList={deliverableUserList}
          savingError={savingError}
          handleUserHourChange={handleUserHourChange}
          handleSaveUserTimeData={handleSaveUserTimeData}
        />
      )}
      {/* --------------- user time entry end  --------------- */}
      {/* --------------- deliverable notes list start  --------------- */}
      {noteList && (
        <Grid item xs={5}>
          <DeliverableNoteList
            permissions={permissions}
            deliverable={editData}
            close={closeEditDelCard}
            getNamebyId={getNamebyId}
            backToDel={backToDel}
            handleNoteCreate={handleNoteCreate}
            handleNoteUpdate={handleNoteUpdate}
            showNotesDetail={showNotesDetail}
            excluded={excluded}
          />
        </Grid>
      )}
      {/* --------------- deliverable notes list end  --------------- */}
      {/* --------------- notes details start  --------------- */}
      {!isWorkflowCall && noteDetails && (
        <Grid item xs={5}>
          <EditDeliverableNotes
            permissions={permissions}
            noteData={editNote}
            deliverable={editData}
            close={closeEditDelCard}
            getNamebyId={getNamebyId}
            backToNotes={backToNotes}
            handleNoteUpdate={handleNoteUpdate}
            handleNoteDelete={handleNoteDelete}
            users={users}
            excluded={excluded}
          />
        </Grid>
      )}
      {/* --------------- notes details end  --------------- */}
      {/* --------------- set duration start  --------------- */}
      {openDuration && (
        <SetDuration
          data={isDeliverable ? _.cloneDeep(autoScheduledDel) : durationData}
          openDialog={openDuration}
          onClose={handleDurationClose}
          holidayList={organization?.organizationHoliday}
          onRowChange={saveDates}
          isDeliverable={isDeliverable}
          handeDelDurationChange={durationChangeHandler}
          saveAutoDel={saveAutoDel}
          handleChangedDate={changedDatehandler}
          updateIsDeliverable={updateIsDeliverable}
          isWorkflowCall={isWorkflowCall}
          saveSubTaskdata={saveSubTaskdata}
          prevAutoScheduledDel={prevAutoScheduledDel}
          isAutoSchedule={isAutoSchedule}
        />
      )}
      {openReSchedule && (
        <ReScheduleDeliverable
          data={isDeliverable ? _.cloneDeep(autoScheduledDel) : durationData}
          openDialog={openReSchedule}
          onClose={handleRescheduleClose}
          holidayList={organization?.organizationHoliday}
          onRowChange={saveDates}
          isDeliverable={isDeliverable}
          handeDelDurationChange={durationChangeHandler}
          saveAutoDel={saveAutoDel}
          handleChangedDate={changedDatehandler}
          updateIsDeliverable={updateIsDeliverable}
          saveSubTaskdata={saveSubTaskdata}
          prevAutoScheduledDel={prevAutoScheduledDel}
          isAutoSchedule={isAutoSchedule}
        />
      )}
      {/* --------------- set duration end  --------------- */}
      {loading && <Loader />}
      {openReviewApprove && (
        <UserConfirmDialog
          selectedDel={selectedDel}
          open={openReviewApprove}
          handleClose={() => setOpenReviewApprove(false)}
          handleConfirm={handleConfirm}
        />
      )}
    </Grid>
  );
};

export default memo(DeliverablesList);
