import React, { memo } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { Button as MuiButton, Typography } from "@material-ui/core";
import Helmet from "react-helmet";
import LINKS from "utils/constants/links";
import { Lock } from "@material-ui/icons";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function AccessDenied() {
  return (
    <Wrapper className="ht-100 d-jsb-cen">
      <div className="mb-20">
        <Lock color="primary" fontSize="large"/>
        <Helmet title="404 Error" />
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          Access Denied
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Looks like you don’t have permission to view this page.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Please contact Admin for further details.
        </Typography>
        <Button
          component={Link}
          to={LINKS.OVERVIEW.HREF}
          variant="contained"
          color="secondary"
          mt={2}
        >
          Return to website
        </Button>
      </div>
    </Wrapper>
  );
}

export default memo(AccessDenied);
