export const FE_BASE_URL =
  (window.REACT_APP_PROXY_URL || process.env.REACT_APP_PROXY_URL) + "/";
export const PROXY_URL =
  window.REACT_APP_PROXY_URL || process.env.REACT_APP_PROXY_URL;
export const API_PROXY_URL =
  window.REACT_APP_API_PROXY_URL || process.env.REACT_APP_API_PROXY_URL;
export const APP_GTAG_ID =
  window.REACT_APP_GTAG_ID || process.env.REACT_APP_GTAG_ID;
export const PSPDFKIT_LICENSE_KEY =
  window.REACT_APP_PSPDFKIT_LICENSE_KEY ||
  process.env.REACT_APP_PSPDFKIT_LICENSE_KEY;
export const ENVIRONMENT =
  window.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
export const LIBRARY_FILE_COPY =
  window.REACT_APP_LIBRARY_FILE_COPY || process.env.REACT_APP_LIBRARY_FILE_COPY;
export const ENC_SECRET_KEY = "!Vektor-encryption-key2021#";
export const WEBVIEWER_PATH = "/webviewer/10.4.0/lib";
export const INACTIVE_TIMEOUT = 86400000; // 1 day
export const PROMPT_BEFORE_INACTIVE_TIMEOUT = 60000; // 1 min
