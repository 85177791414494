import React from "react";
import { useSelector } from "react-redux";
import { PERMISSION_TYPES } from "utils/constants";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";

function FeatureFlag({ children, feature }) {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const authorizedFeatures = useSelector((state) => state?.modulePreference?.accessibleFeature);
  if(currentUser.permissions !== PERMISSION_TYPES.superAdmin && feature && Object.values(FEATURE_FLAGS).includes(feature) && !authorizedFeatures?.includes(feature)){
        return <></>
    }
    return children;
}

export default FeatureFlag;
