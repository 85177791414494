import { lazy } from "react";
import { Users } from "react-feather";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";

const SignIn = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'SignIn' */ "pages/auth/SignIn"))
);
const ResetPassword = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ResetPassword' */ "pages/auth/ResetPassword")
  )
);
const ForgotPassword = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ResetPassword' */ "pages/auth/ForgotPassword")
  )
);
const Page404 = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'Page404' */ "pages/auth/Page404")
  )
);
const Page500 = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'Page500' */ "pages/auth/Page500")
  )
);
const AccessDenied = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'AccessDenied' */ "pages/auth/AccessDenied")
  )
);

const authRoutes = Object.freeze({
  id: LINKS.AUTH.TITLE,
  path: LINKS.AUTH.HREF,
  icon: <Users />,
  component: null,
  children: [
    {
      path: LINKS.SIGN_IN.HREF,
      name: LINKS.SIGN_IN.TITLE,
      component: SignIn,
    },
    {
      path: LINKS.RESET_PASSWORD.HREF,
      name: LINKS.RESET_PASSWORD.TITLE,
      component: ResetPassword,
    },
    {
      path: LINKS.FORGOT_PASSWORD.HREF,
      name: LINKS.FORGOT_PASSWORD.TITLE,
      component: ForgotPassword,
    },
    {
      path: LINKS.NOT_FOUND.HREF,
      name: LINKS.NOT_FOUND.TITLE,
      component: Page404,
    },
    {
      path: LINKS.ERROR_PAGE.HREF,
      name: LINKS.ERROR_PAGE.TITLE,
      component: Page500,
    },
    {
      path: LINKS.ACCESS_DENIED.HREF,
      name: LINKS.ACCESS_DENIED.TITLE,
      component: AccessDenied,
    },
  ],
});

export default authRoutes;
