import React, { memo, useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  LinearProgress,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  Typography,
  Avatar,
  Chip,
  Link,
  IconButton,
  Popover,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import styled from "styled-components/macro";
import Select from "react-select";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import LINKS from "utils/constants/links";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import PageHeader from "parts/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getProjects } from "redux/actions/projects";
import { getDeliverable, getSystemDeviations } from "services/api-meta-system";
import { getProjectDetails } from "services/api-project";
import { readMetaSystem } from "redux/actions/metaSystem";
import Loader from "components/Loader";
import { AvatarGroup } from "@material-ui/lab";
import {
  updateDeliverableNote,
  createDeliverableNote,
  deleteDeliverableNote,
} from "services/api-meta-system";
import { updateDeliverable } from "services/api-meta-system";
import { restrict } from "utils/helpers/utility";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import { ReactComponent as UploadIcon } from "icons/upload.svg";
import { ReactComponent as CalendarIcon } from "icons/calendar-2.svg";
import { DocumentHelper } from "pages/Projects/Documents/helpers";
import { getSystemDocs } from "services/api-meta-system";
import DeliverableStatusTimeline from "pages/MetaSystems/Shared/DeliverablesTableNew/components/deliverableStatusTimeline";
import { PERMISSION_TYPES } from "utils/constants";
import useUserPermissions from "utils/hooks/useUserPermission";
import { ALLOWED_ROLES } from "../constant";
import { updateDeliverables } from "redux/actions/metaSystem";
import { DateRange } from "react-date-range";
import { getChipColor } from "pages/MetaSystems/Shared/DeliverablesTableNew/deliverablesList";
import {
  customDayContent,
  getHolidayAdjustedEndDate,
} from "utils/helpers/dateHelper";
import { uploadSingleFile } from "services/api-general";
import SetDuration from "components/SetDuration";
import { Close } from "@material-ui/icons";
import {
  HOUR_MINUTE_REGEX,
  HOUR_REGEX,
  MINUTE_REGEX,
  checkHourMinuteRegex,
  checkTimeformat,
  returnCorrectTimeFormat,
} from "pages/Timesheet/helpers";
import DocumentViewer from "components/DocumentViewer";
import { DeviationHelper } from "pages/Projects/Deviations/helpers";
// import { postWorkedHours } from "services/api-workload-report";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

const NOTE_TYPES = [
  { value: "ACTION", label: "Action" },
  { value: "DECISIONS", label: "Decisions" },
  { value: "ESCALATIONS", label: "Escalations" },
  { value: "LESSONS_LEARNED", label: "Lessons Learned" },
  { value: "INFORMATION", label: "Information" },
];
export const errorMessage = (type) => {
  if (type.includes("Issued")) {
    return (
      <p className="text-error" style={{ textAlign: "left" }}>{`${
        type === "ifrIssued" ? "Draft" : "Review"
      } status is not 100%`}</p>
    );
  } else if (type.includes("Status")) {
    return (
      <p className="text-error" style={{ textAlign: "left" }}>{`Please check ${
        type === "reviewStatus" ? "IFR" : "IFA"
      }`}</p>
    );
  } else {
    return (
      <p className="text-error">{`${
        type === "start" ? "Start" : "End"
      } Date is required.`}</p>
    );
  }
};

export const dateRangeError = (type, variant, deliverable) => {
  let date = deliverable[type]?.[variant.includes("Start") ? "start" : "end"];
  return (
    <p className="text-error" style={{ textAlign: "left" }}>
      {" "}
      {moment(date).isBefore(moment(deliverable?.start))
        ? "Date should not be before minimal date"
        : "Date should not be after maximal date"}
    </p>
  );
};
export const convertDateTimeStamp = (date) => {
  return moment(date?.split("T")[0]).format("YYYY-MM-DD[T00:00:00.000Z]");
  // return moment(date).format("YYYY-MM-DD[T00:00:00.000Z]");
};

const EditDeliverable = () => {
  const { mainSystemId, systemId, projectId, deliverableId } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [deliverable, setDeliverable] = useState({});
  const [departments, setDepartments] = useState([]);
  const [notesDetailModal, setNotesDetailModal] = useState(false);
  const [scheduledDeliverable, setScheduledDeliverable] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [deviationList, setDeviationList] = useState([]);
  const [editNote, setEditNote] = useState({});
  const [error, setError] = useState(false);
  const [usersList, setUsers] = useState([]);
  const [anchorElDates, setAnchorElDates] = useState({
    draftStart: null,
    draftEnd: null,
    ifrStart: null,
    ifrEnd: null,
    reviewStart: null,
    reviewEnd: null,
    ifaStart: null,
    ifaEnd: null,
    approvalStart: null,
    approvalEnd: null,
    masterStart: null,
    masterEnd: null,
    deliverableStart: null,
    deliverableEnd: null,
  });
  const { DEVIATION_STATUS } = DeviationHelper();
  const [masterLoading, setMasterLoading] = useState(false);
  const [openFileView, setOpenFileView] = useState(false);
  const fileMaster = useRef();
  const [dateRanges, setDateRanges] = useState({});
  const [expectedDateRange, setExpectedDateRange] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const [openDuration, setOpenDuration] = useState(false);
  const [durationData, setDurationData] = useState();
  const [deliverableUserList, setDeliverableUserList] = useState([]);
  const [datePickerIndex, setDatePickerIndex] = useState(undefined);
  const { organization, permissions } = useSelector(
    ({ auth }) => auth.currentUser
  );
  const { results: users } = useSelector(({ users }) => users);
  const organizationData = useSelector((state) =>
    state.organizations.results.find((org) => org._id === organization)
  );
  const organizationUsers = users.filter(
    (u) => u.organization === organization
  );
  const filteredPMs = organizationUsers.filter(
    (u) => u.permissions === PERMISSION_TYPES.projectManager
  );
  const filteredSupervisors = organizationUsers.filter(
    (u) => u.permissions === PERMISSION_TYPES.supervisor
  );
  const newNote = {
    type: "ACTION",
    description: "Description",
    date: moment(deliverable?.start?.split("T")[0])?.format("MM/DD/YY"),
    resource: [],
    status: false,
  };

  const history = useHistory();
  const { excluded } = useUserPermissions(ALLOWED_ROLES);

  const getAssociatedDeviation = (deliverableIdToMatch, deviationList) => {
    const filteredDeviationList = deviationList.filter((deviation) =>
      deviation.deliverables.some(
        (deliverable) => deliverable.deliverableId === deliverableIdToMatch
      )
    );

    return filteredDeviationList;
  };

  const getProjectDepartments = async () => {
    setLoading(true);
    await getProjectDetails(projectId)
      .then((resp) => {
        const { assignedUsers } = resp.data;
        let newUserList = assignedUsers.filter((user) => user !== null);
        newUserList.unshift(...filteredSupervisors);
        newUserList.unshift(...filteredPMs);
        setUsers(_.uniqBy(newUserList, "_id"));
        const dept = resp.data.departments?.map(
          (data) => (data = { ...data, label: data.name })
        );
        setDepartments(dept);
      })
      .catch((err) => {
        console.log("error >>>>> ", err);
      });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readMetaSystem({
        system: systemId !== "_" && systemId,
        mainSystem: mainSystemId !== "_" && mainSystemId,
      })
    );
    dispatch(getProjects({ filter: { _id: projectId } }));
    async function fetchDeliverable() {
      await getProjectDepartments();
      await getDeliverable(systemId, deliverableId)
        .then((resp) => {
          setDeliverable({
            ...resp,
            start: convertDateTimeStamp(resp?.start),
            end: convertDateTimeStamp(resp?.end),
            draft: {
              ...resp?.draft,
              start: convertDateTimeStamp(resp?.draft?.start || resp?.start),
              end: convertDateTimeStamp(resp?.draft?.end || resp?.start),
            },
            ifa: {
              ...resp?.ifa,
              start: convertDateTimeStamp(resp?.ifa?.start || resp?.start),
              end: convertDateTimeStamp(resp?.ifa?.end || resp?.start),
            },
            ifr: {
              ...resp?.ifr,
              start: convertDateTimeStamp(resp?.ifr?.start || resp?.start),
              end: convertDateTimeStamp(resp?.ifr?.end || resp?.start),
            },
            review: {
              ...resp?.review,
              start: convertDateTimeStamp(resp?.review?.start || resp?.start),
              end: convertDateTimeStamp(resp?.review?.end || resp?.start),
            },
            approval: {
              ...resp?.approval,
              start: convertDateTimeStamp(resp?.approval?.start || resp?.start),
              end: convertDateTimeStamp(resp?.approval?.end || resp?.end),
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log("error >>>>> ", err);
        });
      if (systemId) {
        await getSystemDocs(systemId)
          .then((resp) => {
            setDocumentList(
              resp?.filter((doc) => doc.deliverable === deliverableId)
            );
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log("error >>>>> ", err);
          });

        await getSystemDeviations(systemId)
          .then((resp) => {
            setDeviationList(getAssociatedDeviation(deliverableId, resp));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log("error >>>>> ", err);
          });
      }
    }
    fetchDeliverable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, systemId]);

  useEffect(() => {
    getDeliverableUsers();
    // eslint-disable-next-line
  }, [deliverable]);

  const metaSystem = useSelector(
    (state) =>
      state.projects.metaSystems.find(
        (s) =>
          (systemId !== "_" && s._id === systemId) ||
          s.mainSystem._id === mainSystemId
      ) || {}
  );

  const project = useSelector((state) =>
    state.projects.results.find((p) => p._id === projectId)
  );

  const NAV_LINKS = [
    LINKS.PROJECT_MANAGEMENT,
    LINKS.PROJECTS,
    {
      TITLE: project?.name,
      HREF: LINKS.EDIT_PROJECT.HREF.replace(":id", metaSystem?.project),
    },
    {
      TITLE: metaSystem?.name,
      HREF: LINKS.EDIT_META_SYSTEM.HREF.replace(
        ":projectId",
        metaSystem.project
      )
        .replace(":systemId", systemId)
        .replace(":mainSystemId", mainSystemId),
    },
  ];

  const getDepartments = () => {
    let deptList = [];
    departments.forEach((dept) => {
      const obj = {
        label: dept.label,
        value: dept._id,
      };
      deptList.push(obj);
    });
    return deptList;
  };

  const getUsers = () => {
    let userList = [];
    usersList.forEach((user) => {
      const obj = {
        label: user.name,
        value: user._id,
        department: user.department,
      };
      userList.push(obj);
    });
    return userList;
  };

  const getType = () => {
    return [
      { value: "DOCUMENTATION", label: "Documentation" },
      { value: "EXECUTION", label: "Execution" },
    ];
  };
  const getDefaultType = (type) => {
    return { value: type, label: type?.toUpperCase() };
  };

  const getDefaultDept = (deliverable) => {
    const dept = {
      value: deliverable.department,
      label: getNamebyId("approver", deliverable.department),
    };
    return dept;
  };

  const getDefaultResources = (deliverable) => {
    const userList = [];
    deliverable?.resource?.forEach((usr) => {
      const obj = { label: getNamebyId("users", usr), value: usr };
      userList.push(obj);
    });
    return userList;
  };

  const getDefaultResourcesNote = (note) => {
    const userList = [];
    note?.resource?.forEach((usr) => {
      const obj = { label: getNamebyId("users", usr), value: usr };
      userList.push(obj);
    });
    return userList;
  };

  const getDefaultApprover = (deliverable, key) => {
    const userList = [];
    deliverable[key]?.forEach(async (data) => {
      data?.users?.forEach(async (user) => {
        const obj = {
          label: getNamebyId("users", user.userid),
          value: user.userid,
          approved: user.approved,
          rejected: user.rejected,
          department: data.department,
        };
        userList.push(obj);
      });
    });
    return userList;
  };

  const getNamebyId = (key, uid) => {
    if (key === "users") {
      // console.log("users list >>>>>>>>. ", users)
      return usersList.find((user) => user._id === uid)?.name;
    }
    if (key === "approver" || key === "reviewer") {
      return departments.find((dept) => dept._id === uid)?.label;
    }
  };
  const getDepartmentUsers = () => {
    let groupedData = [];
    departments.forEach((dept) => {
      const obj = {
        label: dept.label,
        options: [],
      };
      usersList.forEach((usr) => {
        if (usr.department === dept._id) {
          const userData = {
            label: usr.name,
            value: usr._id,
            department: usr.department,
          };
          obj.options.push(userData);
        }
      });
      groupedData.push(obj);
    });
    return groupedData;
  };

  const handleDataChange = async ({
    target: { type, value, name, value1 = "" },
  }) => {
    if (type === "date" && value !== null) {
      value = moment(value).format("YYYY-MM-DDT00:00:00.000Z");
      value1 = moment(value1).format("YYYY-MM-DDT00:00:00.000Z");
      if (name === "noteDate") {
        setEditNote({
          ...editNote,
          date: value,
        });
      }
    }
    const updatedData = {
      ...deliverable,
      draft: deliverable.draft ? deliverable.draft : {},
    };
    if (name === "startEnd") {
      updatedData.start = value;
      updatedData.draft.start = value;
      updatedData.end = value1;
      updatedData.approval.end = value1;
      if (
        moment(value1).diff(moment(value), "days") !== updatedData.duration &&
        project?.autoSchedulerEnabled &&
        updatedData.type !== "EXECUTION"
      ) {
        setOpenDuration(true);
        setDurationData(updatedData);
      } else {
        if (project.autoSchedulerEnabled) {
          setScheduledDeliverable(updatedData._id);
          updatedData.draft.start = updatedData.start;
          updatedData.draft.end = getHolidayAdjustedEndDate(
            updatedData.draft.start,
            deliverable.draftDuration || 0,
            organizationData?.organizationHoliday
          );
          updatedData.ifr.start = updatedData.draft.end;
          updatedData.ifr.end = getHolidayAdjustedEndDate(
            updatedData.ifr.start,
            deliverable.ifrDuration || 0,
            organizationData?.organizationHoliday
          );
          updatedData.review.start = updatedData.ifr.end;
          updatedData.review.end = getHolidayAdjustedEndDate(
            updatedData.review.start,
            deliverable.reviewDuration || 0,
            organizationData?.organizationHoliday
          );
          updatedData.ifa.start = updatedData.review.end;
          updatedData.ifa.end = getHolidayAdjustedEndDate(
            updatedData.ifa.start,
            deliverable.ifaDuration || 0,
            organizationData?.organizationHoliday
          );
          updatedData.approval.start = updatedData.ifa.end;
          updatedData.approval.end = getHolidayAdjustedEndDate(
            updatedData.approval.start,
            deliverable.approvalDuration || 0,
            organizationData?.organizationHoliday
          );
          updatedData.masterDate.start = updatedData.approval.end;
          updatedData.masterDate.end = getHolidayAdjustedEndDate(
            updatedData.masterDate.start,
            deliverable.masterDuration || 0,
            organization?.organizationHoliday
          );
        }
      }
    }
    if (name === "completion") {
      updatedData.completion = value;
    }
    if (name === "department") {
      updatedData.department = value;
      updatedData.resource = [];
      updatedData.defaultResource = [];
    }
    if (name === "plannedHours") {
      updatedData.plannedHours = value;
    }
    if (name === "workedHours") {
      updatedData.workedHours = value;
    }
    if (name === "status") {
      if (value >= 0 && value <= 100) updatedData.status = value;
    }
    if (name === "activity") {
      updatedData.activity = value;
    }
    if (name === "deliverableType") {
      updatedData.type = value;
      if (value !== "EXECUTION") {
        updatedData.executed = null;
      }
    }
    if (name === "executed") {
      if (value) {
        const selected = value;
        updatedData.defaultExecuted = {
          ...updatedData.defaultExecuted,
          value: selected.value,
          label: selected.label,
        };
        updatedData.executed = {
          userid: updatedData.defaultExecuted.value,
          approved: updatedData.defaultExecuted.approved,
        };
      } else {
        updatedData.defaultExecuted = {
          value: "",
          label: "",
          approved: false,
        };
        updatedData.executed = null;
      }
    }
    if (name === "resource") {
      updatedData.resource = [];
      value.forEach((val) => updatedData.resource.push(val.value));
    }
    if (name === "approver") {
      updatedData.approver = [];
      departments.forEach((dept) => {
        const usersList = value.filter((val) => val.department === dept._id);
        if (usersList.length > 0) {
          const data = {
            department: dept._id,
            users: [],
          };
          usersList.forEach((user) => {
            const userData = {
              userid: user.value,
              approved: user.approved,
              rejected: user.rejected,
            };
            data.users.push(userData);
          });
          updatedData.approver.push(data);
        }
      });
    }
    if (name === "reviewer") {
      updatedData.reviewer = [];
      updatedData.defaultReviewer = value;
      departments.forEach((dept) => {
        const usersList = value.filter((val) => val.department === dept._id);
        if (usersList.length > 0) {
          const data = {
            department: dept._id,
            users: [],
          };
          usersList.forEach((user) => {
            const userData = {
              userid: user.value,
              approved: user.approved,
            };
            data.users.push(userData);
          });
          updatedData.reviewer.push(data);
        }
      });
    }

    if (name === "draftEnd") {
      updatedData.draft.end = value;
    }
    if (name === "ifrStart") {
      updatedData.ifr.start = value;
    }
    if (name === "ifrEnd") {
      updatedData.ifr.end = value;
      if (project.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.review.start = updatedData.ifr.end;
        updatedData.review.end = getHolidayAdjustedEndDate(
          updatedData.review.start,
          deliverable.reviewDuration || 0,
          organizationData?.organizationHoliday
        );
        updatedData.ifa.start = updatedData.review.end;
        updatedData.ifa.end = getHolidayAdjustedEndDate(
          updatedData.ifa.start,
          deliverable.ifaDuration || 0,
          organizationData?.organizationHoliday
        );
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          deliverable.approvalDuration || 0,
          organizationData?.organizationHoliday
        );
      }
    }
    if (name === "reviewStart") {
      updatedData.review.start = value;
    }
    if (name === "reviewEnd") {
      updatedData.review.end = value;
      if (project.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.ifa.start = updatedData.review.end;
        updatedData.ifa.end = getHolidayAdjustedEndDate(
          updatedData.ifa.start,
          deliverable.ifaDuration || 0,
          organizationData?.organizationHoliday
        );
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          deliverable.approvalDuration || 0,
          organizationData?.organizationHoliday
        );
      }
    }
    if (name === "ifaStart") {
      updatedData.ifa.start = value;
    }
    if (name === "ifaEnd") {
      updatedData.ifa.end = value;
      if (project.autoSchedulerEnabled) {
        setScheduledDeliverable(updatedData._id);
        updatedData.approval.start = updatedData.ifa.end;
        updatedData.approval.end = getHolidayAdjustedEndDate(
          updatedData.approval.start,
          deliverable.approvalDuration || 0,
          organizationData?.organizationHoliday
        );
      }
    }
    if (name === "approvalStart") {
      updatedData.approval.start = value;
    }
    if (name === "approvalEnd") {
      updatedData.approval.end = value;
    }
    if (name === "masterDateStart") {
      updatedData.masterDate.start = value;
    }
    if (name === "masterDateEnd") {
      updatedData.masterDate.end = value;
    }
    if (name === "ifrIssued") {
      updatedData.ifr.issued = value;
    }
    if (name === "ifaIssued") {
      updatedData.ifa.issued = value;
    }
    if (name === "draftCompletion") {
      updatedData.draft.completion = value;
    }
    if (name === "ifrCompletion") {
      updatedData.ifr.completion = value;
    }
    if (name === "reviewCompletion") {
      updatedData.review.completion = value;
    }
    if (name === "ifaCompletion") {
      updatedData.ifa.completion = value;
    }
    if (name === "approvalCompletion") {
      updatedData.approval.completion = value;
    }
    if (name === "masterDateCompletion") {
      updatedData.masterDate.completion = value;
    }
    if (name === "draftStatus") {
      if (value >= 0 && value <= 100) updatedData.draft.status = value;
    }
    if (name === "reviewStatus") {
      if (value >= 0 && value <= 100) updatedData.review.status = value;
    }
    if (name === "approvalStatus") {
      if (value >= 0 && value <= 100) updatedData.approval.status = value;
    }
    if (name === "type") {
      setEditNote({ ...editNote, type: value });
    }
    if (name === "noteResource") {
      setEditNote({
        ...editNote,
        resource: value.map((val) => val.value),
      });
    }
    if (name === "description") {
      setEditNote({
        ...editNote,
        description: value,
      });
    }
    setDeliverable(updatedData);
  };

  const handleNoteStatus = async (index) => {
    var data = deliverable.notes[index];
    data.status = !data.status;
    handleNoteUpdate(data);
  };
  const { DOC_STATUS } = DocumentHelper();

  const handleSave = async (data = null) => {
    setError(true);
    const formValidated = !(
      checkValidation("draftEnd") ||
      checkValidation("ifrStart") ||
      checkValidation("ifrEnd") ||
      checkValidation("reviewStart") ||
      checkValidation("reviewEnd") ||
      checkValidation("ifaStart") ||
      checkValidation("ifaEnd") ||
      checkValidation("approvalStart") ||
      checkValidation("approvalEnd") ||
      checkValidation("ifrIssued") ||
      checkValidation("ifaIssued") ||
      checkValidation("reviewStatus") ||
      checkValidation("approvalStatus")
    );

    let isTimeEntryError = false;
    deliverableUserList
      ?.filter(
        (f) => f.plannedMinutesText?.trim() || f.workedMinutesText?.trim()
      )
      ?.forEach((m) => {
        let tempError = false;
        if (m.workedMinutesText) {
          tempError = !checkHourMinuteRegex(m.workedMinutesText);
        }
        if (m.plannedMinutesText) {
          tempError = !checkHourMinuteRegex(m.plannedMinutesText);
        }
        if (tempError) {
          isTimeEntryError = tempError;
        }
      });

    if (
      (formValidated || deliverable.type === "EXECUTION") &&
      !isTimeEntryError
    ) {
      setLoading(true);
      const timeData = deliverableUserList
        ?.filter((f) => f.plannedMinutes || f.workedMinutes)
        ?.map((m) => {
          return {
            userId: m.id,
            ...(m.plannedMinutes ? { plannedMinutes: m.plannedMinutes } : {}),
            ...(m.workedMinutes
              ? { workedMinutes: m.workedMinutes, date: m.date }
              : {}),
          };
        });
      await updateDeliverable({
        ...restrict(data ? data : { ...deliverable, workTimeData: timeData }, [
          "_id",
          "plannedHours",
          "workedHours",
          "start",
          "end",
          "completion",
          "status",
          "department",
          "activity",
          "resource",
          "approver",
          "reviewer",
          "executed",
          "type",
          "draft",
          "ifa",
          "ifr",
          "review",
          "approval",
          "masterFile",
          "masterDate",
          "duration",
          "draftDuration",
          "ifrDuration",
          "reviewDuration",
          "ifaDuration",
          "approvalDuration",
          "masterDuration",
          "workTimeData",
        ]),
        mainId: mainSystemId,
        scheduled: deliverable._id === scheduledDeliverable ? true : false,
      })
        .then((response) => {
          const resp = response.data.deliverables.find(
            (deliverable) => deliverable._id === deliverableId
          );
          setDeliverable({
            ...resp,
            start: convertDateTimeStamp(resp?.start),
            end: convertDateTimeStamp(resp?.end),
            draft: {
              ...resp?.draft,
              start: convertDateTimeStamp(resp?.draft?.start || resp?.start),
              end: convertDateTimeStamp(resp?.draft?.end || resp?.start),
            },
            ifa: {
              ...resp?.ifa,
              start: convertDateTimeStamp(resp?.ifa?.start || resp?.start),
              end: convertDateTimeStamp(resp?.ifa?.end || resp?.end),
            },
            ifr: {
              ...resp?.ifr,
              start: convertDateTimeStamp(resp?.ifr?.start || resp?.start),
              end: convertDateTimeStamp(resp?.ifr?.end || resp?.start),
            },
            review: {
              ...resp?.review,
              start: convertDateTimeStamp(resp?.review?.start || resp?.start),
              end: convertDateTimeStamp(resp?.review?.end || resp?.start),
            },
            approval: {
              ...resp?.approval,
              start: convertDateTimeStamp(resp?.approval?.start || resp?.start),
              end: convertDateTimeStamp(resp?.approval?.end || resp?.end),
            },
          });
          dispatch(updateDeliverables(response.data));
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          dispatch(
            setPopup({
              popupType: POPUP_TYPE.error,
              popupText: err?.response?.data?.info,
            })
          );
          setLoading(false);
        });
    }
    setScheduledDeliverable(null);
  };

  const getMinimumDate = (varaint) => {
    if (project?.autoSchedulerEnabled) {
      switch (varaint) {
        case "ifrStart":
        case "ifrEnd":
          return (
            new Date(moment(deliverable?.draft?.end?.split("T")[0])) || null
          );
        case "reviewStart":
        case "reviewEnd":
          return new Date(moment(deliverable?.ifr?.end?.split("T")[0])) || null;
        case "ifaStart":
        case "ifaEnd":
          return (
            new Date(moment(deliverable?.review?.end?.split("T")[0])) || null
          );

        case "approvalStart":
          return new Date(moment(deliverable?.ifa?.end?.split("T")[0])) || null;
        default:
          return new Date(moment(deliverable?.start?.split("T")[0])) || null;
      }
    } else {
      return new Date(moment(deliverable?.start?.split("T")[0])) || null;
    }
  };

  const handleNoteUpdate = async (data) => {
    setLoading(true);
    const resp = await updateDeliverableNote({
      noteId: data._id,
      ...data,
      mainId: mainSystemId,
      _id: deliverable._id,
    });
    let deliverableCopy = { ...deliverable };
    deliverableCopy.notes = resp.data;
    setDeliverable(deliverableCopy);
    setNotesDetailModal(false);
    setEditNote({});
    setLoading(false);
  };

  const handleNoteCreate = async (note) => {
    note.date = moment(note.date).format("YYYY-MM-DD[T00:00:00.000Z]");
    setLoading(true);
    const resp = await createDeliverableNote({
      ...note,
      mainId: mainSystemId,
      _id: deliverable._id,
    });
    let deliverableCopy = { ...deliverable };
    deliverableCopy.notes = resp.data;
    setDeliverable(deliverableCopy);
    setLoading(false);
  };

  const handleNoteDelete = async (noteId) => {
    setLoading(true);
    const resp = await deleteDeliverableNote({
      noteId,
      mainId: mainSystemId,
      _id: deliverable._id,
    });
    let deliverableCopy = { ...deliverable };
    deliverableCopy.notes = resp.data;
    setDeliverable(deliverableCopy);
    setNotesDetailModal(false);
    setEditNote({});
    setLoading(false);
  };

  const onDelete = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Are you sure you want to delete this note?",
        onConfirm: async () => {
          await handleNoteDelete(editNote._id);
        },
      })
    );
  };

  const handleApprove = (key, index, userIndex) => {
    if (
      key === "reviewer" &&
      !deliverable?.ifr?.issued &&
      !deliverable[key][index].users[userIndex].approved
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: `${deliverable.name} not Issued for Review`,
        })
      );
      return;
    }
    if (
      key === "approver" &&
      !deliverable?.ifa?.issued &&
      (!deliverable[key][index].users[userIndex].approved ||
        !deliverable[key][index].users[userIndex].rejected)
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: `${deliverable.name} not Issued for Approval`,
        })
      );
      return;
    }
    let data = _.cloneDeep(deliverable);
    if (
      data[key][index]?.users[userIndex]?.approved ||
      data[key][index]?.users[userIndex]?.rejected
    ) {
      data[key][index].users[userIndex].approved = false;
      data[key][index].users[userIndex].rejected = false;
    } else if (!data[key][index]?.users[userIndex]?.approved) {
      data[key][index].users[userIndex].approved = true;
      data[key][index].users[userIndex].rejected = false;
    }
    handleSave(data);
  };

  const handleReject = (key = "approver", index, userIndex) => {
    if (
      key === "approver" &&
      !deliverable?.ifa?.issued &&
      !deliverable[key][index].users[userIndex].rejected
    ) {
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText: `${deliverable.name} not Issued for Approval`,
        })
      );
      return;
    }
    var data = _.cloneDeep(deliverable);
    data[key][index].users[userIndex].rejected =
      !data[key][index].users[userIndex].rejected;
    data[key][index].users[userIndex].approved = false;
    handleSave(data);
  };

  const handleExecuted = () => {
    var data = { ...deliverable };
    data.executed.approved = !data.executed.approved;
    data.status = 100;
    setDeliverable(data);
    handleSave();
  };

  const checkValidation = (type) => {
    const checkDate = (date) => {
      return moment(date?.split("T")[0])?.isBetween(
        moment(deliverable?.start?.split("T")[0]),
        moment(deliverable?.end?.split("T")[0]),
        undefined,
        "[]"
      );
    };
    if (
      (!checkDate(deliverable?.draft?.end) || !deliverable?.draft?.end) &&
      type === "draftEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifr?.start) || !deliverable?.ifr?.start) &&
      type === "ifrStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifr?.end) || !deliverable?.ifr?.end) &&
      type === "ifrEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.review?.start) || !deliverable?.review?.start) &&
      type === "reviewStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.review?.end) || !deliverable?.review?.end) &&
      type === "reviewEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifa?.start) || !deliverable?.ifa?.start) &&
      type === "ifaStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifa?.end) || !deliverable?.ifa?.end) &&
      type === "ifaEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.approval?.start) ||
        !deliverable?.approval?.start) &&
      type === "approvalStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.approval?.end) || !deliverable?.approval?.end) &&
      type === "approvalEnd"
    ) {
      return true;
    }
    if (
      type === "ifrIssued" &&
      deliverable?.ifr?.issued &&
      Math.floor(deliverable?.draft?.status || 0) < 100
    ) {
      return true;
    }
    if (
      type === "ifaIssued" &&
      deliverable?.ifa?.issued &&
      Math.floor(deliverable?.review?.status || 0) < 100
    ) {
      return true;
    }
    if (
      type === "reviewStatus" &&
      Math.round(deliverable?.review?.status) > 0 &&
      !deliverable.ifr.issued &&
      (documentList?.length || 0) === 0
    ) {
      return true;
    }
    if (
      type === "approvalStatus" &&
      Math.round(deliverable?.approval?.status) > 0 &&
      !deliverable.ifa.issued &&
      (documentList?.length || 0) === 0
    ) {
      return true;
    }
    return false;
  };

  if (deliverable.draft === undefined) {
    deliverable["draft"] = { start: "", end: "" };
  }
  if (deliverable.ifr === undefined) {
    deliverable["ifr"] = { start: "", end: "" };
  }
  if (deliverable.ifa === undefined) {
    deliverable["ifa"] = { start: "", end: "" };
  }
  if (deliverable.review === undefined) {
    deliverable["review"] = { start: "", end: "" };
  }
  if (deliverable.approval === undefined) {
    deliverable["approval"] = { start: "", end: "" };
  }

  if (!deliverable?.draft?.start) {
    deliverable["draft"]["start"] = deliverable?.start || "";
  }
  if (!deliverable?.approval?.end) {
    deliverable["ifa"]["end"] = deliverable?.end || "";
  }
  const openCalendarPopup = (event, type, variant) => {
    setDateRanges([
      {
        startDate:
          type === "deliverable"
            ? new Date(moment(deliverable.start.split("T")[0]))
            : new Date(moment(deliverable[type].start.split("T")[0])),
        endDate:
          type === "deliverable"
            ? new Date(moment(deliverable.end.split("T")[0]))
            : new Date(moment(deliverable[type].end.split("T")[0])),
        key: "selection",
      },
    ]);
    let range = { ...anchorElDates };
    range[variant] = event.currentTarget;
    setAnchorElDates(range);
  };

  const dateRangeSelector = (type, variant) => {
    return (
      <div>
        <TextField
          error={checkValidation(variant)}
          InputProps={{
            readOnly:
              [PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                permissions
              ) ||
              variant === "draftStart" ||
              variant === "approvalEnd",
          }}
          required
          label={variant.includes("Start") ? "Start date" : "End date"}
          aria-describedby={
            Boolean(anchorElDates[variant]) ? "simple-popover" : undefined
          }
          onClick={(event) => {
            if (
              ![PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                permissions
              )
            ) {
              openCalendarPopup(event, type, variant);
            }
          }}
          value={
            type === "deliverable"
              ? moment(
                  deliverable?.[
                    variant.includes("Start") ? "start" : "end"
                  ]?.split("T")[0]
                ).format("MM/DD/YY")
              : moment(
                  deliverable[type]?.[
                    variant.includes("Start") ? "start" : "end"
                  ]?.split("T")[0]
                ).format("MM/DD/YY")
          }
        ></TextField>
        {type !== "deliverable" &&
          checkValidation(variant) &&
          dateRangeError(type, variant, deliverable)}
        {variant !== "draftStart" && variant !== "approvalEnd" && (
          <Popover
            id={Boolean(anchorElDates[variant]) ? "simple-popover" : undefined}
            open={Boolean(anchorElDates[variant])}
            anchorEl={anchorElDates[variant]}
            onClose={() => {
              setAnchorElDates({
                ...anchorElDates,
                [variant]: null,
              });
              setExpectedDateRange({
                startDate: null,
                endDate: null,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              {type !== "deliverable" && (
                <DateRange
                  editableDateInputs={true}
                  disabledDates={organizationData?.organizationHoliday?.map(
                    (holiday) =>
                      new Date(
                        moment(holiday).format("YYYY-MM-DDT00:00:00.000Z")
                      )
                  )}
                  maxDate={
                    new Date(moment(deliverable?.end?.split("T")[0])) || null
                  }
                  minDate={getMinimumDate(variant)}
                  onChange={({ selection }) => {
                    const adjustedEndDate = new Date(
                      getHolidayAdjustedEndDate(
                        type === "draft"
                          ? deliverable.draft.start.split("T")[0]
                          : selection.startDate,
                        deliverable[type + "Duration"],
                        organizationData?.organizationHoliday
                      )
                    );
                    setDateRanges([
                      {
                        startDate:
                          type === "draft"
                            ? new Date(
                                moment(deliverable.draft.start.split("T")[0])
                              )
                            : new Date(moment(selection.startDate)),
                        endDate: project.autoSchedulerEnabled
                          ? adjustedEndDate
                          : type === "approval"
                          ? new Date(
                              moment(deliverable.approval.end.split("T")[0])
                            )
                          : new Date(moment(selection.endDate)),
                        key: "selection",
                      },
                    ]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRanges}
                />
              )}
              {type === "deliverable" && (
                <DateRange
                  editableDateInputs={true}
                  disabledDates={organizationData?.organizationHoliday?.map(
                    (holiday) =>
                      new Date(
                        moment(holiday).format("YYYY-MM-DDT00:00:00.000Z")
                      )
                  )}
                  dayContentRenderer={(day) =>
                    customDayContent(day, expectedDateRange)
                  }
                  onChange={({ selection }) => {
                    const adjustedEndDate = new Date(
                      getHolidayAdjustedEndDate(
                        selection.startDate,
                        deliverable.duration,
                        organizationData?.organizationHoliday
                      )
                    );
                    setDateRanges([
                      {
                        startDate: new Date(moment(selection.startDate)),
                        endDate: new Date(moment(selection.endDate)),
                        key: "selection",
                      },
                    ]);
                    setExpectedDateRange({
                      startDate: new Date(moment(selection.startDate)),
                      endDate: new Date(moment(adjustedEndDate)),
                    });
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRanges}
                />
              )}
            </div>
            <div className="d-jsb-r mb-5">
              <Button
                variant="contained"
                onClick={() =>
                  setAnchorElDates({
                    ...anchorElDates,
                    [variant]: null,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="ml-5 mr-5"
                onClick={() => {
                  if (type !== "deliverable") {
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].startDate,
                        name: type + "Start",
                      },
                    });
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].endDate,
                        name: type + "End",
                      },
                    });
                  } else {
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].startDate,
                        value1: dateRanges[0].endDate,
                        name: "startEnd",
                      },
                    });
                  }

                  setAnchorElDates({
                    ...anchorElDates,
                    [variant]: null,
                  });
                }}
              >
                Save
              </Button>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const timelineType =
    deliverable?.type !== undefined &&
    deliverable?.type !== "" &&
    deliverable?.type !== "EXECUTION";

  const handleMaster = ({ target }) => {
    if (target.files[0]) {
      uploadMasterFile(target.files[0]);
    }
  };

  const uploadMasterFile = async (file) => {
    setMasterLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("module", "deliverable");
    formData.append("projectId", projectId);
    await uploadSingleFile(formData)
      .then((resp) => {
        const del = deliverable;
        deliverable.masterFile = resp.data;
        setDeliverable(del);
        setMasterLoading(false);
      })
      .catch((err) => {
        setMasterLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>> ", err);
      });
  };

  const handleDurationClose = () => {
    setOpenDuration(false);
  };

  const closeCalendarPopup = () => {
    setExpectedDateRange({
      startDate: null,
      endDate: null,
    });
  };

  const saveDates = (data) => {
    closeCalendarPopup();
    handleDurationClose();
    let updatedData = {
      _id: data._id,
      start: data.start,
      end: data.end,
      duration: data.duration,
      draft: {
        start: data.draft.start,
        end: data.draft.end,
      },
      ifr: {
        start: data.ifr.start,
        end: data.ifr.end,
      },
      review: {
        start: data.review.start,
        end: data.review.end,
      },
      ifa: {
        start: data.ifa.start,
        end: data.ifa.end,
      },
      approval: {
        start: data.approval.start,
        end: data.approval.end,
      },
      draftDuration: data.draftDuration,
      ifrDuration: data.ifrDuration,
      reviewDuration: data.reviewDuration,
      ifaDuration: data.ifaDuration,
      approvalDuration: data.approvalDuration,
    };
    if (data.master) {
      updatedData = {
        ...updatedData,
        masterDuration: data.masterDuration,
        masterDate: {
          start: data.masterDate.start,
          end: data.masterDate.end,
        },
      };
    }
    updatedData.mainId = mainSystemId;
    updatedData.scheduled = true;
    updateDeliverable(updatedData)
      .then((response) => {
        const resp = response.data.deliverables.find(
          (deliverable) => deliverable._id === deliverableId
        );
        setDeliverable({
          ...resp,
          start: convertDateTimeStamp(resp?.start),
          end: convertDateTimeStamp(resp?.end),
          draft: {
            ...resp?.draft,
            start: convertDateTimeStamp(resp?.draft?.start || resp?.start),
            end: convertDateTimeStamp(resp?.draft?.end || resp?.start),
          },
          ifa: {
            ...resp?.ifa,
            start: convertDateTimeStamp(resp?.ifa?.start || resp?.start),
            end: convertDateTimeStamp(resp?.ifa?.end || resp?.end),
          },
          ifr: {
            ...resp?.ifr,
            start: convertDateTimeStamp(resp?.ifr?.start || resp?.start),
            end: convertDateTimeStamp(resp?.ifr?.end || resp?.start),
          },
          review: {
            ...resp?.review,
            start: convertDateTimeStamp(resp?.review?.start || resp?.start),
            end: convertDateTimeStamp(resp?.review?.end || resp?.start),
          },
          approval: {
            ...resp?.approval,
            start: convertDateTimeStamp(resp?.approval?.start || resp?.start),
            end: convertDateTimeStamp(resp?.approval?.end || resp?.end),
          },
          masterDate: {
            ...resp?.masterDate,
            start: convertDateTimeStamp(resp?.masterDate?.start || resp?.start),
            end: convertDateTimeStamp(resp?.masterDate?.end || resp?.end),
          },
        });
        dispatch(updateDeliverables(response.data));
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: err?.response?.data?.info,
          })
        );
        setLoading(false);
      });
  };

  const getDeliverableUsers = () => {
    if (!_.isEmpty(deliverable)) {
      setDeliverableUserList(
        deliverable?.workedMinutes?.map((m) => ({
          id: m.user,
          name: users.find((user) => user._id === m.user)?.name,
          plannedMinutes: 0,
          plannedMinutesText: "",
          workedMinutes: 0,
          workedMinutesText: "",
          date: moment().format("YYYY-MM-DD[T00:00:00.000Z]"),
          totalWorkedMinutes: m.totalWorkedMinutes,
          totalWorkedMinutesText: `${parseInt(m.totalWorkedMinutes / 60)}h ${
            m.totalWorkedMinutes % 60
          }m`,
        }))
      );
    }
  };

  const handleUserHourChange = ({ name, value, index }) => {
    const userData = [...deliverableUserList];
    if (
      name.split(" ")[0] === "plannedMinutesText" ||
      name.split(" ")[0] === "workedMinutesText"
    ) {
      userData[index][name.split(" ")[0]] = value;
      let totalMinutes = 0;
      let newVal = value?.trim().match(HOUR_MINUTE_REGEX)
        ? value?.trim()
        : value?.trim()?.match(HOUR_REGEX)
        ? `${value?.trim()} 0m`
        : value?.trim()?.match(MINUTE_REGEX)
        ? `0h ${value?.trim()}`
        : null;
      if (newVal) {
        const match = newVal.match(HOUR_MINUTE_REGEX);
        if (match) {
          const hours = parseInt(match[1]);
          const minutes = match[2] ? parseInt(match[2]) : 0;
          totalMinutes += hours * 60 + minutes;
        }
        userData[index][name.split(" ")[1]] = totalMinutes;
      } else {
        userData[index][name.split(" ")[1]] = 0;
      }
    } else {
      userData[index][name] = value;
    }

    setDeliverableUserList(userData);
  };

  // const handleSaveUserTimeData = async () => {
  //   let isError = false;
  //   deliverableUserList
  //     ?.filter(
  //       (f) => f.plannedMinutesText?.trim() || f.workedMinutesText?.trim()
  //     )
  //     ?.forEach((m) => {
  //       let tempError = false;
  //       if (m.workedMinutesText) {
  //         tempError = !checkHourMinuteRegex(m.workedMinutesText);
  //       }
  //       if (m.plannedMinutesText) {
  //         tempError = !checkHourMinuteRegex(m.plannedMinutesText);
  //       }
  //       if (tempError) {
  //         isError = tempError;
  //         // setSavingError(tempError);
  //       }
  //     });
  //   if (!isError) {
  //     const data = deliverableUserList
  //       ?.filter((f) => f.plannedMinutes || f.workedMinutes)
  //       ?.map((m) => {
  //         return {
  //           userId: m.id,
  //           ...(m.plannedMinutes ? { plannedMinutes: m.plannedMinutes } : {}),
  //           ...(m.workedMinutes
  //             ? { workedMinutes: m.workedMinutes, date: m.date }
  //             : {}),
  //         };
  //       });
  //     // console.log("$ data = ", data);
  //     if (data?.length) {
  //       setLoading(true);
  //       await postWorkedHours(projectId, mainSystemId, deliverable?._id, data)
  //         .then(async (resp) => {
  //           await dispatch(updateDeliverables(resp?.data));
  //           setLoading(false);
  //         })
  //         ?.catch((e) => {
  //           console.log("some error occured in handleSaveUserTimeData ", e);
  //           dispatch(
  //             setPopup({
  //               popupType: POPUP_TYPE.error,
  //               popupText: e?.response?.data?.info
  //                 ? e?.response?.data?.info
  //                 : "Some error occured",
  //             })
  //           );
  //           setLoading(false);
  //         });
  //     }
  //   }
  // };

  const handleOpenCalendar = (index) => {
    setDatePickerIndex(index);
  };

  const handleRemoveMasterFile = () => {
    const del = { ...deliverable };
    del.masterFile = null;
    setDeliverable(del);
  };

  return (
    <div>
      <PageHeader
        title={deliverable?.name || ""}
        links={NAV_LINKS}
        milestone={deliverable?.milestone}
        completion={deliverable?.completion}
        end={deliverable?.end}
        status={deliverable?.status}
        type={"timeline"}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <form noValidate className="pd-10">
              <div className="max-ht-620 ">
                <Grid container columns={12} justify="space-between">
                  <div className="wd-31">
                    <InputLabel id="demo-multiple-chip-label">
                      Department
                    </InputLabel>
                    <FormControl fullWidth className="mb-10 mt-5">
                      <Select
                        isDisabled={excluded}
                        value={getDefaultDept(deliverable)}
                        options={getDepartments()}
                        onChange={(selected) =>
                          handleDataChange({
                            target: {
                              value: selected.value,
                              name: "department",
                            },
                          })
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="wd-31 ">
                    <InputLabel id="demo-multiple-chip-label">
                      Resource
                    </InputLabel>
                    <FormControl fullWidth className="mb-10 mt-5">
                      <Select
                        isClearable={false}
                        isDisabled={excluded}
                        isMulti
                        options={getUsers()}
                        value={getDefaultResources(deliverable)}
                        closeMenuOnSelect={false}
                        onChange={(selected) =>
                          handleDataChange({
                            target: { value: selected, name: "resource" },
                          })
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="wd-31">
                    <InputLabel id="demo-multiple-chip-label">Type</InputLabel>
                    <FormControl fullWidth className="mb-10 mt-5">
                      <Select
                        isDisabled={excluded}
                        value={getDefaultType(deliverable?.type)}
                        options={getType()}
                        onChange={(selected) =>
                          handleDataChange({
                            target: {
                              value: selected.value,
                              name: "deliverableType",
                            },
                          })
                        }
                      />
                    </FormControl>
                  </div>
                  {deliverable?.type !== "EXECUTION" && (
                    <>
                      <div className="wd-31">
                        <InputLabel id="demo-multiple-chip-label">
                          Approver
                        </InputLabel>
                        <FormControl fullWidth className="mb-10 mt-5">
                          <Select
                            isDisabled={documentList?.length !== 0 || excluded}
                            isClearable={false}
                            value={getDefaultApprover(deliverable, "approver")}
                            options={getDepartmentUsers()}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                              handleDataChange({
                                target: { value: selected, name: "approver" },
                              });
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="wd-31">
                        <InputLabel id="demo-multiple-chip-label">
                          Reviewer
                        </InputLabel>
                        <FormControl fullWidth className="mb-10 mt-5">
                          <Select
                            isDisabled={documentList?.length !== 0 || excluded}
                            isClearable={false}
                            value={getDefaultApprover(deliverable, "reviewer")}
                            options={getDepartmentUsers()}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                              handleDataChange({
                                target: { value: selected, name: "reviewer" },
                              });
                            }}
                          />
                        </FormControl>
                      </div>
                    </>
                  )}
                  <div className="wd-31">
                    {deliverable?.type === "EXECUTION" && (
                      <div>
                        {" "}
                        <InputLabel id="demo-multiple-chip-label">
                          Execution
                        </InputLabel>
                        <FormControl fullWidth className="mb-10 mt-5">
                          <Select
                            isDisabled={excluded}
                            isClearable
                            value={{
                              value: deliverable?.executed?.userid,
                              label: getNamebyId(
                                "users",
                                deliverable?.executed?.userid
                              ),
                            }}
                            options={getUsers()}
                            onChange={(selected) =>
                              handleDataChange({
                                target: { value: selected, name: "executed" },
                              })
                            }
                          />
                        </FormControl>{" "}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid
                  container
                  columns={12}
                  spacing={2}
                  justify="space-between"
                  className="mb-10"
                >
                  <Grid item xs={3}>
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("deliverable", "deliverableStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("deliverable", "deliverableEnd")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth className="mb-10">
                      <DatePicker
                        disabled={excluded}
                        label="Completion date"
                        minDate={moment(deliverable?.start?.split("T")[0])}
                        value={
                          (deliverable?.completion &&
                            moment(deliverable?.completion?.split("T")[0])) ||
                          null
                        }
                        format="MM/dd/yy"
                        onChange={(date) =>
                          handleDataChange({
                            target: {
                              type: "date",
                              value: date,
                              name: "completion",
                            },
                          })
                        }
                        InputProps={{
                          endAdornment: deliverable?.completion && (
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: null,
                                    name: "completion",
                                  },
                                });
                              }}
                              className="pd-0"
                            >
                              <Close />
                            </IconButton>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container columns={12} justify="space-between">
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Planned hours"
                      type="number"
                      value={deliverable.plannedHours || 0}
                      name="plannedHours"
                      InputProps={{
                        inputProps: { min: "0", max: "100", step: "1" },
                        disabled: excluded,
                      }}
                      onChange={handleDataChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Worked hours"
                      type="number"
                      value={deliverable.workedHours || 0}
                      name="workedHours"
                      InputProps={{
                        inputProps: { min: "0", max: "100", step: "1" },
                        disabled: excluded,
                      }}
                      onChange={handleDataChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={excluded}
                          checked={deliverable.activity || null}
                          onChange={(event) =>
                            handleDataChange({
                              target: {
                                value: event.target.checked,
                                name: "activity",
                              },
                            })
                          }
                          className="pd-5"
                        />
                      }
                      label={
                        <span style={{ fontSize: "10px", color: "#00000089" }}>
                          Activity
                        </span>
                      }
                      labelPlacement="top"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  columns={12}
                  alignItems="flex-end"
                  spacing={2}
                  className="mb-10"
                >
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type="number"
                      name="status"
                      label="Status"
                      value={_.round(deliverable?.status) || 0}
                      onChange={handleDataChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                        disabled: excluded,
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {" "}
                    <BorderLinearProgress
                      variant="determinate"
                      value={_.round(deliverable?.status)}
                    ></BorderLinearProgress>
                  </Grid>
                </Grid>
              </div>
              {deliverable.type !== "EXECUTION" && (
                <>
                  <Divider className="mb-5" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">Draft</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("draft", "draftStart")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("draft", "draftEnd")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            <DatePicker
                              disabled={excluded}
                              label="Completion date"
                              value={
                                (deliverable?.draft?.completion &&
                                  moment(
                                    deliverable?.draft?.completion.split("T")[0]
                                  )) ||
                                null
                              }
                              minDate={
                                moment(deliverable?.start?.split("T")[0]) ||
                                null
                              }
                              format="MM/dd/yy"
                              onChange={(date) =>
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: date,
                                    name: "draftCompletion",
                                  },
                                })
                              }
                              InputProps={{
                                endAdornment: deliverable?.draft?.completion &&
                                  !excluded && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDataChange({
                                          target: {
                                            type: "date",
                                            value: null,
                                            name: "draftCompletion",
                                          },
                                        });
                                      }}
                                      className="pd-0"
                                    >
                                      <Close />
                                    </IconButton>
                                  ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="number"
                                name="status"
                                label="Status"
                                value={_.round(deliverable?.draft?.status) || 0}
                                onChange={(e) =>
                                  handleDataChange({
                                    target: {
                                      value: e.target.value || "0",
                                      name: "draftStatus",
                                    },
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                  inputProps: { min: 0, max: 100 },
                                  disabled: excluded,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider className="mb-5" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">IFR</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("ifr", "ifrStart")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("ifr", "ifrEnd")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            <DatePicker
                              disabled={documentList?.length !== 0 || excluded}
                              label="Completion date"
                              value={
                                (deliverable?.ifr?.completion &&
                                  moment(
                                    deliverable?.ifr?.completion.split("T")[0]
                                  )) ||
                                null
                              }
                              minDate={
                                moment(deliverable?.start?.split("T")[0]) ||
                                null
                              }
                              format="MM/dd/yy"
                              onChange={(date) =>
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: date,
                                    name: "ifrCompletion",
                                  },
                                })
                              }
                              InputProps={{
                                endAdornment: deliverable?.ifr?.completion &&
                                  documentList?.length === 0 &&
                                  !excluded && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDataChange({
                                          target: {
                                            type: "date",
                                            value: null,
                                            name: "ifrCompletion",
                                          },
                                        });
                                      }}
                                      className="pd-0"
                                    >
                                      <Close />
                                    </IconButton>
                                  ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <Grid container>
                            {" "}
                            <Grid item xs={12}>
                              {" "}
                              <FormControlLabel
                                className="m-0"
                                control={
                                  <Checkbox
                                    disabled={
                                      excluded || documentList?.length !== 0
                                    }
                                    checked={deliverable?.ifr?.issued || false}
                                    onChange={(event) =>
                                      handleDataChange({
                                        target: {
                                          value: event.target.checked,
                                          name: "ifrIssued",
                                        },
                                      })
                                    }
                                    className="pd-5"
                                  />
                                }
                                label={
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "#00000089",
                                    }}
                                  >
                                    Issued
                                  </span>
                                }
                                labelPlacement="top"
                              />
                            </Grid>
                            {error &&
                              checkValidation("ifrIssued") &&
                              errorMessage("ifrIssued")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider className="mb-5" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">Review</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("review", "reviewStart")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("review", "reviewEnd")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            <DatePicker
                              disabled={documentList?.length !== 0 || excluded}
                              label="Completion date"
                              value={
                                (deliverable?.review?.completion &&
                                  moment(
                                    deliverable?.review?.completion.split(
                                      "T"
                                    )[0]
                                  )) ||
                                null
                              }
                              minDate={
                                moment(deliverable?.start?.split("T")[0]) ||
                                null
                              }
                              format="MM/dd/yy"
                              onChange={(date) =>
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: date,
                                    name: "reviewCompletion",
                                  },
                                })
                              }
                              InputProps={{
                                endAdornment: deliverable?.review?.completion &&
                                  documentList?.length === 0 &&
                                  !excluded && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDataChange({
                                          target: {
                                            type: "date",
                                            value: null,
                                            name: "reviewCompletion",
                                          },
                                        });
                                      }}
                                      className="pd-0"
                                    >
                                      <Close />
                                    </IconButton>
                                  ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="number"
                                name="status"
                                label="Status"
                                value={
                                  _.round(deliverable?.review?.status) || 0
                                }
                                onChange={(e) =>
                                  handleDataChange({
                                    target: {
                                      value: e.target.value || "0",
                                      name: "reviewStatus",
                                    },
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                  readOnly: deliverable?.reviewer?.length !== 0,
                                  inputProps: { min: 0, max: 100 },
                                  disabled: excluded,
                                }}
                              />
                              {error &&
                                checkValidation("reviewStatus") &&
                                errorMessage("reviewStatus")}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {deliverable?.reviewer?.length > 0 && (
                        <>
                          <Grid>
                            <div className="mb-5">Reviewers</div>
                            {deliverable?.reviewer?.map((data, reviewerIndex) =>
                              data.users?.map((user, userIndex) => (
                                <Tooltip
                                  title={
                                    getNamebyId("approver", data.department) ||
                                    ""
                                  }
                                  key={user.userid + "Reviewer"}
                                >
                                  <Chip
                                    key={user.userid}
                                    className={
                                      "mr-5 mb-5 border-r-20 chip " +
                                      (user.approved
                                        ? "chip-success"
                                        : "chip-primary")
                                    }
                                    onClick={() => {
                                      if (
                                        documentList?.length === 0 &&
                                        !excluded
                                      ) {
                                        handleApprove(
                                          "reviewer",
                                          reviewerIndex,
                                          userIndex
                                        );
                                      }
                                    }}
                                    label={getNamebyId("users", user.userid)}
                                  />
                                </Tooltip>
                              ))
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Divider className="mb-5" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">IFA</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("ifa", "ifaStart")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("ifa", "ifaEnd")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            <DatePicker
                              disabled={documentList?.length !== 0 || excluded}
                              label="Completion date"
                              value={
                                (deliverable?.ifa?.completion &&
                                  moment(
                                    deliverable?.ifa?.completion.split("T")[0]
                                  )) ||
                                null
                              }
                              minDate={
                                moment(deliverable?.start?.split("T")[0]) ||
                                null
                              }
                              format="MM/dd/yy"
                              onChange={(date) =>
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: date,
                                    name: "ifaCompletion",
                                  },
                                })
                              }
                              InputProps={{
                                endAdornment: deliverable?.ifa?.completion &&
                                  documentList?.length === 0 &&
                                  !excluded && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDataChange({
                                          target: {
                                            type: "date",
                                            value: null,
                                            name: "ifaCompletion",
                                          },
                                        });
                                      }}
                                      className="pd-0"
                                    >
                                      <Close />
                                    </IconButton>
                                  ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                          <Grid container>
                            <Grid item xs={12}>
                              {" "}
                              <FormControlLabel
                                className="m-0"
                                control={
                                  <Checkbox
                                    disabled={
                                      excluded || documentList?.length !== 0
                                    }
                                    checked={deliverable?.ifa?.issued || false}
                                    onChange={(event) =>
                                      handleDataChange({
                                        target: {
                                          value: event.target.checked,
                                          name: "ifaIssued",
                                        },
                                      })
                                    }
                                    className="pd-5"
                                  />
                                }
                                label={
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "#00000089",
                                    }}
                                  >
                                    Issued
                                  </span>
                                }
                                labelPlacement="top"
                              />{" "}
                            </Grid>

                            {error &&
                              checkValidation("ifaIssued") &&
                              errorMessage("ifaIssued")}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className="mb-5" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">Approval</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("approval", "approvalStart")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            {dateRangeSelector("approval", "approvalEnd")}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} className="mr-20">
                          <FormControl fullWidth className="mb-10">
                            <DatePicker
                              disabled={documentList?.length !== 0 || excluded}
                              label="Completion date"
                              value={
                                (deliverable?.approval?.completion &&
                                  moment(
                                    deliverable?.approval?.completion.split(
                                      "T"
                                    )[0]
                                  )) ||
                                null
                              }
                              minDate={
                                moment(deliverable?.start?.split("T")[0]) ||
                                null
                              }
                              format="MM/dd/yy"
                              onChange={(date) =>
                                handleDataChange({
                                  target: {
                                    type: "date",
                                    value: date,
                                    name: "approvalCompletion",
                                  },
                                })
                              }
                              InputProps={{
                                endAdornment: deliverable?.approval
                                  ?.completion &&
                                  documentList?.length === 0 &&
                                  !excluded && (
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDataChange({
                                          target: {
                                            type: "date",
                                            value: null,
                                            name: "approvalCompletion",
                                          },
                                        });
                                      }}
                                      className="pd-0"
                                    >
                                      <Close />
                                    </IconButton>
                                  ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="number"
                                name="status"
                                label="Status"
                                value={
                                  _.round(deliverable?.approval?.status) || 0
                                }
                                onChange={(e) =>
                                  handleDataChange({
                                    target: {
                                      value: e.target.value || "0",

                                      name: "approvalStatus",
                                    },
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                  readOnly: deliverable?.reviewer?.length !== 0,
                                  inputProps: { min: 0, max: 100 },
                                  disabled: excluded,
                                }}
                              />
                              {error &&
                                checkValidation("approvalStatus") &&
                                errorMessage("approvalStatus")}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {deliverable?.approver?.length > 0 && (
                        <>
                          <Grid>
                            <div className="mb-5">Approvers</div>
                            {deliverable?.approver?.map((data, approverIndex) =>
                              data.users?.map((user, userIndex) => (
                                <Tooltip
                                  title={
                                    getNamebyId("approver", data.department) ||
                                    ""
                                  }
                                  key={user.userid + "Approver"}
                                >
                                  <Chip
                                    key={user.userid}
                                    className={
                                      "mr-5 mb-5 border-r-20 chip " +
                                      getChipColor(documentList, user)
                                    }
                                    onClick={() => {
                                      if (
                                        documentList?.length === 0 &&
                                        !excluded
                                      ) {
                                        if (
                                          !deliverable?.ifa?.issued &&
                                          (!deliverable["approver"][
                                            approverIndex
                                          ].users[userIndex].approved ||
                                            !deliverable["approver"][
                                              approverIndex
                                            ].users[userIndex].rejected)
                                        ) {
                                          dispatch(
                                            setPopup({
                                              popupType: POPUP_TYPE.error,
                                              popupText: `${deliverable.name} not Issued for Approval`,
                                            })
                                          );
                                        } else {
                                          dispatch(
                                            setPopup({
                                              popupType: POPUP_TYPE.confirm,
                                              confirmText: "Approve",
                                              cancelText: "Reject",
                                              confirmColor: "green",
                                              cancelColor: "red",
                                              onConfirm: () => {
                                                handleApprove(
                                                  "approver",
                                                  approverIndex,
                                                  userIndex
                                                );
                                              },
                                              onCancel: (type) =>
                                                type !== "close"
                                                  ? handleReject(
                                                      "approver",
                                                      approverIndex,
                                                      userIndex
                                                    )
                                                  : null,
                                              popupText:
                                                "Select approver action",
                                            })
                                          );
                                        }
                                      }
                                    }}
                                    label={getNamebyId("users", user.userid)}
                                  />
                                </Tooltip>
                              ))
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Divider className="mb-5" />
                  {deliverable?.master && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body2">Master</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3} className="mr-20">
                            <FormControl fullWidth className="mb-10">
                              {dateRangeSelector("masterDate", "masterStart")}
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} className="mr-20">
                            <FormControl fullWidth className="mb-10">
                              {dateRangeSelector("masterDate", "masterEnd")}
                            </FormControl>
                          </Grid>
                          <Grid item xs={3} className="mr-20">
                            <FormControl fullWidth className="mb-10">
                              <DatePicker
                                label="Completion date"
                                value={
                                  (deliverable?.masterDate?.completion &&
                                    moment(
                                      deliverable?.masterDate?.completion.split(
                                        "T"
                                      )[0]
                                    )) ||
                                  null
                                }
                                minDate={
                                  moment(deliverable?.start?.split("T")[0]) ||
                                  null
                                }
                                format="MM/dd/yy"
                                onChange={(date) =>
                                  handleDataChange({
                                    target: {
                                      type: "date",
                                      value: date,
                                      name: "masterDateCompletion",
                                    },
                                  })
                                }
                                InputProps={{
                                  endAdornment: deliverable?.approval
                                    ?.completion &&
                                    !excluded && (
                                      <IconButton
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleDataChange({
                                            target: {
                                              type: "date",
                                              value: null,
                                              name: "masterDateCompletion",
                                            },
                                          });
                                        }}
                                        className="pd-0"
                                      >
                                        <Close />
                                      </IconButton>
                                    ),
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {deliverable?.executed && (
                <>
                  <Divider className="mt-5 mb-5" />
                  <Grid>
                    <div>Execution</div>
                    <Tooltip
                      title={
                        getNamebyId("users", deliverable.executed?.userid) || ""
                      }
                    >
                      <Chip
                        className={
                          "mr-5 border-r-20 chip " +
                          (deliverable.executed?.approved
                            ? "chip-success"
                            : "chip-error")
                        }
                        onClick={() => {
                          if (!excluded) {
                            handleExecuted();
                          }
                        }}
                        label={getNamebyId(
                          "users",
                          deliverable.executed?.userid
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}
            </form>
          </Card>
        </Grid>
        <FeatureFlag feature={FEATURE_FLAGS.WorkloadEntry}>
          <Grid item xs={12} className="mt-10">
            <Card className="pd-10">
              <Grid container>
                <Grid item xs={12} alignItems="center" justify="center">
                  <Typography
                    variant="h6"
                    className="mb-20"
                    style={{ textAlign: "left" }}
                  >
                    Resources management
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {deliverableUserList?.map((user, index) => (
                      <div
                        style={{
                          flex: 1,
                          marginBottom: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid style={{ flex: 1 }}>
                          <Typography style={{ fontWeight: "bold" }}>
                            {user?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography style={{ textAlign: "center" }}>
                            Planned hours
                          </Typography>
                          <TextField
                            inputProps={{
                              style: {
                                textAlign: "center",
                              },
                            }}
                            error={
                              error &&
                              !user.plannedMinutes &&
                              user.plannedMinutesText.trim()
                            }
                            helperText={
                              error &&
                              !user.plannedMinutes &&
                              user.plannedMinutesText.trim()
                                ? "incorrect format"
                                : ""
                            }
                            onBlur={(e) => {
                              handleUserHourChange({
                                name: e.target.name,
                                value:
                                  !checkHourMinuteRegex(e.target.value) &&
                                  checkTimeformat(e.target.value)
                                    ? returnCorrectTimeFormat(e.target.value)
                                    : e.target.value,
                                index,
                              });
                            }}
                            className="mt-10 mb-10"
                            variant="standard"
                            value={user.plannedMinutesText}
                            placeholder="0h 0m"
                            name="plannedMinutesText plannedMinutes"
                            size="small"
                            onChange={(e) =>
                              handleUserHourChange({
                                name: e.target.name,
                                value: e.target.value,
                                index,
                              })
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <Typography style={{ textAlign: "center" }}>
                              Worked hours
                            </Typography>

                            <TextField
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                },
                              }}
                              className="mt-10 mb-10"
                              error={
                                error &&
                                !user.workedMinutes &&
                                user.workedMinutesText.trim()
                              }
                              helperText={
                                error &&
                                !user.workedMinutes &&
                                user.workedMinutesText.trim()
                                  ? "incorrect format"
                                  : ""
                              }
                              onBlur={(e) => {
                                handleUserHourChange({
                                  name: e.target.name,
                                  value:
                                    !checkHourMinuteRegex(e.target.value) &&
                                    checkTimeformat(e.target.value)
                                      ? returnCorrectTimeFormat(e.target.value)
                                      : e.target.value,
                                  index,
                                });
                              }}
                              variant="standard"
                              value={user.workedMinutesText}
                              placeholder="0h 0m"
                              name="workedMinutesText workedMinutes"
                              size="small"
                              onChange={(e) =>
                                handleUserHourChange({
                                  name: e.target.name,
                                  value: e.target.value,
                                  index,
                                })
                              }
                            />
                            {user?.totalWorkedMinutes && (
                              <Typography
                                style={{ fontSize: 10, color: "gray" }}
                              >
                                {user?.totalWorkedMinutesText} logged
                              </Typography>
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: 12,
                            }}
                          >
                            Date
                          </Typography>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              style={{ marginTop: 10 }}
                            >
                              <DatePicker
                                inputVariant="standard"
                                format="MM/dd/yyyy"
                                value={
                                  user.date
                                    ? moment(user.date?.split("T")[0])
                                    : ""
                                }
                                open={datePickerIndex === index}
                                // onClick={() => {
                                //   setDatePickerIndex(index);
                                // }}
                                onClose={() => setDatePickerIndex(undefined)}
                                onChange={(date) => {
                                  handleUserHourChange({
                                    name: "date",
                                    value: moment(date).format(
                                      "YYYY-MM-DD[T00:00:00.000Z]"
                                    ),
                                    index,
                                  });
                                  // setDatePickerIndex(undefined);
                                }}
                              />
                            </FormControl>
                            <IconButton
                              onClick={() => handleOpenCalendar(index)}
                            >
                              <CalendarIcon />
                            </IconButton>
                          </div>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </FeatureFlag>
        <Grid item xs={12} className="mt-10">
          <Card className="pd-10">
            <Grid container columns={12} justify="space-between">
              <Grid item xs={3}>
                <div className="txt-grey">Lapsed</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.lapsed, 2) || 0}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">Differential</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.differential, 2) || 0}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">Effort</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.effort, 2) || 0}
                </div>
              </Grid>
            </Grid>
            <Divider className="mt-5 mb-5" />
            <Grid container columns={12} justify="space-between">
              <Grid item xs={3}>
                <div className="txt-grey">EV</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.EV) || 0}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">PV</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.PV) || 0}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">Weight</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.weight) || 0}
                </div>
              </Grid>
            </Grid>
            <Divider className="mt-5 mb-5" />
            <Grid container columns={12} justify="space-between">
              <Grid item xs={3}>
                <div className="txt-grey">System EV</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.systemEV) || 0}%
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">System PV</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.systemPV) || 0}%
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="txt-grey">System Status</div>
                <div className="txt-grey">
                  {_.round(deliverable?.calculated?.systemStatus) || 0}%
                </div>
              </Grid>
            </Grid>
            <Divider className="mt-5 mb-5" />

            <Grid container columns={12}>
              {deliverable.predecessors?.length > 0 && (
                <Grid container columns={12} className="mb-10">
                  <Grid item xs={12}>
                    <div className="txt-grey">Predecessors</div>
                    {deliverable?.predecessors?.map((pre, index) => (
                      <Chip
                        key={index}
                        className="mr-5 border-r-20"
                        label={
                          metaSystem?.mainSystem?.deliverables?.find(
                            (d) => d._id === pre
                          )?.name
                        }
                      ></Chip>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} className="mt-10">
          {deliverable?.master && deliverable?.status === 100 && (
            <Card>
              {deliverable?.master && <CardHeader title={<>Master</>} />}
              <CardContent>
                <Grid item xs={4}>
                  <InputLabel className="mb-5">
                    Upload Master Document
                  </InputLabel>
                  <input
                    accept=".pdf, .docx, .doc, .xlsx, .xls"
                    className="d-none"
                    type="file"
                    name="file"
                    ref={fileMaster}
                    onChange={(e) => handleMaster(e)}
                  />
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={() => fileMaster.current.click()}
                  >
                    {masterLoading ? (
                      <CircularProgress />
                    ) : deliverable?.masterFile ? (
                      <div className="d-jsb-cen">
                        <UploadIcon className="primary__icon" />
                        &nbsp;
                        {deliverable?.masterFile.fileName.substring(
                          deliverable?.masterFile.fileName.indexOf("-") + 1
                        )}
                      </div>
                    ) : (
                      <div className="d-jsb-cen">
                        <UploadIcon className="primary__icon" />
                        &nbsp; File
                      </div>
                    )}
                  </Button>
                  <div className="d-jsb-c">
                    {deliverable?.masterFile && (
                      <Typography
                        variant="caption"
                        className="pointer"
                        color="primary"
                        onClick={() =>
                          deliverable?.masterFile && setOpenFileView(true)
                        }
                      >
                        <u>View File</u>
                      </Typography>
                    )}
                    {deliverable?.masterFile && (
                      <Typography
                        style={{ color: "red" }}
                        variant="caption"
                        className="pointer"
                        color="primary"
                        onClick={() => handleRemoveMasterFile()}
                      >
                        <u>Remove File</u>
                      </Typography>
                    )}
                  </div>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} className="mt-10">
          <Card>
            {documentList?.length !== 0 && <CardHeader title={<>Document</>} />}
            <CardContent>
              {documentList?.map((document, index) => (
                <div key={index}>
                  <Tooltip
                    title={
                      DOC_STATUS.find(
                        (status) => status.value === document.documentStatus
                      ).label
                    }
                  >
                    <Link
                      className="pointer"
                      onClick={() =>
                        history.push(
                          LINKS.EDIT_DOCUMENTS.HREF.replace(
                            ":projectId",
                            projectId
                          ).replace(":id", document.documentId)
                        )
                      }
                    >
                      {document.documentNumber}
                    </Link>
                  </Tooltip>
                </div>
              ))}
              <div className="wd-50">
                <DeliverableStatusTimeline
                  deliverable={deliverable}
                  editCard={false}
                  showStatus={false}
                  docLength={documentList.length === 0}
                  fullScreen={true}
                  timelineType={timelineType}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className="mt-10">
          <Card>
            {deviationList?.length !== 0 && (
              <CardHeader title={<>Deviation</>} />
            )}
            <CardContent>
              {deviationList?.map((deviation, index) => (
                <div key={index}>
                  <Tooltip
                    title={
                      DEVIATION_STATUS.find(
                        (status) => status.value === deviation?.status
                      )?.label
                    }
                  >
                    <Link
                      className="pointer"
                      onClick={() =>
                        history.push(
                          LINKS.EDIT_DEVIATIONS.HREF.replace(
                            ":projectId",
                            projectId
                          ).replace(":id", deviation?._id)
                        )
                      }
                    >
                      {deviation?.deviationNumber}
                    </Link>
                  </Tooltip>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} className="mt-10">
          <Card>
            <CardHeader
              className="al-flx-s"
              title={<>{deliverable?.name}&nbsp;Notes</>}
              action={
                <div>
                  {!excluded && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleNoteCreate(newNote);
                      }}
                    >
                      Add Note
                    </Button>
                  )}
                </div>
              }
            />
            <Divider className="mb-5" />
            <CardContent className="max-ht-620 scroll-y">
              {deliverable?.notes?.length > 0 &&
                deliverable?.notes.map((note, index) => (
                  <Grid
                    container
                    columns={10}
                    key={index}
                    className="card-sdw mb-10"
                    spacing={2}
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <Tooltip title={note.type}>
                        <Typography
                          className="pointer txt-elps"
                          color="primary"
                          variant="subtitle2"
                          onClick={() => {
                            setNotesDetailModal(true);
                            setEditNote({ ...deliverable.notes[index] });
                          }}
                        >
                          {note.type}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      {moment(note?.date?.split("T")[0])?.format("MM/DD/YY")}
                    </Grid>
                    <Grid item xs={1}>
                      <Checkbox
                        disabled={excluded}
                        className="pd-0"
                        checked={note.status || null}
                        onChange={() => handleNoteStatus(index)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title={note.description}>
                        <div className="txt-elps">{note.description}</div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      <AvatarGroup max={2} className="avatar">
                        {note.resource.map((uid) => (
                          <Tooltip title={getNamebyId("users", uid)} key={uid}>
                            <Avatar
                              alt={getNamebyId("users", uid)}
                              sx={{ bgcolor: "#5A83BB" }}
                            >
                              {getNamebyId("users", uid)?.replace(
                                /[^A-Z]/g,
                                ""
                              )}
                            </Avatar>
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Grid>
                  </Grid>
                ))}
              {deliverable?.notes?.length === 0 && (
                <div className="mb-5">No notes available</div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={notesDetailModal}
        onClose={() => {
          setNotesDetailModal(false);
          setEditNote({});
        }}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Grid container justify="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                setNotesDetailModal(false);
                setEditNote({});
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            columns={12}
            justify="space-between"
            className="mb-20"
          >
            <Grid item xs={12} className="mb-20">
              <InputLabel>Type</InputLabel>
              <FormControl fullWidth className="mb-10 mt-5">
                <Select
                  isDisabled={excluded}
                  value={{
                    value: editNote?.type,
                    label: editNote?.type?.toUpperCase(),
                  }}
                  options={NOTE_TYPES}
                  onChange={(selected) =>
                    handleDataChange({
                      target: {
                        value: selected.value,
                        name: "type",
                      },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Date</InputLabel>
              <FormControl fullWidth>
                <DatePicker
                  disabled={
                    excluded ||
                    [PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                      permissions
                    )
                  }
                  minDate={moment(deliverable?.start?.split("T")[0])}
                  maxDate={moment(deliverable?.end?.split("T")[0])}
                  value={
                    (editNote?.date && moment(editNote?.date?.split("T")[0])) ||
                    null
                  }
                  format="MM/dd/yy"
                  onChange={(date) =>
                    handleDataChange({
                      target: { type: "date", value: date, name: "noteDate" },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Status</InputLabel>
              <Checkbox
                disabled={excluded}
                className="pd-0"
                checked={editNote.status || null}
                label="Status"
                onChange={() =>
                  setEditNote({
                    ...editNote,
                    status: !editNote.status,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container columns={12} className="mb-20">
            <InputLabel id="demo-multiple-chip-label">Resource</InputLabel>
            <FormControl fullWidth className="mb-10 mt-5">
              <Select
                isDisabled={excluded}
                isClearable={false}
                isMulti
                options={getUsers().filter(
                  (user) => deliverable.department === user.department
                )}
                value={getDefaultResourcesNote(editNote)}
                closeMenuOnSelect={false}
                onChange={(selected) =>
                  handleDataChange({
                    target: { value: selected, name: "noteResource" },
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid container columns={12}>
            <Grid item xs={12}>
              <InputLabel id="demo-multiple-chip-label">Description</InputLabel>
              <FormControl fullWidth className="mb-10 mt-5">
                <TextField
                  disabled={excluded}
                  fullWidth
                  multiline
                  rows={4}
                  variant={"outlined"}
                  type="string"
                  value={editNote.description || ""}
                  name="description"
                  onChange={(e) =>
                    handleDataChange({
                      target: { value: e.target.value, name: "description" },
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="pd-10">
          <DialogActions>
            {!excluded && (
              <div className="d-jsb-r">
                <div className="mr-10">
                  <Button
                    variant="outlined"
                    color="default"
                    className="btn-error"
                    onClick={onDelete}
                  >
                    DELETE
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleNoteUpdate(editNote)}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {openFileView && (
        <DocumentViewer
          open={openFileView}
          fileData={deliverable?.masterFile}
          handleClose={() => setOpenFileView(false)}
        />
      )}
      {openDuration && (
        <SetDuration
          data={durationData}
          openDialog={openDuration}
          onClose={handleDurationClose}
          holidayList={organization?.organizationHoliday}
          onRowChange={saveDates}
        />
      )}
      {!excluded && (
        <div>
          <Divider className="mb-5" />
          <div className="d-jsb-r">
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave();
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default memo(EditDeliverable);
