import { getJQ, put, del, post, get } from "services/axios";

export const getMetaSystems = async (data) => await getJQ("metasystems", data);

export const getSystemHistory = async (data) =>
  await getJQ(["metasystems", "systems", "history"], data);

export const createMetaSystem = async (params) =>
  await post("metasystems", params);

export const updateMetaSystem = async (params) => {
  return await put("metasystems", params);
};

export const updateMultipleMetaSystems = async (params) => {
  return await post("metasystems/assignToPhase", params);
};

export const deleteMetaSystem = async (params) => {
  return await del("metasystems", params);
};

export const getSystemDocs = async (systemId) => {
  return await get(`v2/systems/${systemId}/documents`);
};

export const getSystemDeviations = async (systemId) => {
  return await get(`v2/systems/${systemId}/deviations`);
};

export const initDeliverables = async (params) => {
  return await put(["metasystems", "systems", "initialize"], params);
};
export const getDeliverable = async (systemId, deliverableId) => {
  return await get(`v2/systems/${systemId}/deliverables/${deliverableId}`);
};
export const getTask = async (systemId, taskId) => {
  return await get(`v2/systems/${systemId}/tasks/${taskId}`);
};

export const getEscalation = async (systemId, escalationId) => {
  return await get(`v2/systems/${systemId}/escalations/${escalationId}`);
};

export const createDeliverable = async (params) => {
  return await post(["metasystems", "systems", "nested"], params);
};

export const updateDeliverable = async (params) => {
  return await put(["metasystems", "systems", "nested"], params);
};

export const updateDeliverableOrder = async (params) => {
  return await put(["metasystems", "systems", "deliverable-reorder"], params);
};

export const deleteDeliverable = async (params) => {
  return await del(["metasystems", "systems", "nested"], params);
};

export const updateDeliverablePositions = async (params) => {
  return await put(["metasystems", "systems", "positioning"], params);
};

export const createDeliverableNote = async (params) => {
  return await post(["metasystems", "systems", "nested", "notes"], params);
};

export const updateDeliverableNote = async (params) => {
  return await put(["metasystems", "systems", "nested", "notes"], params);
};

export const deleteDeliverableNote = async (params) => {
  return await del(["metasystems", "systems", "nested", "notes"], params);
};

export const createMainTask = async (systemId, params) => {
  return await post(`metasystems/systems/tasks?systemId=${systemId}`, params);
};

export const createEscalation = async (params) => {
  return await post(`metasystems/systems/escalations`, params);
};

export const updateMainTask = async (systemId, taskId, params) => {
  return await put(
    `metasystems/systems/tasks?systemId=${systemId}&taskId=${taskId}`,
    params
  );
};

export const updateEscalation = async (params) => {
  return await put(`metasystems/systems/escalations`, params);
};

export const deleteMainTask = async (systemId, taskId) => {
  return await del(
    `metasystems/systems/tasks?systemId=${systemId}&taskId=${taskId}`
  );
};

export const deleteEscalation = async (mainId, escalationId) => {
  return await del(
    `metasystems/systems/escalations?mainId=${mainId}&_id=${escalationId}`
  );
};

export const createSubTask = async (systemId, taskId, params) => {
  return await post(
    `metasystems/systems/tasks/subtasks?systemId=${systemId}&taskId=${taskId}`,
    params
  );
};

export const updateSubTask = async (systemId, taskId, params) => {
  return await put(
    `metasystems/systems/tasks/subtasks?systemId=${systemId}&taskId=${taskId}&subTaskId=${params._id}`,
    params
  );
};

export const deleteSubTask = async (systemId, taskId, subTaskId) => {
  return await del(
    `metasystems/systems/tasks/subtasks?systemId=${systemId}&taskId=${taskId}&subTaskId=${subTaskId}`
  );
};

export const createSubEscalation = async (params) => {
  return await post(`metasystems/systems/escalations/notes`, params);
};

export const updateSubEscalation = async (params) => {
  return await put(`metasystems/systems/escalations/notes`, params);
};

export const deleteSubEscalation = async (
  subEscalationId,
  mainId,
  escalationId
) => {
  return await del(
    `metasystems/systems/escalations/notes?subEscalationId=${subEscalationId}&mainId=${mainId}&_id=${escalationId}`
  );
};
