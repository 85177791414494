import { get, post, del, put, getJQ } from "services/axios";

const uploadFile = async (projectId, file) =>
  await post(`/v2/dms/project/${projectId}/document/upload`, file);

const postDocument = async (projectId, params) =>
  await post(`/v2/dms/project/${projectId}/document`, params);

const updateDocument = async (projectId, docId, params) =>
  await put(`/v2/dms/project/${projectId}/documents/${docId}`, params);

const deleteDocument = async (projectId, docId) =>
  await del(`/v2/dms/project/${projectId}/documents/${docId}`);

const approveDocument = async (projectId, docId, params) =>
  await put(`/v2/dms/project/${projectId}/documents/${docId}/approve`, params);

const getDocument = async (projectId) =>
  await get(`/v2/dms/project/${projectId}/myTask/`);

const getCompletedDocument = async (projectId) =>
  await get(`/v2/dms/project/${projectId}/completedDocuments/`);

const getDocumentById = async (projectId, documentId) =>
  await get(`/v2/dms/project/${projectId}/documents/${documentId}`);

const getDocumentHistoryById = async (projectId, documentHistoryId) =>
  await get(
    `/v2/dms/project/${projectId}/documentHistory/${documentHistoryId}`
  );

const getDocuments = async (projectId) =>
  await get(`/v2/dms/project/${projectId}/myDocuments/`);

const getMyBookmarks = async (projectId) =>
  await get(`/v2/dms/project/${projectId}/myBookmarks/`);

const searchDocuments = async (
  projectId,
  searchQuery,
  skip = 0,
  limit = null
) =>
  await getJQ(`/v2/dms/project/${projectId}/searchDocuments`, {
    searchQuery,
    skip,
    limit,
  });

const postAnnotation = async (documentId, documentHistoryId, params) =>
  await post(
    `/v2/dms/document/${documentId}/history/${documentHistoryId}/annotate`,
    params
  );

const updateAnnotation = async (
  annotationId,
  documentHistoryId,
  documentId,
  params
) =>
  await put(
    `/v2/dms/document/${documentId}/history/${documentHistoryId}/annotate/${annotationId}`,
    params
  );

const deleteAnnotation = async (annotationId, documentHistoryId, documentId) =>
  await del(
    `/v2/dms/document/${documentId}/history/${documentHistoryId}/annotate/${annotationId}`
  );

const getAnnotations = async (documentId, documentHistoryId) =>
  await get(`/v2/dms/document/${documentId}/annotations/${documentHistoryId}`);

const getPrefix = async (projectId) =>
  await get(`v2/dms/document/prefixList/project/${projectId}`);

const updatePrefix = async (projectId, params) =>
  await put(`v2/dms/document/prefixList/project/${projectId}`, params);

const addDocType = async (projectId, params) =>
  await post(`v2/documentType/project/${projectId}`, params);

const delDocType = async (projectId, docId) =>
  await del(`v2/documentType/project/${projectId}/${docId}`);

const bookmarkDocument = async (documentId) =>
  await post(`/v2/dms/document/${documentId}/bookmark`, {});

/** DMS Templates */
const getDmsTemplates = async (projectId) =>
  await get(`/v2/dms/project/${projectId}/templates/`);

const postDmsTemplates = async (projectId, params) =>
  await post(`/v2/dms/project/${projectId}/templates/`, params);

const updateDmsTemplates = async (projectId, templateId, params) =>
  await put(`/v2/dms/project/${projectId}/templates/${templateId}`, params);

const deleteDmsTemplates = async (projectId, templateId) =>
  await del(`/v2/dms/project/${projectId}/templates/${templateId}`);

const getDmsTemplateDetail = async (projectId, templateId) =>
  await get(`/v2/dms/project/${projectId}/templates/${templateId}`);

const getDocumentCR = async (projectId) =>
  await get(`v2/dcr/project/${projectId}/myTasks/`);

const getCompletedDocumentCR = async (projectId) =>
  await get(`v2/dcr/project/${projectId}/completedTasks/`);

const getClosedDocumentCR = async (projectId) =>
  await get(`v2/dcr/project/${projectId}/closedTasks/`);

const getAllDocumentCR = async (projectId) =>
  await get(`v2/dcr/project/${projectId}/changeRequests/`);

export {
  uploadFile,
  postDocument,
  updateDocument,
  deleteDocument,
  approveDocument,
  getDocument,
  getDocumentById,
  getDocuments,
  postAnnotation,
  updateAnnotation,
  getAnnotations,
  deleteAnnotation,
  getPrefix,
  addDocType,
  delDocType,
  updatePrefix,
  getDocumentHistoryById,
  bookmarkDocument,
  getMyBookmarks,
  searchDocuments,
  getCompletedDocument,
  getDmsTemplateDetail,
  getDmsTemplates,
  postDmsTemplates,
  updateDmsTemplates,
  deleteDmsTemplates,
  getDocumentCR,
  getCompletedDocumentCR,
  getClosedDocumentCR,
  getAllDocumentCR,
};
