import { memo } from "react";
import {
  Grid,
  LinearProgress,
  styled,
  Stepper,
  Step,
  StepButton,
} from "@material-ui/core";
import { ReactComponent as MasterIcon } from "icons/master_icon.svg";
import { ReactComponent as CheckCircleIcon } from "icons/check_circle_filled.svg";
import { ReactComponent as PendingCircleIcon } from "icons/pending_circle_filled.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import "../deliverableList.css";
import { ReactComponent as DeviationIcon } from "icons/deviation_icon.svg";

import _ from "lodash";
import moment from "moment";
import {
  colorIFRA,
  colorRA,
  getFormattedDate,
} from "pages/Projects/ProjectInsight/components/DeliverableSnapshot/snapshotHelper";
import "./statusTimeline.css";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as CautionCircleIcon } from "icons/caution_circle_icon.svg";

const useStyles = makeStyles((theme) => ({
  containerDiv: {
    display: "flex",
    alignItems: "center",
  },

  deviationIconStyle: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "calc(80% + 2px)",
  },

  deviationIconStyleExecution: {
    marginLeft: "-8px",
  },

  progressStyle: {
    marginTop: "0.7rem",
    width: "95%",
  },

  progressExecution: {
    marginTop: "1.35rem",
    width: "88%",
  },

  executionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  leftIcon: {
    marginLeft: "-4px",
  },

  rightIcon: {
    marginRight: "-4px",
  },

  progressContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  minHeight: "3px",
  transform: "translateY(-110%)",
}));

const DeliverableStatusTimeline = (props) => {
  const {
    deliverable,
    editCard,
    showStatus,
    timelineType,
    isWorkflowCall,
    deviationList,
  } = props;
  const { end, completion, status } = deliverable;

  let today = new Date();
  today = today.toISOString();
  const classes = useStyles();

  const getStyle = (completion, end, status) => {
    if (isWorkflowCall) {
      return "grayBackgroundColor";
    }

    return getFormattedDate(today) > getFormattedDate(end) &&
      !completion &&
      Math.round(status) < 100
      ? "redBackground"
      : completion
      ? getFormattedDate(end) >= getFormattedDate(completion) &&
        Math.round(status) === 100
        ? "greenBackground"
        : getFormattedDate(end) < getFormattedDate(completion) &&
          Math.round(status) === 100
        ? "orangeBackground"
        : "primaryBackGround"
      : "primaryBackGround";
  };
  const getClassname = (completion, end, status) => {
    if (isWorkflowCall) {
      return "gray";
    }

    return getFormattedDate(today) > getFormattedDate(end) &&
      !completion &&
      Math.round(status) < 100
      ? "red"
      : completion
      ? getFormattedDate(end) >= getFormattedDate(completion) &&
        Math.round(status) === 100
        ? "green"
        : getFormattedDate(end) < getFormattedDate(completion) &&
          Math.round(status) === 100
        ? "orange"
        : "primary"
      : "primary";
  };
  const getDatesOnHover = (start, end) => {
    const startDate = moment(start?.split("T")[0])?.format("MM/DD/YY");
    const endDate = moment(end?.split("T")[0])?.format("MM/DD/YY");
    return startDate + " - " + endDate;
  };

  const colorClassMap = {
    "#bdbdbd": "grey_icon",
    "#5E5E5E": "darkgrey_icon",
    "#D32F2F": "red_icon",
    "#5A83BB": "primary_icon",
    "#27AE60": "green_icon",
    "#F39C12": "orange_icon",
  };

  const deviationStage = () => {
    if (deviationList.length === 0) {
      return;
    }
    if (Number(deliverable?.draft?.status) === 100) {
      if (!deliverable?.ifr?.issued) {
        return "DRAFT";
      }
      if (Number(deliverable?.review?.status) !== 100) {
        return "IFR";
      }
      if (!deliverable?.ifa?.issued) {
        return "REVIEW";
      }
      if (Number(deliverable?.approval?.status) !== 100) {
        return "IFA";
      }

      if (Number(deliverable?.approval?.status) === 100) {
        return "APPROVAL";
      }
    }
    return "DRAFT";
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const getDeviationIcon = (type) => {
    const deviationNumbers = deviationList?.map((deviation, index) => (
      <div key={index}>{deviation?.deviationNumber}</div>
    ));

    return (
      <div
        className={
          type === "EXECUTION"
            ? classes.deviationIconStyleExecution
            : classes.deviationIconStyle
        }
      >
        <div style={{ transform: "rotate(90deg)" }}>
          <HtmlTooltip title={deviationNumbers} placement="top">
            <DeviationIcon />
          </HtmlTooltip>
        </div>
      </div>
    );
  };

  const findMaxCapaDueDate = (deviationList) => {
    let maxDate = null;

    deviationList.forEach((deviation) => {
      if (deviation.capaDueDate) {
        const deviationCapaDueDate = moment(deviation.capaDueDate);
        if (!maxDate || deviationCapaDueDate.isAfter(maxDate)) {
          maxDate = deviationCapaDueDate;
        }
      }
    });

    return maxDate ? maxDate.format("YYYY-MM-DD") : null;
  };

  const getExecutionIconColor = (
    status,
    icon,
    completionDate,
    startDate,
    endDate
  ) => {
    const currentDate = moment().format("YYYY-MM-DD");

    if (Number(status) === 0) {
      if (
        moment(startDate?.split("T")[0]).isSameOrAfter(currentDate) &&
        startDate
      ) {
        return "grey_icon";
      } else {
        return "red_icon";
      }
    }

    if (Number(status) > 0 && Number(status) !== 100) {
      if (icon === "START") {
        if (moment(endDate?.split("T")[0]).isBefore(currentDate) && endDate) {
          return "red_icon";
        }
        return "green_icon";
      }

      if (icon === "END") {
        if (moment(endDate?.split("T")[0]).isBefore(currentDate) && endDate) {
          return "red_icon";
        }
        return "primary_icon";
      }
    }

    if (Number(status) === 100) {
      if (
        moment(completionDate?.split("T")[0]).isAfter(endDate.split("T")[0]) &&
        completionDate
      ) {
        return "orange_icon";
      } else {
        return "green_icon";
      }
    }
  };

  return (
    <div>
      <Grid container column={12} spacing={2}>
        <Grid item xs={11}>
          {timelineType && (
            <div>
              <Grid
                container
                column={12}
                spacing={2}
                className="txt-sm"
                justify="space-around"
              >
                <Grid item xs={2} className="d-jsb-cen">
                  Draft
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  IFR
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  Reviewed
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  IFA
                </Grid>
                <Grid item xs={2} className="d-jsb-cen">
                  Approved
                </Grid>
                {deliverable?.master && (
                  <Grid item xs={2} className="d-jsb-cen">
                    Master
                  </Grid>
                )}
              </Grid>
              <Stepper alternativeLabel style={{ padding: "0px" }}>
                <Tooltip
                  title={getDatesOnHover(
                    deliverable?.draft?.start,
                    deliverable?.draft?.end
                  )}
                >
                  <Step>
                    <StepButton
                      icon={
                        Number(deliverable?.draft?.status) === 100 ? (
                          <CheckCircleIcon
                            className={
                              colorClassMap[
                                colorRA(
                                  deliverable?.draft?.completion,
                                  deliverable?.draft?.end,
                                  deliverable?.draft?.status,
                                  "timeline",
                                  isWorkflowCall
                                )
                              ]
                            }
                          />
                        ) : (
                          <PendingCircleIcon
                            className={
                              colorClassMap[
                                colorRA(
                                  deliverable?.draft?.completion,
                                  deliverable?.draft?.end,
                                  deliverable?.draft?.status,
                                  "timeline",
                                  isWorkflowCall
                                )
                              ]
                            }
                          />
                        )
                      }
                    ></StepButton>
                  </Step>
                </Tooltip>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.ifr?.start,
                      deliverable?.ifr?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          deliverable?.ifr?.issued ? (
                            <CheckCircleIcon
                              className={
                                colorClassMap[
                                  colorIFRA(
                                    deliverable?.ifr?.completion,
                                    deliverable?.ifr?.end || deliverable?.start,
                                    deliverable?.ifr?.issued,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          ) : (
                            <PendingCircleIcon
                              className={
                                colorClassMap[
                                  colorIFRA(
                                    deliverable?.ifr?.completion,
                                    deliverable?.ifr?.end || deliverable?.start,
                                    deliverable?.ifr?.issued,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          )
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <div className={classes.containerDiv}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(deliverable?.draft?.status || 0)}
                      className={
                        deviationStage() === "DRAFT"
                          ? "deviation-connector"
                          : "timeline-connector"
                      }
                    ></LinearProgress>
                    {deviationStage() === "DRAFT" && getDeviationIcon()}
                  </div>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.review?.start,
                      deliverable?.review?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          Number(deliverable?.review?.status) === 100 ? (
                            <CheckCircleIcon
                              className={
                                colorClassMap[
                                  colorRA(
                                    deliverable?.review?.completion,
                                    deliverable?.review?.end,
                                    deliverable?.review?.status,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          ) : (
                            <PendingCircleIcon
                              className={
                                colorClassMap[
                                  colorRA(
                                    deliverable?.review?.completion,
                                    deliverable?.review?.end,
                                    deliverable?.review?.status,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          )
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <div className={classes.containerDiv}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(deliverable?.review?.status || 0)}
                      className={
                        deviationStage() === "IFR"
                          ? "deviation-connector"
                          : "timeline-connector"
                      }
                    ></LinearProgress>
                    {deviationStage() === "IFR" && getDeviationIcon()}
                  </div>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.ifa?.start,
                      deliverable?.ifa?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          deliverable?.ifa?.issued ? (
                            <CheckCircleIcon
                              className={
                                colorClassMap[
                                  colorIFRA(
                                    deliverable?.ifa?.completion,
                                    deliverable?.ifa?.end || deliverable?.start,
                                    deliverable?.ifa?.issued,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          ) : (
                            <PendingCircleIcon
                              className={
                                colorClassMap[
                                  colorIFRA(
                                    deliverable?.ifa?.completion,
                                    deliverable?.ifa?.end || deliverable?.start,
                                    deliverable?.ifa?.issued,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          )
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <div className={classes.containerDiv}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        Math.round(deliverable?.review?.status || 0) === 100 &&
                        deliverable?.ifr?.issued &&
                        deliverable?.ifa?.issued
                          ? 100
                          : 0
                      }
                      className={
                        deviationStage() === "REVIEW"
                          ? "deviation-connector"
                          : "timeline-connector"
                      }
                    ></LinearProgress>
                    {deviationStage() === "REVIEW" && getDeviationIcon()}
                  </div>
                </Step>
                <Step>
                  <Tooltip
                    title={getDatesOnHover(
                      deliverable?.approval?.start,
                      deliverable?.approval?.end
                    )}
                  >
                    <Step>
                      <StepButton
                        icon={
                          Number(deliverable?.approval?.status) === 100 ? (
                            <CheckCircleIcon
                              className={
                                colorClassMap[
                                  colorRA(
                                    deliverable?.approval?.completion,
                                    deliverable?.approval?.end,
                                    deliverable?.approval?.status,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          ) : (
                            <PendingCircleIcon
                              className={
                                colorClassMap[
                                  colorRA(
                                    deliverable?.approval?.completion,
                                    deliverable?.approval?.end,
                                    deliverable?.approval?.status,
                                    "timeline",
                                    isWorkflowCall
                                  )
                                ]
                              }
                            />
                          )
                        }
                      ></StepButton>
                    </Step>
                  </Tooltip>
                  <div className={classes.containerDiv}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round(deliverable?.approval?.status || 0)}
                      className={
                        deviationStage() === "IFA"
                          ? "deviation-connector"
                          : "timeline-connector"
                      }
                    ></LinearProgress>
                    {deviationStage() === "IFA" && getDeviationIcon()}
                  </div>
                </Step>
                {deliverable?.master && (
                  <Step>
                    <Tooltip
                      title={getDatesOnHover(
                        deliverable?.masterDate?.start,
                        deliverable?.masterDate?.end
                      )}
                    >
                      <Step>
                        <StepButton
                          icon={
                            deliverable?.masterDate?.completion ? (
                              <CheckCircleIcon
                                className={
                                  colorClassMap[
                                    colorIFRA(
                                      deliverable?.masterDate?.completion,
                                      deliverable?.masterDate?.end,
                                      deliverable?.masterFile,
                                      "timeline",
                                      isWorkflowCall
                                    )
                                  ]
                                }
                              />
                            ) : (
                              <PendingCircleIcon
                                className={
                                  colorClassMap[
                                    colorIFRA(
                                      deliverable?.masterDate?.completion,
                                      deliverable?.masterDate?.end,
                                      deliverable?.masterFile,
                                      "timeline",
                                      isWorkflowCall
                                    )
                                  ]
                                }
                              />
                            )
                          }
                        ></StepButton>
                      </Step>
                    </Tooltip>
                    <div className={classes.containerDiv}>
                      <LinearProgress
                        variant="determinate"
                        value={deliverable?.masterFile ? 100 : 0}
                        className={
                          deviationStage() === "APPROVAL"
                            ? "deviation-connector"
                            : "timeline-connector"
                        }
                      ></LinearProgress>
                      {deviationStage() === "APPROVAL" && getDeviationIcon()}
                    </div>
                  </Step>
                )}
              </Stepper>
              <Grid container justify="space-between">
                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.end?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}

                    {moment(findMaxCapaDueDate(deviationList)).isAfter(
                      moment(deliverable?.end?.split("T")[0])
                    ) && (
                      <CautionCircleIcon className="ml-5 red__icon icons_msmall" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          {!timelineType && (
            <div>
              <Grid container justify="space-around">
                <Grid item xs={10}>
                  <div
                    className={`${getClassname(completion, end, status)} ${
                      classes.executionContainer
                    }`}
                  >
                    {Number(deliverable?.status) > 0 ? (
                      <CheckCircleIcon
                        className={`${getExecutionIconColor(
                          deliverable?.status,
                          "START",
                          deliverable?.completion,
                          deliverable?.start,
                          deliverable?.end
                        )} ${classes.leftIcon}`}
                      />
                    ) : (
                      <PendingCircleIcon
                        className={`${getExecutionIconColor(
                          deliverable?.status,
                          "START",
                          deliverable?.completion,
                          deliverable?.start,
                          deliverable?.end
                        )} ${classes.leftIcon}`}
                      />
                    )}
                    <div className={classes.progressContainer}>
                      <BorderLinearProgress
                        className={`${getStyle(completion, end, status)} ${
                          deviationList?.length > 0
                            ? classes.progressExecution
                            : classes.progressStyle
                        }`}
                        variant="determinate"
                        value={_.round(deliverable?.status || 0)}
                      />
                      {deviationList?.length > 0 &&
                        getDeviationIcon("EXECUTION")}
                    </div>

                    {Number(deliverable?.status) === 100 ? (
                      <CheckCircleIcon
                        className={`${getExecutionIconColor(
                          deliverable?.status,
                          "END",
                          deliverable?.completion,
                          deliverable?.start,
                          deliverable?.end
                        )} ${classes.rightIcon}`}
                      />
                    ) : (
                      <PendingCircleIcon
                        className={`${getExecutionIconColor(
                          deliverable?.status,
                          "END",
                          deliverable?.completion,
                          deliverable?.start,
                          deliverable?.end
                        )} ${classes.rightIcon}`}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.start?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container justify="center" className="txt-sm">
                    {moment(deliverable?.end?.split("T")[0])?.format(
                      "MM/DD/YY"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item xs={1}>
          {showStatus && (
            <Grid item xs={2} className="d-jsb-c">
              <Grid
                container
                style={{
                  marginLeft: editCard ? "45px" : "30px",
                  // marginTop: "10px",
                }}
              >
                {deliverable?.master && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: "11px" }}>Master</div>
                    <div>
                      <MasterIcon />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(DeliverableStatusTimeline);
