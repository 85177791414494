import authRoutes from "./authRoutes";
import overviewRoutes from "./overviewRoutes";
import projectManagementRoutes from "./projectManagementRoutes";
import sowTrackerRoutes from "./sowTrackerRoutes";
import workflowTemplateRoutes from "./workflowTemplateRoutes";
import userManagementRoutes from "./userManagementRoutes";
import recentActionRoutes from "./recentActionRoutes";
import profileEditRoute from "./profileEditRoute";
import notificationRoutes from "./notificationRoute";
import timesheetRoute from "./timesheetRoute";
import administrationRoutes from "./administrationRoutes";
import riskAssessmentManagementRoutes from "./riskAssessmentManagementRoutes";

export const dashboardLayoutRoutes = [
  overviewRoutes,
  projectManagementRoutes,
  sowTrackerRoutes,
  workflowTemplateRoutes,
  userManagementRoutes,
  recentActionRoutes,
  profileEditRoute,
  notificationRoutes,
  timesheetRoute,
  administrationRoutes,
  riskAssessmentManagementRoutes
];

export const authLayoutRoutes = [authRoutes];

export const sidebarRoutes = [
  overviewRoutes,
  projectManagementRoutes,
  sowTrackerRoutes,
  workflowTemplateRoutes,
  userManagementRoutes,
  timesheetRoute,
  administrationRoutes,
  riskAssessmentManagementRoutes
];
