import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { ColorButton } from "../../../../../components/UI/Buttons";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.custom.palette.black,
  },
  actionButtons: {
    justifyContent: "center",
    marginBottom: theme.spacing(5),
  },
  dialogTitle: (props) => ({
    marginBottom: !props.popupHeader && theme.spacing(5),
  }),
  dialogContentText: {
    color: theme.custom.palette.black,
    whiteSpace: "pre-line",
  },
}));
export default function UserConfirmDialog({
  open,
  handleClose,
  handleConfirm,
  selectedDel,
}) {
  const classes = useStyles();
  const [userActionDate, setUserActionDate] = useState(null);
  const isReviewer = selectedDel?.key === "reviewer";
  const isReviewerApproved = isReviewer && selectedDel?.isApproved;

  useEffect(() => {
    setUserActionDate(
      selectedDel?.userActionDate
        ? moment(new Date(selectedDel?.userActionDate)).format("MM/DD/YYYY")
        : null
    );
  }, [selectedDel?.userActionDate]);
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle className={classes.dialogTitle}>
        <div className="d-flex">
          <div> {isReviewer ? "Complete Review" : "Complete Approval"}</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className="mb-10">
          <DatePicker
            label="Completion date"
            value={userActionDate}
            format="MM/dd/yy"
            onChange={(date) => setUserActionDate(date)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUserActionDate(null);
                  }}
                  className="pd-0"
                >
                  <Close />
                </IconButton>
              ),
            }}
          />
          {isReviewerApproved && (
            <FormHelperText>
              Undo Review will remove the completion date.
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        {isReviewer ? (
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              setUserActionDate(null);
            }}
          >
            Cancel
          </Button>
        ) : (
          <ColorButton
            colour="red"
            className="mr-5"
            onClick={() => {
              handleConfirm("reject", userActionDate);
              setUserActionDate(null);
            }}
          >
            Reject
          </ColorButton>
        )}
        <ColorButton
          colour="lightGreen"
          className="mr-5"
          onClick={() => {
            handleConfirm(
              "approve",
              isReviewerApproved ? null : userActionDate
            );
            setUserActionDate(null);
          }}
        >
          {isReviewerApproved
            ? "Undo Review"
            : isReviewer
            ? "Review"
            : "Approve"}
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}
