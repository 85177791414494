import React, { memo, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  IconButton,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
} from "@material-ui/core";
import moment from "moment";
import { noop, POPUP_TYPE, STRING_INPUT_VALID } from "utils/constants";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import joi from "joi";
import { ArrowBack } from "@material-ui/icons";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DatePicker } from "@material-ui/pickers";
import { PERMISSION_TYPES } from "utils/constants";
import { useDispatch } from "react-redux";
import VektorTextField from "components/UI/TextFields/VektorTextField";
import { setPopup } from "redux/actions/popupActions";

const NOTE_TYPES = [
  { value: "ACTION", label: "Action" },
  { value: "DECISIONS", label: "Decisions" },
  { value: "ESCALATIONS", label: "Escalations" },
  { value: "LESSONS_LEARNED", label: "Lessons Learned" },
  { value: "INFORMATION", label: "Information" },
];

const schema = joi.object().keys({
  description: STRING_INPUT_VALID,
});

const EditDeliverableNotes = ({
  permissions,
  noteData = {},
  deliverable = {},
  users = [],
  close = noop,
  getNamebyId = noop,
  backToNotes = noop,
  handleNoteUpdate = noop,
  handleNoteDelete = noop,
  excluded = false,
}) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(noteData);
  const { control, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const handleDataChange = ({ target: { type, value, name } }) => {
    if (type === "date") {
      value = moment(value).format("YYYY-MM-DD[T00:00:00.000Z]");
    }

    const updatedData = { ...editData, [name]: value };

    setEditData(updatedData);
  };

  const onSubmit = (data) => {
    handleNoteUpdate({
      ...editData,
      noteId: editData._id,
      description: data.description,
    });
    backToNotes();
  };

  const onDelete = () => {
    dispatch(
      setPopup({
        popupType: POPUP_TYPE.confirm,
        popupText: "Are you sure you want to delete this note?",
        onConfirm: async () => {
          await handleNoteDelete(editData._id);
          backToNotes();
        },
      })
    );
  };

  return (
    <Card>
      <CardHeader
        className="al-flx-s"
        avatar={
          <IconButton className="pd-0" onClick={() => backToNotes()}>
            <ArrowBack />
          </IconButton>
        }
        // title={<>Detailed&nbsp;Notes</>}
        action={
          <div>
            <IconButton onClick={() => close()}>
              {/* <Close /> */}
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <Divider className="mb-5" />
      <CardContent className="max-ht-620 scroll-y">
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={12}
            justify="space-between"
            className="mb-20"
          >
            <Grid item xs={3}>
              <InputLabel>Type</InputLabel>
              <Select
                disabled={excluded}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={editData.type || ""}
                label="Type"
                name="type"
                onChange={handleDataChange}
              >
                {NOTE_TYPES.map((data, index) => (
                  <MenuItem value={data.value} key={index}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Date</InputLabel>
              <FormControl fullWidth>
                <DatePicker
                  disabled={
                    excluded ||
                    [PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                      permissions
                    )
                  }
                  minDate={moment(deliverable.start.split("T")[0])}
                  maxDate={moment(deliverable.end.split("T")[0])}
                  value={
                    (editData.date && moment(editData.date.split("T")[0])) ||
                    null
                  }
                  format="MM/dd/yy"
                  onChange={(date) =>
                    handleDataChange({
                      target: { type: "date", value: date, name: "date" },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Status</InputLabel>
              <Checkbox
                disabled={excluded}
                className="pd-0"
                checked={editData.status}
                label="Status"
                onChange={(e) =>
                  handleDataChange({
                    target: { name: "status", value: e.target.checked },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container columns={12} className="mb-20">
            <InputLabel>Resource</InputLabel>
            <FormControl fullWidth className="mb-10">
              <Select
                disabled={excluded}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={editData.resource || ""}
                name="resource"
                onChange={handleDataChange}
                renderValue={(selected) => (
                  <Box style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((selected) => (
                      <Chip
                        className="border-r-20 mr-5 mb-5"
                        key={selected}
                        label={getNamebyId("users", selected)}
                      />
                    ))}
                  </Box>
                )}
              >
                {users
                  .filter((u) => u.department === deliverable.department)
                  .map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container columns={12} className="mb-20">
            <Grid item xs={12}>
              <Controller
                as={<VektorTextField />}
                fullWidth
                name="description"
                label="Description"
                placeholder="Enter description"
                error={errors.description?.message}
                control={control}
                defaultValue={editData?.description || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  disabled: excluded,
                }}
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Divider className="mb-5" />
          {!excluded && (
            <div className="d-jsb-r">
              <div className="mr-10">
                <Button
                  variant="outlined"
                  color="default"
                  className="btn-error"
                  onClick={onDelete}
                >
                  DELETE
                </Button>
              </div>
              <div>
                <Button type="submit" variant="contained" color="primary">
                  SAVE
                </Button>
              </div>
            </div>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default memo(EditDeliverableNotes);
