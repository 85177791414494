import NotificationSubscriber from "utils/socket/notificationSubscriber"
import TYPES from 'utils/constants/action-types';
import { SOCKET_EVENTS } from "utils/constants/socket-events";

export default function socketMiddleware(socket) {
  return (params) => (next) => (action) => {
    const { dispatch } = params
    const { type, payload, event_name } = action

    // eslint-disable-next-line default-case
    switch (type) {
      // Connect to the socket when a user logs in
      case SOCKET_EVENTS.CONNECT: {
        socket.connect(payload)
        dispatch({
            type: TYPES.INITIALIZE_SOCKET,
            payload: socket
        })
        socket.on('connect', () => {
          // console.log("SOCKET CONNECTED SUCCESSFULLY")
        })
        socket.on('disconnect', () => {
          // console.log("SOCKET DISCONNECTED")
        })
        socket.on('connect_error', (error) => {
          // console.log("ERROR CONNECTION TO SOCKET::" + JSON.stringify(error))
        })
        new NotificationSubscriber(socket, dispatch);
        break
      }

      case SOCKET_EVENTS.EMIT: {
        socket.emit(event_name, payload)
        break
      }

      case SOCKET_EVENTS.DISCONNECT: {
        socket.disconnect()
        dispatch({
            type: TYPES.DISCONNECT_SOCKET
        })
        break
      }
    }
    return next(action)
  }
}