import { post } from "services/axios";

const sendErrorEmail = async (payload) =>
  await post(`/v2/general/sendEmail`, payload);

const signUrl = async (payload) =>
  await post(`/v2/general/signUrl`, payload);

const uploadMultipleFiles = async (payload) =>
  await post(`/v2/general/uploadMultipleFile`, payload);

const uploadSingleFile = async (payload) =>
  await post(`/v2/general/uploadFile`, payload);

export { sendErrorEmail, signUrl, uploadMultipleFiles, uploadSingleFile };
