import { post } from "services/axios";

const login = async (params) => {
  return await post(["users", "sign-in"], params);
};

const restorePassword = async (params) => {
  return await post(["users", "restore-password"], params);
};

const setPassword = async (params) => {
  return await post(["users", "set-password"], params);
};

const refreshToken = async (params) => {
  return await post(["users", "refresh"], params);
};

const verifyUser = async (params) => {
  return await post(["users", "verify"], params);
};

const verifyOtp = async (params) => {
  return await post(["users", "otp", "verify"], params);
};

const resendOtp = async (params) => {
  return await post(["users", "otp", "send"], params);
};

export {
  login,
  restorePassword,
  refreshToken,
  setPassword,
  verifyUser,
  verifyOtp,
  resendOtp,
};
