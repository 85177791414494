import ActionTypes from "utils/constants/action-types";
const Events = {
  NewNotification: "notification/new",
};

export default class NotificationSubscriber {
  constructor(socket, dispatch) {
    const user = JSON.parse(localStorage.getItem("currentUser"))._id;
    socket.on(`${Events.NewNotification}/${user}`, function (data) {
      dispatch({ type: ActionTypes.ADD_NOTIFICATION, payload: data });
    });
  }
}

export { Events as NotificationEvents };
