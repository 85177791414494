import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import DateRangePicker from "components/DateRangePicker";
import { autoScheduleSubTask } from "pages/WorkflowTemplates/EditWorkflowTemplate/helper";
import { withStyles } from "@material-ui/core/styles";
import { cloneDeep } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as pendingCircleIcon } from "icons/pending_circle_filled.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
  },
}));

const CustomConnector = withStyles({
  line: {
    height: 65,
    marginTop: 5,
  },
})(StepConnector);

const dataKeys = [
  {
    key: "draftDuration",
    label: "Draft Duration",
    cycle: "draft",
    cycleLabel: "Draft",
  },
  {
    key: "ifrDuration",
    label: "IFR Duration",
    cycle: "ifr",
    cycleLabel: "IFR",
  },
  {
    key: "reviewDuration",
    label: "Review Duration",
    cycle: "review",
    cycleLabel: "Review",
  },
  {
    key: "ifaDuration",
    label: "IFA Duration",
    cycle: "ifa",
    cycleLabel: "IFA",
  },
  {
    key: "approvalDuration",
    label: "Approval Duration",
    cycle: "approval",
    cycleLabel: "Approval",
  },
  {
    key: "masterDuration",
    label: "Master Duration",
    cycle: "masterDate",
    cycleLabel: "Master",
  },
  {
    key: "duration",
    label: "Total Duration",
    cycle: "deliverable",
    cycleLabel: "",
  },
];

const ReScheduleDeliverable = ({
  data,
  openDialog,
  onClose,
  holidayList,
  onRowChange,
  isDeliverable,
  saveAutoDel,
  handleChangedDate,
  updateIsDeliverable,
  saveSubTaskdata,
  prevAutoScheduledDel,
  isAutoSchedule,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [deliverable, setDeliverable] = useState(cloneDeep(data));
  useEffect(() => {
    if (openDialog && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleClose = () => {
    if (isAutoSchedule && !isDeliverable) {
      updateIsDeliverable(true);
    } else {
      setOpen(false);
      onClose();
    }
  };

  const setInitialDuration = (updatedData) => {
    dataKeys
      .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
      .forEach((val) => {
        if (val.key !== "duration") {
          if (updatedData?.duration >= 5) {
            updatedData[val.key] = 1;
            if (
              (val.key === "approvalDuration" && !updatedData?.master) ||
              val.key === "masterDuration"
            ) {
              updatedData[val.key] =
                updatedData?.duration -
                (updatedData?.master ? Number(5) : Number(4));
            }
          } else {
            updatedData[val.key] = 0;
            if (
              (val.key === "approvalDuration" && !updatedData?.master) ||
              val.key === "masterDuration"
            ) {
              updatedData[val.key] = updatedData?.duration;
            }
          }
        }
      });
    setInitialDates(updatedData);
  };

  const setInitialDates = (updatedData) => {
    dataKeys
      .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
      .forEach((val, index) => {
        if (val.key !== "duration") {
          if (val.cycle === "draft") {
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              start: updatedData?.start,
            };
            const endDate = moment(
              updatedData?.[val.cycle]?.["start"]?.split("T")[0]
            )
              .add("days", updatedData[val.key])
              .format("YYYY-MM-DDT00:00:00.000[Z]");
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              end: endDate,
            };
          } else {
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              start: updatedData?.[dataKeys[index - 1]?.cycle]?.end,
            };
            const endDate = moment(
              updatedData?.[val.cycle]?.["start"]?.split("T")[0]
            )
              .add("days", updatedData[val.key])
              .format("YYYY-MM-DDT00:00:00.000[Z]");
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              end: endDate,
            };
          }
        } else {
          const endDate = moment(updatedData?.start?.split("T")[0])
            .add("days", Number(updatedData.duration))
            .format("YYYY-MM-DDT00:00:00.000[Z]");
          updatedData = {
            ...updatedData,
            end: endDate,
          };
        }
        setDeliverable(updatedData);
      });
  };

  const setDateChange = (startDate, endDate, key) => {
    let reSchedule = true;
    let data = { ...deliverable };
    data.scheduled = false;
    if (key === "deliverable") {
      data.start = moment.utc(startDate).format("YYYY-MM-DDT00:00:00.000[Z]");
      data.end = moment.utc(endDate).format("YYYY-MM-DDT00:00:00.000[Z]");
      data.duration = moment(endDate).diff(moment(startDate), "days");
    }

    if (isAutoSchedule) {
      let prevStateIndex = prevAutoScheduledDel.findIndex(
        (deliverable) => deliverable._id === data._id
      );

      if (
        Number(data?.duration) ===
        Number(prevAutoScheduledDel[prevStateIndex]?.duration)
      ) {
        reSchedule = false;
      }
    }
    if (reSchedule) {
      setInitialDuration(data);
    } else {
      const updatedData = [data];
      autoScheduleSubTask(updatedData);
      setDeliverable(updatedData[0]);
    }
  };

  const subTaskhandleDateChange = (startDate, endDate, key) => {
    let data = { ...deliverable };
    data[key].start = moment
      .utc(startDate)
      .format("YYYY-MM-DDT00:00:00.000[Z]");
    data[key].end = moment.utc(endDate).format("YYYY-MM-DDT00:00:00.000[Z]");
    data[`${key}Duration`] = moment
      .utc(endDate)
      .diff(moment.utc(startDate), "days");

    const startIndex = dataKeys.findIndex((obj) => obj.cycle === key);
    for (let i = startIndex + 1; i < dataKeys.length; i++) {
      const currentPhase = dataKeys[i].cycle;
      const currentDuration = dataKeys[i].key;
      if (
        currentPhase !== "deliverable" &&
        (currentPhase !== "masterDate" || data.master)
      ) {
        const previousPhaseEnd = data[dataKeys[i - 1].cycle].end;
        data[currentPhase].start = previousPhaseEnd;
        data[currentPhase].end = moment
          .utc(previousPhaseEnd)
          .add(data[currentDuration], "days")
          .format("YYYY-MM-DDT00:00:00.000[Z]");
      }
    }
    data.duration =
      Number(data.draftDuration) +
      Number(data.ifrDuration) +
      Number(data.reviewDuration) +
      Number(data.ifaDuration) +
      Number(data.approvalDuration) +
      (data.master ? Number(data.masterDuration) : 0);

    data.start = moment
      .utc(data.draft.start)
      .format("YYYY-MM-DDT00:00:00.000[Z]");
    data.end = data.master
      ? moment.utc(data.masterDate.end).format("YYYY-MM-DDT00:00:00.000[Z]")
      : moment.utc(data.approval.end).format("YYYY-MM-DDT00:00:00.000[Z]");

    setDeliverable(data);
  };

  const typeLabelMap = {
    DOCUMENTATION: "Documentation",
    EXECUTION: "Execution",
  };

  const steps = ["Draft", "IFR", "Review", "IFA", "Approval", "Master"];

  return (
    <Dialog open={open} fullWidth className="pd-10">
      <DialogTitle style={{ textAlign: "center" }}>
        {!isDeliverable ? `Reschedule Deliverable` : `Please confirm duration`}
      </DialogTitle>
      <Typography style={{ textAlign: "center" }}>
        <span className="txt-bold">{`${typeLabelMap[data?.type]}: `}</span>
        {`${deliverable?.name}`}
      </Typography>
      <div className="pd-20">
        {!isDeliverable && data?.type === "EXECUTION" && (
          <Grid container columns={12} className="mb-10" spacing={2}>
            <Grid item xs={12}>
              <DateRangePicker
                label={"deliverable"}
                startDate={moment(deliverable?.start?.split("T")[0])}
                endDate={moment(deliverable?.end?.split("T")[0])}
                duration={deliverable?.duration}
                holidayList={holidayList}
                hanldeDataChange={setDateChange}
                orignalStartDate={moment(
                  deliverable?.start?.split("T")[0]
                ).format("DD MMMM YYYY")}
                orignalEndDate={moment(deliverable?.end?.split("T")[0]).format(
                  "DD MMMM YYYY"
                )}
              />
            </Grid>
          </Grid>
        )}
        {data?.type !== "EXECUTION" && (
          <Grid container columns={12}>
            <Grid item xs={9} className="mt-20">
              {!isDeliverable &&
                dataKeys
                  .filter((x) =>
                    !deliverable?.master ? x.key !== "masterDuration" : x.key
                  )
                  .map((column, index) => (
                    <div key={index} className="mb-10">
                      {column.key !== "duration" && (
                        <>
                          <Grid
                            container
                            columns={12}
                            className="mb-20"
                            spacing={2}
                          >
                            <Grid item xs={12}>
                              <DateRangePicker
                                label={column.cycle}
                                startDate={moment(
                                  deliverable?.[column.cycle]?.start?.split(
                                    "T"
                                  )[0]
                                )}
                                endDate={moment(
                                  deliverable?.[column.cycle]?.end?.split(
                                    "T"
                                  )[0]
                                )}
                                duration={deliverable?.[column.key]}
                                holidayList={holidayList}
                                hanldeDataChange={subTaskhandleDateChange}
                                orignalStartDate={moment(
                                  data?.[column.cycle]?.start?.split("T")[0]
                                ).format("DD MMMM YYYY")}
                                orignalEndDate={moment(
                                  data?.[column.cycle]?.end?.split("T")[0]
                                ).format("DD MMMM YYYY")}
                                dateLabel={column.cycleLabel}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                  ))}
            </Grid>
            <Grid item xs={3}>
              <Stepper
                orientation="vertical"
                className="ml-20"
                connector={<CustomConnector />}
              >
                {steps
                  .filter((step) => step !== "Master" || deliverable?.master)
                  .map((label, index) => (
                    <Step key={index} active={true}>
                      <StepLabel StepIconComponent={pendingCircleIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
              </Stepper>
            </Grid>
          </Grid>
        )}
      </div>
      <div>
        <div className="d-jsb-r mb-10">
          <Button
            variant="contained"
            style={{ borderRadius: "20px" }}
            onClick={handleClose}
            className={`mr-10 ${classes.button}`}
          >
            Cancel
          </Button>
          <Button
            className={`mr-10 ${classes.button}`}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              onRowChange(deliverable);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(ReScheduleDeliverable);
