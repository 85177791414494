import { handleActions } from "redux-actions";
import ActionTypes from "utils/constants/action-types";
import * as updaters from "redux/updaters/projects";

const actionHandler = {
  [ActionTypes.FETCH_PROJECTS]: updaters.fetchProjectsUpdater,
  [ActionTypes.ADD_PROJECT]: updaters.addProjectUpdater,
  [ActionTypes.EDIT_PROJECT]: updaters.editProjectUpdater,
  [ActionTypes.DELETE_PROJECT]: updaters.deleteProjectUpdater,
  [ActionTypes.FETCH_SYSTEM_TRENDS]: updaters.fetchSystemTrendsUpdater,
  [ActionTypes.CREATE_META_SYSTEM]: updaters.createMetaSystemUpdater,
  [ActionTypes.FETCH_META_SYSTEMS]: updaters.fetchMetaSystemsUpdater,
  [ActionTypes.FETCH_SYSTEMS_WORKFLOWS]: updaters.systemWorkflowUpdater,
  [ActionTypes.DELETE_META_SYSTEM]: updaters.deleteMetaSystemUpdater,
  [ActionTypes.UPDATE_META_SYSTEM]: updaters.updateMetaSystemUpdater,
  [ActionTypes.UPDATE_MULTIPLE_META_SYSTEMS]:
    updaters.updateMultipleMetaSystemUpdater,
  [ActionTypes.DUPLICATE_META_SYSTEM]: updaters.duplicateMetaSystemUpdater,
  [ActionTypes.UPDATE_DELIVERABLES]: updaters.updateDeliverablesUpdater,
  [ActionTypes.UPDATE_DELIVERABLE_NOTES]:
    updaters.updateDeliverableNotesUpdater,
  [ActionTypes.UPDATE_TASKS]: updaters.updateTasksUpdater,
  [ActionTypes.UPDATE_SUBTASK]: updaters.updateSubTaskUpdater,
  [ActionTypes.GET_INSIGHTS]: updaters.getInsights,
  [ActionTypes.GET_PHASES]: updaters.getPhases,
  [ActionTypes.GET_PHASE_DETAIL]: updaters.getPhaseDetail,
  [ActionTypes.GET_MULTI_PHASE_DETAIL]: updaters.getMultiPhaseDetail,
  [ActionTypes.FETCH_CUSTOM_NOTIFICATIONS]:
    updaters.fetchCustomNotificationsUpdater,
  [ActionTypes.DELETE_CUSTOM_NOTIFICATIONS]:
    updaters.deleteCustomNotificationsUpdater,
  [ActionTypes.CLEAR_CUSTOM_NOTIFICATIONS]:
    updaters.clearCustomNotificationsUpdater,
  [ActionTypes.UPDATE_ESCALATIONS]: updaters.updateEscalationUpdater,
  [ActionTypes.UPDATE_SUBESCALATION]: updaters.updateSubEscalationUpdater,
  [ActionTypes.SET_ACTIVE_PROJECT]: updaters.setActiveProject,
  [ActionTypes.SET_QMS_PROJECT]: updaters.qmsProjectUpdater,
};

const projectReducer = handleActions(actionHandler, updaters.INITIAL_STATE);

export default projectReducer;
