import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as Workflow } from "icons/workflow.svg";

const WorkflowTemplateList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'WorkflowTemplateList' */ "pages/WorkflowTemplates/WorkflowTemplateList"
    )
  )
);
const AddWorkflowTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddWorkflowTemplate' */ "pages/WorkflowTemplates/AddWorkflowTemplate"
    )
  )
);
const EditWorkflowTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditWorkflowTemplate' */ "pages/WorkflowTemplates/EditWorkflowTemplate"
    )
  )
);
const WorkflowTemplateHistory = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'WorkflowTemplateHistory' */ "pages/WorkflowTemplates/WorkflowTemplateHistory"
    )
  )
);
const WorkflowTemplateChart = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'WorkflowTemplateChart' */ "pages/WorkflowTemplates/WorkflowTemplateChart"
    )
  )
);

const workflowTemplateRoutes = Object.freeze({
  id: LINKS.WORKFLOW_TEMPLATES.TITLE,
  pathId: "workflow-template",
  path: LINKS.WORKFLOW_TEMPLATES.HREF,
  icon:<Workflow />,
  component: WorkflowTemplateList,
  roles:LINKS.WORKFLOW_TEMPLATES.ROLES,
  children: [
    {
      path: LINKS.WORKFLOW_TEMPLATES.HREF,
      name: LINKS.WORKFLOW_TEMPLATES.TITLE,
      component: WorkflowTemplateList,
    },
    {
      path: LINKS.ADD_WORKFLOW_TEMPLATE.HREF,
      name: LINKS.ADD_WORKFLOW_TEMPLATE.TITLE,
      component: AddWorkflowTemplate,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_WORKFLOW_TEMPLATE.HREF,
      name: LINKS.EDIT_WORKFLOW_TEMPLATE.TITLE,
      component: EditWorkflowTemplate,
      isNotSlide: true,
    },
    {
      path: LINKS.WORKFLOW_TEMPLATE_HISTORY.HREF,
      name: LINKS.WORKFLOW_TEMPLATE_HISTORY.TITLE,
      component: WorkflowTemplateHistory,
      isNotSlide: true,
    },
    {
      path: LINKS.WORKFLOW_TEMPLATE_CHART.HREF,
      name: LINKS.WORKFLOW_TEMPLATE_CHART.TITLE,
      component: WorkflowTemplateChart,
      isNotSlide: true,
    },
  ],
});

export default workflowTemplateRoutes;
