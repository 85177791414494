import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as RiskAssessment } from "icons/risk_assessment.svg";

const riskAsssesssment = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'RiskAsssesssment' */ "pages/RiskAssessment/RiskAssessmentList"
    )
  )
);

const addRiskAsssesssment = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'RiskAsssesssment' */ "pages/RiskAssessment/AddRiskAssessment")
  )
);

const editRiskAsssesssment = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'RiskAsssesssment' */ "pages/RiskAssessment/EditRiskAssessment")
  )
);

const riskAsssesssmentReport = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'RiskAsssesssment' */ "pages/RiskAssessment/RiskAssessmentReport")
  )
);

const riskAssessmentManagementRoutes = Object.freeze({
  id: LINKS.RISK_ASSESSMENT_MANAGEMENT.TITLE,
  path: LINKS.RISK_ASSESSMENT_MANAGEMENT.HREF,
  icon: <RiskAssessment />,
  component: null,
  roles: LINKS.RISK_ASSESSMENT_MANAGEMENT.ROLES,
  children: [
    {
      path: LINKS.RISK_ASSESSMENT.HREF,
      name: LINKS.RISK_ASSESSMENT.TITLE,
      component: riskAsssesssment,
    },
    {
      path: LINKS.ADD_RISK_ASSESSMENT.HREF,
      name: LINKS.ADD_RISK_ASSESSMENT.TITLE,
      component: addRiskAsssesssment,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_RISK_ASSESSMENT.HREF,
      name: LINKS.EDIT_RISK_ASSESSMENT.TITLE,
      component: editRiskAsssesssment,
      isNotSlide: true,
    },
    {
      path: LINKS.RISK_ASSESSMENT_REPORT.HREF,
      name: LINKS.RISK_ASSESSMENT_REPORT.TITLE,
      component: riskAsssesssmentReport,
    }
    
  ],
});

export default riskAssessmentManagementRoutes;
