import { getJQ, put, del, post, get } from "services/axios";

const getOrganizations = async (data) => await getJQ("organizations", data);

const createOrganization = async (params) => {
  return await post("organizations", params);
};

const getOrganizationById = async (organizationId) =>
  await get(`/v2/organization/${organizationId}/`);

const updateOrganization = async (params) => {
  return await put("organizations", params);
};
const updateOrganizationModulePreference = async (organizationId, params) => {
  return await put(
    `/v2/organization/${organizationId}/modulePreferences`,
    params
  );
};

const updateOrganizationHoliday = async (organizationId, params) => {
  return await put(`/v2/organization/${organizationId}/holidays`, params);
};

const deleteOrganization = async (params) => {
  return await del("organizations", params);
};

const createOrganizationDepartment = async (params) => {
  return await post(["organizations", "nested"], params);
};

const updateOrganizationDepartment = async (params) => {
  return await put(["organizations", "nested"], params);
};

const deleteOrganizationDepartment = async (params) => {
  return await del(["organizations", "nested"], params);
};

const getDepartments = async (organizationId) => {
  return await get(`/v2/organization/${organizationId}/departments`);
};
export {
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  createOrganizationDepartment,
  updateOrganizationDepartment,
  deleteOrganizationDepartment,
  getDepartments,
  getOrganizationById,
  updateOrganizationModulePreference,
  updateOrganizationHoliday,
};
