import moment from "moment";
import _ from "lodash";

export const keysToOmit = [
  "completion",
  "defaultApprover",
  "defaultDepartment",
  "defaultExecuted",
  "defaultResource",
  "defaultReviewer",
  "doc",
  "startEnd",
  "draftEnd",
  "ifrEnd",
  "reviewEnd",
  "ifaEnd",
  "approvalEnd",
  "executed",
  "resource",
  "notes",
  "approver",
  "reviewer",
  "masterDateEnd",
  "successors",
];

const dataKeys = [
  {
    key: "draftDuration",
    label: "Draft Duration",
    cycle: "draft",
    cycleLabel: "Draft",
  },
  {
    key: "ifrDuration",
    label: "IFR Duration",
    cycle: "ifr",
    cycleLabel: "IFR",
  },
  {
    key: "reviewDuration",
    label: "Review Duration",
    cycle: "review",
    cycleLabel: "Review",
  },
  {
    key: "ifaDuration",
    label: "IFA Duration",
    cycle: "ifa",
    cycleLabel: "IFA",
  },
  {
    key: "approvalDuration",
    label: "Approval Duration",
    cycle: "approval",
    cycleLabel: "Approval",
  },
  {
    key: "masterDuration",
    label: "Master Duration",
    cycle: "masterDate",
    cycleLabel: "Master",
  },
  {
    key: "duration",
    label: "Total Duration",
    cycle: "deliverable",
    cycleLabel: "",
  },
];

export const formatDeliverableWithSuccessor = (deliverables) => {
  return deliverables.map((del) => {
    del.successors = [];
    deliverables.forEach((del2) => {
      if (
        del2.predecessors
          .map((pred) => pred.toString())
          .includes(del._id.toString())
      ) {
        del.successors.push(del2._id.toString());
      }
    });
    return del;
  });
};

export const autoScheduleDeliverables = (deliverables) => {
  let deliverablesData = _.cloneDeep(deliverables);
  deliverablesData = formatDeliverableWithSuccessor(deliverablesData);
  const unModifiedData = _.cloneDeep(deliverablesData);

  const initialDeliverables = deliverablesData.filter(
    (deliverable) => deliverable.predecessors.length === 0
  );

  initialDeliverables.forEach((deliverable) => {
    deliverable.start = moment().format("YYYY-MM-DDT00:00:00.000[Z]");
    deliverable.end = moment(deliverable.start)
      .add(deliverable.duration, "days")
      .format("YYYY-MM-DDT00:00:00.000[Z]");

    updateSuccessors(
      deliverablesData,
      deliverable.successors,
      deliverable.end,
      unModifiedData
    );
  });

  autoScheduleSubTask(deliverablesData, null, true);

  return deliverablesData;
};

export const autoScheduleSubTask = (
  deliverablesData,
  adjustedDel = null,
  UpdateSuccessor
) => {
  const unModifiedData = _.cloneDeep(deliverablesData);

  for (const deliverable of deliverablesData) {
    if (adjustedDel?._id.toString() !== deliverable?._id.toString()) {
      const unModifiedDeliverable = unModifiedData.find(
        (del) => del._id.toString() === deliverable._id.toString()
      );

      const formatDate = (date) =>
        moment.utc(date).format("YYYY-MM-DDT00:00:00.000[Z]");
      const addDays = (date, days) =>
        moment.utc(date).add(days, "days").format("YYYY-MM-DDT00:00:00.000[Z]");

      const schedulePhase = (phase, duration, previousEndDate, gap) => {
        if (gap > 0) {
          previousEndDate = moment
            .utc(previousEndDate)
            .add(gap, "days")
            .format("YYYY-MM-DDT00:00:00.000[Z]");
        }
        phase.start = formatDate(previousEndDate);

        phase.end = addDays(previousEndDate, duration);
      };

      for (let i = 0; i < dataKeys.length; i++) {
        if (dataKeys[i].cycle !== "deliverable") {
          if (dataKeys[i].cycle === "draft") {
            schedulePhase(
              deliverable[dataKeys[i].cycle],
              deliverable[dataKeys[i].key],
              deliverable.start
            );
          } else if (dataKeys[i].cycle !== "masterDate" || deliverable.master) {
            let gap = 0;
            if (deliverable.master && !deliverable.masterDate) {
              if (!deliverable.master.masterDate) {
                deliverable.masterDate = {
                  start: "",
                  end: "",
                };
              }
            }
            if (deliverable.scheduled) {
              const start = unModifiedDeliverable[dataKeys[i].cycle]?.start;
              const end = unModifiedDeliverable[dataKeys[i - 1].cycle]?.end;
              if (start && end) {
                gap = moment.utc(start).diff(end, "days");
              }
            }
            schedulePhase(
              deliverable[dataKeys[i].cycle],
              deliverable[dataKeys[i].key],
              deliverable[dataKeys[i - 1].cycle].end,
              gap
            );
          }
        }
      }
      deliverable.end = deliverable.master
        ? deliverable?.masterDate?.end
        : deliverable?.approval?.end;
    }
  }
  if (UpdateSuccessor) {
    const initialDeliverables = deliverablesData.filter(
      (deliverable) => deliverable.predecessors.length === 0
    );

    initialDeliverables.forEach((deliverable) => {
      updateSuccessors(
        deliverablesData,
        deliverable.successors,
        deliverable.end,
        unModifiedData
      );
    });
  }
};

export const handleDelDurationChange = (autoScheduledDel, duration, index) => {
  let delData = _.cloneDeep(autoScheduledDel);
  delData[index].duration = duration;
  setInitialDuration(delData[index]);

  const deliverablesUpdated = formatDeliverableWithSuccessor(delData);
  const updatedDel = deliverablesUpdated[index];

  if (updatedDel.predecessors.length === 0) {
    updatedDel.start = moment().format("YYYY-MM-DDT00:00:00.000[Z]");
  }
  updatedDel.end = moment(updatedDel.start)
    .add(duration, "days")
    .format("YYYY-MM-DDT00:00:00.000[Z]");

  updateSuccessors(deliverablesUpdated, updatedDel.successors, updatedDel.end);

  return deliverablesUpdated;
};

const setInitialDuration = (updatedData) => {
  dataKeys
    .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
    .forEach((val) => {
      if (val.key !== "duration") {
        if (updatedData?.duration >= 5) {
          updatedData[val.key] = 1;
          if (
            (val.key === "approvalDuration" && !updatedData?.master) ||
            val.key === "masterDuration"
          ) {
            updatedData[val.key] =
              updatedData?.duration -
              (updatedData?.master ? Number(5) : Number(4));
          }
        } else {
          updatedData[val.key] = 0;
          if (
            (val.key === "approvalDuration" && !updatedData?.master) ||
            val.key === "masterDuration"
          ) {
            updatedData[val.key] = updatedData?.duration;
          }
        }
      }
    });
  setInitialDates(updatedData);
};

const setInitialDates = (updatedData) => {
  dataKeys
    .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
    .forEach((val, index) => {
      if (val.key !== "duration") {
        if (val.cycle === "draft") {
          updatedData[val.cycle] = {
            ...updatedData[val.cycle],
            start: updatedData?.start,
          };
          const endDate = moment(
            updatedData?.[val.cycle]?.["start"]?.split("T")[0]
          )
            .add("days", updatedData[val.key])
            .format("YYYY-MM-DDT00:00:00.000[Z]");
          updatedData[val.cycle] = {
            ...updatedData[val.cycle],
            end: endDate,
          };
        } else {
          updatedData[val.cycle] = {
            ...updatedData[val.cycle],
            start: updatedData?.[dataKeys[index - 1]?.cycle]?.end,
          };
          const endDate = moment(
            updatedData?.[val.cycle]?.["start"]?.split("T")[0]
          )
            .add("days", updatedData[val.key])
            .format("YYYY-MM-DDT00:00:00.000[Z]");
          updatedData[val.cycle] = {
            ...updatedData[val.cycle],
            end: endDate,
          };
        }
      } else {
        const endDate = moment(updatedData?.start?.split("T")[0])
          .add("days", Number(updatedData.duration))
          .format("YYYY-MM-DDT00:00:00.000[Z]");
        updatedData = {
          ...updatedData,
          end: endDate,
        };
      }
    });
};

export const handleChangedDate = (
  autoScheduledDel,
  startDate,
  endDate,
  index,
  currentDelUpdate = true,
  unModifiedData,
  isDeliverable
) => {
  let delData = _.cloneDeep(autoScheduledDel);

  if (isDeliverable) {
    const newDuration = moment(
      moment(endDate).format("YYYY-MM-DDT00:00:00.000[Z]")
    ).diff(moment(startDate).format("YYYY-MM-DDT00:00:00.000[Z]"), "days");
    if (delData[index].duration !== newDuration) {
      delData[index].duration = newDuration;
      setInitialDuration(delData[index]);
    }
  }

  const deliverablesUpdated = formatDeliverableWithSuccessor(delData);
  const updatedDel = deliverablesUpdated[index];

  if (currentDelUpdate) {
    updatedDel.start = moment
      .utc(startDate)
      .format("YYYY-MM-DDT00:00:00.000[Z]");
    updatedDel.end = moment.utc(endDate).format("YYYY-MM-DDT00:00:00.000[Z]");
    updatedDel.duration = moment
      .utc(updatedDel.end)
      .diff(updatedDel.start, "days");
  }

  updateSuccessors(
    deliverablesUpdated,
    updatedDel.successors,
    updatedDel.end,
    unModifiedData
  );

  if (!currentDelUpdate) {
    autoScheduleSubTask(deliverablesUpdated, updatedDel);
  } else {
    autoScheduleSubTask(deliverablesUpdated);
  }

  return deliverablesUpdated;
};

export const updateSuccessors = (
  deliverables,
  successorIds,
  predecessorEndDate,
  unModifiedData
) => {
  successorIds.forEach((successorId) => {
    let gap = 0;
    const successor = deliverables.find(
      (deliverable) => deliverable._id === successorId
    );
    if (successor) {
      if (unModifiedData) {
        const unmodifiedPredecessor = unModifiedData.find((deliverable) =>
          deliverable.successors.includes(successor._id)
        );
        const unmodifiedSuccessor = unModifiedData.find(
          (deliverable) => deliverable._id === successor._id
        );

        if (successor.scheduled) {
          const start = unmodifiedSuccessor?.start;
          const end = unmodifiedPredecessor?.end;

          if (start && end) {
            gap = moment.utc(start).diff(end, "days");
          }
        }
      }

      let maxPredecessorEndDate = deliverables
        .filter((del) => successor.predecessors.includes(del._id))
        .reduce((maxDate, del) => {
          const endDate = moment.utc(del.end);
          return endDate.isAfter(maxDate) ? endDate : maxDate;
        }, moment.utc(predecessorEndDate));

      if (gap > 0) {
        maxPredecessorEndDate = moment
          .utc(maxPredecessorEndDate)
          .add(gap, "days")
          .format("YYYY-MM-DDT00:00:00.000[Z]");
      }

      const newStartDate = moment
        .utc(maxPredecessorEndDate)
        .format("YYYY-MM-DDT00:00:00.000[Z]");
      const newEndDate = moment
        .utc(newStartDate)
        .add(successor.duration, "days")
        .format("YYYY-MM-DDT00:00:00.000[Z]");

      successor.start = newStartDate;
      successor.end = newEndDate;

      if (successor?.successors?.length > 0) {
        updateSuccessors(
          deliverables,
          successor.successors,
          successor.end,
          unModifiedData
        );
      }
    }
  });
};

export const compareDates = (obj1, obj2) => {
  for (const item of dataKeys) {
    if (
      item.cycle !== "deliverable" &&
      (item.cycle !== "masterDate" || obj1.master)
    ) {
      if (
        obj1[item.cycle].start !== obj2[item.cycle].start ||
        obj1[item.cycle].end !== obj2[item.cycle].end
      ) {
        return false;
      }
    } else {
      if (obj1.start !== obj2.start || obj1.end !== obj2.end) {
        return false;
      }
    }
  }

  return true;
};
