import { lazy } from "react";
import lazyRetry from "utils/helpers/lazyRetry";
import LINKS from "../links";

const EditProfile = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'MaterialIcons' */ "pages/Profile/EditProfile")
  )
);

const profileEditRoute = Object.freeze({
  id: LINKS.EDIT_PROFILE.TITLE,
  path: LINKS.EDIT_PROFILE.HREF,
  header: "Edit profile",
  icon: null,
  containsHome: true,
  component: EditProfile,
  children: null,
});

export default profileEditRoute;
