import { getAnnotations } from "services/api-document";

export function convertAnnotations(annotation) {
  const tags = ["add", "modify", "delete"];

  // Replace opening and closing tags
  tags.forEach((tag) => {
    annotation = annotation.replace(new RegExp(`<${tag}>`, "g"), "<annots>");
    annotation = annotation.replace(new RegExp(`</${tag}>`, "g"), "</annots>");
  });

  // Replace self-closing tags
  const emptyTags = ["<add />", "<modify />", "<delete />"];
  emptyTags.forEach((emptyTag) => {
    annotation = annotation.replace(new RegExp(emptyTag, "g"), "");
  });

  return annotation;
}

const loadAnnotation = async (
  documentId,
  documentHistoryId,
  instance,
  PSPDFKit
) => {
  const { data } = await getAnnotations(documentId, documentHistoryId);
  if (data.length) {
    const annotations = data.reduce(
      (acc, currentObj) => {
        if (currentObj.type === "JSON") {
          acc.json.push(currentObj.annotation);
        } else {
          acc.xfdf.push({
            type: "applyXfdf",
            xfdf: convertAnnotations(currentObj.annotation),
          });
        }
        return acc;
      },
      { json: [], xfdf: [] }
    );

    instance.applyOperations([
      {
        type: "applyInstantJson",
        instantJson: {
          annotations: annotations.json,
          format: "https://pspdfkit.com/instant-json/v1",
        },
      },
      ...annotations.xfdf,
    ]);
  }
};

const annotationEvents = (
  instance,
  PSPDFKit,
  documentId,
  documentHistoryId,
  userId,
  socket
) => {
  instance.addEventListener("annotations.create", (event) => {
    const createdAnnotation = event.toArray()[0];
    if (!createdAnnotation?.customData?.preventTrigger) {
      const serializedAnnotation =
        PSPDFKit.Annotations.toSerializableObject(createdAnnotation);
      socket?.emit("add_annotation", {
        documentId,
        documentHistoryId,
        type: "JSON",
        annotation: serializedAnnotation,
        annotationId: serializedAnnotation.name,
        userId,
      });
    }
  });
  instance.addEventListener("annotations.update", (updatedAnnotations) => {
    const updatedAnnotation = updatedAnnotations.toArray()[0];

    if (!updatedAnnotation?.customData?.preventTrigger) {
      const serializedAnnotation =
        PSPDFKit.Annotations.toSerializableObject(updatedAnnotation);
      socket?.emit("update_annotation", {
        documentId,
        documentHistoryId,
        type: "JSON",
        annotation: serializedAnnotation,
        annotationId: serializedAnnotation.name,
        userId,
      });
    }
  });
  instance.addEventListener("annotations.delete", (deletedAnnotations) => {
    const deletedAnnotation = deletedAnnotations.toArray()[0];
    const serializedAnnotation =
      PSPDFKit.Annotations.toSerializableObject(deletedAnnotation);
    console.log(serializedAnnotation);
    socket?.emit("delete_annotation", {
      documentId,
      documentHistoryId,
      annotation: serializedAnnotation,
      annotationId: serializedAnnotation.name,
      userId,
    });
  });
};

const annotationSubscriber = (
  instance,
  documentHistoryId,
  socket,
  PSPDFKit
) => {
  socket?.on(`add_annotation_${documentHistoryId}`, async (annotation) => {
    const deserializedAnnotation = PSPDFKit.Annotations.fromSerializableObject(
      annotation.annotation
    );
    const change = deserializedAnnotation
      .set("id", annotation.annotation_id)
      .set("customData", { preventTrigger: true });
    await instance.create(change);
  });
  socket?.on(`delete_annotation_${documentHistoryId}`, async (annotation) => {
    try {
      await instance.delete([annotation.annotation_id]);
    } catch (error) {
      console.log("Annotation doesn't exist.");
    }
  });
  socket?.on(`update_annotation_${documentHistoryId}`, async (annotation) => {
    const deserializedAnnotation = PSPDFKit.Annotations.fromSerializableObject(
      annotation.annotation
    );

    const change = deserializedAnnotation
      .set("id", annotation.annotation_id)
      .set("customData", { preventTrigger: true });

    await instance.update(change);
  });
};

export const toolbarOptions = (instance, type) => {
  const viewTools = [
    "pager",
    "pan",
    "zoom-out",
    "zoom-in",
    "zoom-mode",
    "spacer",
    "search",
    "sidebar-annotations",
    "sidebar-thumbnails",
    "sidebar-document-outline",
    "export-pdf",
  ];
  const annotateTools = [
    "pager",
    "pan",
    "zoom-out",
    "zoom-in",
    "zoom-mode",
    "spacer",
    "ink",
    "highlighter",
    "text-highlighter",
    "ink-eraser",
    "note",
    "text",
    "callout",
    "line",
    "arrow",
    "rectangle",
    "ellipse",
    "polygon",
    "cloudy-polygon",
    "polyline",
    "search",
    "sidebar-annotations",
    "sidebar-thumbnails",
    "sidebar-document-outline",
    "export-pdf",
  ];
  const items = [];
  const toolIDs =
    type === "annotate"
      ? annotateTools
      : type === "author"
      ? viewTools
      : (viewTools.shift(), viewTools);
  toolIDs.map((tool) => {
    const data = { type: tool };
    items.push(data);
    return items;
  });
  instance.setToolbarItems(items);
};

export const setSideBar = (instance, PSPDFKit) => {
  instance.setViewState((v) =>
    v.set("sidebarPlacement", PSPDFKit.SidebarPlacement.END)
  );
};

// const downloadButton = {
//   type: "custom",
//   id: "download-pdf",
//   icon: "/download.svg",
//   title: "Download",
//   onPress: () => {
//     pspdfkitInstance.exportPDF().then((buffer) => {
//       const blob = new Blob([buffer], { type: "application/pdf" });
//       const fileName = "document.pdf";
//       if (window.navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveOrOpenBlob(blob, fileName);
//       } else {
//         const objectUrl = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = objectUrl;
//         a.style = "display: none";
//         a.download = fileName;
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(objectUrl);
//         document.body.removeChild(a);
//       }
//     });
//   }
// };

export const initViewer = (
  instance,
  PSPDFKit,
  currentUserRole,
  documentData,
  documentId,
  documentHistoryId,
  socket,
  annotationAllowed
) => {
  const userId = JSON.parse(localStorage.getItem("currentUser"))?._id;
  const userName = JSON.parse(localStorage.getItem("currentUser"))?.name;

  instance.setAnnotationCreatorName(userName);
  if (
    annotationAllowed &&
    ["REVIEW_PENDING"].includes(documentData?.documentStatus)
  ) {
    toolbarOptions(instance, "annotate");
  } else {
    toolbarOptions(instance, "author");
  }
  setSideBar(instance, PSPDFKit);
  loadAnnotation(documentId, documentHistoryId, instance, PSPDFKit);
  annotationEvents(
    instance,
    PSPDFKit,
    documentId,
    documentHistoryId,
    userId,
    socket
  );
  annotationSubscriber(instance, documentHistoryId, socket, PSPDFKit);
};
