import { useMemo, useState } from "react";
import { SORT_DIRS } from "utils/constants";
import { get } from "lodash";

const createDefaultSorter = (column, dir) => (a, b) => {
  if (!column) {
    return 1;
  }

  const val1 = get(a, column.split("."));
  const val2 = get(b, column.split("."));

  const v1 = val1 || "#";
  const v2 = val2 || "#";

  if (typeof val1 !== "string" && typeof val2 !== "string") {
    if (dir === SORT_DIRS.asc) {
      return val1 - val2;
    } else {
      return val2 - val1;
    }
  }

  if (v1 > v2) {
    return dir === SORT_DIRS.asc ? 1 : -1;
  } else if (v1 === v2) {
    return 0;
  } else {
    return dir === SORT_DIRS.asc ? -1 : 1;
  }
};

const useTableSort = (rows = [], sorter = createDefaultSorter) => {
  const [sortCol, setSortColumn] = useState(null);
  const [sortDir, setSortDir] = useState(SORT_DIRS.none);

  const handleSort = (col, dir) => {
    setSortColumn(col);
    setSortDir(dir);
  };

  const sortedRows = useMemo(
    () => rows.sort(sorter(sortCol, sortDir)),
    // eslint-disable-next-line
    [sortCol, sortDir, rows]
  );

  return { sortedRows, handleSort, sortCol, sortDir };
};

export default useTableSort;
