import React, { memo, useEffect, useState } from "react";
import ModulePreferenceForm from "../Shared/ModulePreferenceForm";
import PageHeader from "parts/PageHeader";
import LINKS from "utils/constants/links";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getOrganizationById,
  updateOrganizationModulePreference,
} from "services/api-organization";
import Loader from "components/Loader";
import { setPopup } from "redux/actions/popupActions";
import { POPUP_TYPE } from "utils/constants";

function ModulePreference() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [organizationDetail, setOrganizationDetail] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const loadOrganizationDetail = async (id) => {
    setLoading(true);
    try {
      const resp = await getOrganizationById(id);
      console.log(resp);
      setOrganizationDetail(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error >>>>> ", error);
    }
  };
  const updatePreference = async (preference) => {
    setLoading(true);
    try {
      const resp = await updateOrganizationModulePreference(id, preference);
      setOrganizationDetail(resp.data);
      setLoading(false);
      history.goBack();
    } catch (error) {
      setLoading(false);
      dispatch(
        setPopup({
          popupType: POPUP_TYPE.error,
          popupText:
            error.response?.data?.info || "Failed to update preferences.",
        })
      );
      console.log("error >>>>> ", error);
    }
  };

  useEffect(() => {
    loadOrganizationDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const NAV_LINKS = [
    LINKS.USER_MANAGEMENT,
    LINKS.ORGANIZATIONS,
    {
      HREF: LINKS.EDIT_ORGANIZATION.HREF.replace(":id", organizationDetail._id),
      TITLE: organizationDetail?.name || "Not Found",
    },
  ];
  return (
    <>
      <PageHeader
        title={LINKS.ORGANIZATION_MODULE_PREFERENCES.TITLE}
        links={NAV_LINKS}
      />
      <ModulePreferenceForm
        mode={"ORG"}
        preferencesData={organizationDetail?.modulePreference}
        updatePreference={updatePreference}
      />
      {loading && <Loader />}
    </>
  );
}

export default memo(ModulePreference);
