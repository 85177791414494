import TYPES from "utils/constants/action-types";
import { POPUP_TYPE } from "utils/constants";

const initialState = {
  popupType: POPUP_TYPE.inactive,
  popupText: "",
};

export default function customPopupReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.SET_CUSTOM_POPUP:
      const {
        popupType,
        popupText,
        confirmText,
        cancelText,
        onCancel,
        onConfirm,
        cancelColor,
        confirmColor,
        showCloseIcon,
        popupHeader,
        icon,
      } = action.payload;
      return {
        ...state,
        popupType,
        popupText,
        onConfirm,
        onCancel,
        confirmText,
        cancelText,
        cancelColor,
        confirmColor,
        showCloseIcon,
        popupHeader,
        icon,
      };
    case TYPES.SET_CUSTOM_POPUP_TEXT:
      return {
        ...state,
        popupText: action.payload,
      };
    default:
      return state;
  }
}
