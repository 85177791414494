import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { ReactComponent as pendingCircleIcon } from "icons/pending_circle_filled.svg";

const CustomConnector = withStyles({
  line: {
    height: 35,
    marginTop: 5,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
    color: "black",
    marginTop: "5px",
    marginBottom: "10px",
  },
  dateStyle: {
    fontSize: "17px",
    fontWeight: "30",
    marginBottom: "5px",
    marginTop: "5px",
  },
  heading: { fontSize: "16px" },
  hourCircle: {
    backgroundColor: "#d3e7e9",
    color: "black",
    height: "2.25rem",
    width: "2.25rem",
    fontSize: "0.875rem",
    borderRadius: "2.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  withoutCircle: {
    color: "black",
    fontSize: "0.875rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dateSpacing: {
    margin: "0 10px",
  },
  tittle: {
    fontSize: "25px",
    fontWeight: "20",
  },
  updateStyle: {
    fontSize: "12px",
  },
}));

const dataKeys = [
  {
    cycle: "draft",
    cycleLabel: "Draft",
  },
  {
    cycle: "ifr",
    cycleLabel: "IFR",
  },
  {
    cycle: "review",
    cycleLabel: "Review",
  },
  {
    cycle: "ifa",
    cycleLabel: "IFA",
  },
  {
    cycle: "approval",
    cycleLabel: "Approval",
  },
  {
    cycle: "masterDate",
    cycleLabel: "Master",
  },
];

const ScheduleHistoryDialog = ({
  data,
  openScheduleHistory,
  handleCloseScheduleHistory,
  type,
}) => {
  const classes = useStyles();

  const historyData = cloneDeep(data)?.reverse();
  const [dataIndex, setDataIndex] = useState(null);
  const users = useSelector(({ users }) => users.results);

  const getUserName = (userId) => {
    const userName = users?.find((user) => user?._id === userId)?.name;
    return userName;
  };

  return (
    <Dialog
      open={openScheduleHistory}
      onClose={handleCloseScheduleHistory}
      fullWidth
      className="pd-10"
      maxWidth={"xs"}
    >
      <DialogTitle className="ml-10">
        <div className="d-flex align-items-center">
          {dataIndex !== null && (
            <IconButton
              onClick={() => {
                setDataIndex(null);
              }}
            >
              <div className={classes.hourCircle}>{"<"}</div>
            </IconButton>
          )}
          <Typography
            className={`${classes.tittle} ${dataIndex === null ? "ml-10" : ""}`}
          >
            {" "}
            Schedule History
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container columns={12} spacing={2}>
          {historyData?.map(
            (item, index) =>
              (dataIndex === index || dataIndex === null) && (
                <>
                  <Grid item xs={2} alignContent="center">
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {type === "DOCUMENTATION" && (
                        <IconButton
                          onClick={() => {
                            setDataIndex(index);
                          }}
                        >
                          <div className={classes.hourCircle}>{index + 1}</div>
                        </IconButton>
                      )}
                      {type !== "DOCUMENTATION" && (
                        <div className={classes.withoutCircle}>{index + 1}</div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes?.heading}>Current Start Date</div>
                    <div className={classes?.dateStyle}>
                      {moment(item?.start).format("D-MMMM-YYYY")}
                    </div>
                    <div className={classes.updateStyle}>
                      Updated: {moment(item?.createdAt).format("D-MMMM-YYYY")}
                    </div>
                    <div className={classes.updateStyle}>
                      By: {getUserName(item?.user)}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes?.heading}>Current End Date</div>
                    <div className={classes?.dateStyle}>
                      {moment(item?.end).format("D-MMMM-YYYY")}
                    </div>
                  </Grid>
                </>
              )
          )}
        </Grid>
        {dataIndex !== null &&
          type === "DOCUMENTATION" &&
          historyData[dataIndex]?.draft && (
            <Grid container columns={12} spacing={2}>
              <Grid item xs={2} className="mt-5">
                <Stepper
                  orientation="vertical"
                  className="ml-5"
                  connector={<CustomConnector />}
                >
                  {dataKeys
                    .filter(
                      (step, index) =>
                        step.cycle !== "masterDate" ||
                        historyData[dataIndex]?.masterDate
                    )
                    .map((label, index) => (
                      <Step key={index} active={true}>
                        <StepLabel
                          StepIconComponent={pendingCircleIcon}
                        ></StepLabel>
                      </Step>
                    ))}
                </Stepper>
              </Grid>

              <Grid item xs={10} className="mt-10">
                {dataKeys
                  .filter(
                    (step) =>
                      step?.cycle !== "masterDate" ||
                      historyData[dataIndex]?.masterDate
                  )
                  .map((label, index) => (
                    <div className="mt-20">
                      <div className={classes?.heading}>
                        {label?.cycleLabel}
                      </div>
                      <div className={classes?.dateStyle}>
                        <span>
                          {moment(
                            historyData[dataIndex]?.[label.cycle]?.start
                          ).format("D-MMMM-YYYY")}
                        </span>
                        <span className={classes.dateSpacing}>-</span>
                        <span>
                          {moment(
                            historyData[dataIndex]?.[label.cycle]?.end
                          ).format("D-MMMM-YYYY")}
                        </span>
                      </div>
                    </div>
                  ))}
              </Grid>
            </Grid>
          )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleCloseScheduleHistory();
            setDataIndex(null);
          }}
          className={`${classes.button} mr-10`}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleHistoryDialog;
