export const INITIAL_STATE = Object.freeze({
  results: [],
  next: false,
  newCount: 0,
});

export const fetchNotifications = (state, { payload }) => ({
  ...state,
  results: [...state.results, ...payload.notifications],
  next: payload.next,
  newCount: payload.countData.unread_count,
});

export const addNotification = (state, { payload }) => {
  return {
    ...state,
    results: [payload, ...state.results],
    newCount: state.newCount + 1,
  };
};

export const updateNotification = (state, { payload }) => {
  let notifications = [...state.results];
  if (payload.type === "read-one") {
    notifications = notifications.map((notification) => {
      return {
        ...notification,
        read: payload.id === notification._id ? true : notification.read,
      };
    });
  } else {
    notifications = notifications.map((notification) => {
      return {
        ...notification,
        read: true,
      };
    });
  }
  return {
    ...state,
    results: [...notifications],
    newCount:
      payload.type === "read-one"
        ? state.newCount === 0
          ? 0
          : state.newCount - 1
        : 0,
  };
};
