import TYPES from 'utils/constants/action-types';

const initialState = {
  socket: null,
};

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.INITIALIZE_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    case TYPES.DISCONNECT_SOCKET:
      return {
        ...state,
        socket: null,
      };
    default:
      return state;
  }
}
