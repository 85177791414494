import moment from "moment";

export const getHolidayAdjustedEndDate = (
  start,
  duration,
  organizationHoliday = []
) => {
  let startDate = moment(start);
  let endDate = moment(start).add(duration, "days");
  let dates = [];
  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.format("YYYY-MM-DD"));
    startDate.add(1, "days");
  }
  let holidayCount = 0;
  let valueCount = 0;
  let dateCount = 0;
  while (valueCount < dates.length) {
    if (organizationHoliday.includes(dates[dateCount])) {
      dates.push(
        moment(dates[dates.length - 1])
          .add(1, "day")
          .format("YYYY-MM-DD")
      );
      dateCount++;
    } else {
      valueCount++;
      dateCount++;
    }
  }
  dates.forEach((date) => {
    if (organizationHoliday.includes(date)) {
      holidayCount++;
    }
  });

  return moment(start)
    .add(duration + holidayCount, "days")
    .format("YYYY-MM-DDT00:00:00.000Z");
};

export const customDayContent = (day, expectedDateRange) => {
  let expected = null;
  if (
    moment(day).isBetween(
      expectedDateRange?.startDate,
      expectedDateRange?.endDate,
      "days",
      "(]"
    )
  ) {
    expected = (
      <div
        style={{
          border: "solid 1px cyan",
          borderRadius: "25%",
          height: "24px",
          width: "32px",
          position: "absolute",
          top: 1,
          right: 5,
        }}
      />
    );
  }
  return (
    <div>
      {expected}
      <span>{moment(day).format("D")}</span>
    </div>
  );
};
