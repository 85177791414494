import moment from "moment";

let today = new Date();
today = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

export const getFormattedDate = (date) => {
  return moment(date?.split("T")[0])?.format("YYYY-MM-DD[T00:00:00.000Z]");
};

export const colorIFRA = (
  completion,
  end,
  status,
  type = "",
  isWorkflowCall = false
) => {
  if (isWorkflowCall) {
    return "#bdbdbd";
  }

  if (!completion) {
    if (status) {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return type !== "" ? "#bdbdbd" : "#5E5E5E";
      } else {
        return "#D32F2F";
      }
    }
  } else {
    if (status) {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#5A83BB";
      } else {
        return "#D32F2F";
      }
    }
  }
};

export const colorRA = (
  completion,
  end,
  status,
  type = "",
  isWorkflowCall = false
) => {
  if (isWorkflowCall) {
    return "#bdbdbd";
  }

  if (!completion) {
    if (parseInt(status || 0) === 0) {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return type !== "" ? "#bdbdbd" : "#5E5E5E";
      } else {
        return "#D32F2F";
      }
    } else if (parseInt(status || 0) > 0 && parseInt(status || 0) < 100) {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return "#5A83BB";
      } else {
        return "#D32F2F";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(today)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    }
  } else {
    if (parseInt(status || 0) >= 0 && parseInt(status || 0) < 100) {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#5A83BB";
      } else {
        return "#D32F2F";
      }
    } else {
      if (getFormattedDate(end) >= getFormattedDate(completion)) {
        return "#27AE60";
      } else {
        return "#F39C12";
      }
    }
  }
};

export const SYSTEM_STATUS_TYPES = [
  {
    LABEL: "Not Started",
    VALUE: "Not Started",
  },
  {
    LABEL: "In Progress",
    VALUE: "In Progress",
  },

  {
    LABEL: "Completed",
    VALUE: "Completed",
  },
];
