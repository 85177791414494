import { handleActions } from "redux-actions";
import ActionTypes from "utils/constants/action-types";
import * as updaters from "redux/updaters/notifications";

const actionHandler = {
  [ActionTypes.FETCH_NOTIFICATION]: updaters.fetchNotifications,
  [ActionTypes.ADD_NOTIFICATION]: updaters.addNotification,
  [ActionTypes.UPDATE_NOTIFICATION]: updaters.updateNotification,
};

const notificationssReducer = handleActions(
  actionHandler,
  updaters.INITIAL_STATE
);

export default notificationssReducer;
