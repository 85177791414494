import TYPES from "utils/constants/action-types";
import * as API from "services/api-user";

export const setModulePreference = (accessibleFeature) => (dispatch) => {
  dispatch({
    type: TYPES.SET_MODULE_PREFERENCE,
    payload: accessibleFeature,
  });
};

const getModulePreferences =
  (id, projectId = null) =>
  async (dispatch) => {
    if (id) {
      await API.getAccessibleFeatures(id, projectId)
        .then((response) => {
          dispatch(setModulePreference(response.accessibleFeature));
          localStorage.setItem("accessibleFeature", response.accessibleFeature);
          if (!projectId) {
            localStorage.setItem(
              "orgAccessibleFeature",
              response.accessibleFeature
            );
          }
          const oldBuildNumber = parseInt(
            localStorage.getItem("buildNumber") || 0
          );
          if (oldBuildNumber < response.buildNumber) {
            console.log("Clearing Caches");
            if ("caches" in window) {
              caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                  caches.delete(name);
                });
                localStorage.setItem("buildNumber", response.buildNumber);

                // window.location.reload();
              });
            }
          }
        })
        .catch((err) => {
          console.log("[editProject] error => ", err);
        });
    }
  };

export { getModulePreferences };
