import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { PSPDFKIT_LICENSE_KEY, FE_BASE_URL } from "config";
import { memo, useEffect, useRef, useCallback } from "react";
import { signUrl } from "services/api-general";
import { decryptData } from "utils/helpers/utility";
import { setSideBar, toolbarOptions } from "./helper";

const DocumentViewer = ({ handleClose, open, fileData, localFile = false }) => {
  const containerRef = useRef(null);
  const PSPDFKitRef = useRef(null);

  const loadDocument = useCallback(async (container, fileUrl) => {
    console.log("loadDocument called");
    if (!container) {
      console.error("Container is not defined");
      return;
    }

    let PSPDFKit;
    try {
      PSPDFKit = await import("pspdfkit");
      PSPDFKitRef.current = PSPDFKit;
      console.log("PSPDFKit loaded");

      await PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      if (fileUrl) {
        const instance = await PSPDFKit.load({
          container,
          disableWebAssemblyStreaming: true,
          document: fileUrl,
          licenseKey: PSPDFKIT_LICENSE_KEY,
          baseUrl: FE_BASE_URL,
        });
        console.log("Document loaded");
        toolbarOptions(instance, "view");
        setSideBar(instance, PSPDFKit);
        return instance;
      }
    } catch (error) {
      console.error("Failed to load document:", error);
    }
  }, []);

  useEffect(() => {
    let instance;
    const fetchAndLoadDocument = async () => {
      if (fileData) {
        try {
          let url = await fileData?.fileUrl;
          if(!localFile) {
            const signedUrl = await signUrl({ fileUrl: url });
            url = await decryptData(signedUrl?.encryptedUrl);
          }
          const loadedInstance = await loadDocument(containerRef.current, url);
          instance = loadedInstance;
          console.log("Document instance loaded:", instance);
        } catch (error) {
          console.error("Error loading document instance:", error);
        }
      }
    };

    fetchAndLoadDocument();
    const container = containerRef.current;
    return () => {
      const PSPDFKit = PSPDFKitRef.current;
      if (instance && PSPDFKit && !open) {
        PSPDFKit.unload(container)
          .then(() => console.log("PSPDFKit instance unloaded"))
          .catch((error) =>
            console.error("Failed to unload PSPDFKit instance:", error)
          );
      }
    };
  }, [fileData, loadDocument, localFile, open]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <div className="d-jsb-c">
          <div>File View</div>
          <IconButton className="pd-0" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </div>
      </DialogTitle>
      <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
      {/* {loading && <Loader />} */}
    </Dialog>
  );
};

export default memo(DocumentViewer);
