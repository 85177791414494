import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import moment from "moment";
import DateRangePicker from "components/DateRangePicker";
import { Eye } from "react-feather";
import { autoScheduleSubTask } from "pages/WorkflowTemplates/EditWorkflowTemplate/helper";

const dataKeys = [
  {
    key: "draftDuration",
    label: "Draft Duration",
    cycle: "draft",
    cycleLabel: "Draft",
  },
  {
    key: "ifrDuration",
    label: "IFR Duration",
    cycle: "ifr",
    cycleLabel: "IFR",
  },
  {
    key: "reviewDuration",
    label: "Review Duration",
    cycle: "review",
    cycleLabel: "Review",
  },
  {
    key: "ifaDuration",
    label: "IFA Duration",
    cycle: "ifa",
    cycleLabel: "IFA",
  },
  {
    key: "approvalDuration",
    label: "Approval Duration",
    cycle: "approval",
    cycleLabel: "Approval",
  },
  {
    key: "masterDuration",
    label: "Master Duration",
    cycle: "masterDate",
    cycleLabel: "Master",
  },
  {
    key: "duration",
    label: "Total Duration",
    cycle: "deliverable",
    cycleLabel: "",
  },
];

const SetDuration = ({
  data,
  openDialog,
  onClose,
  holidayList,
  onRowChange,
  isDeliverable,
  handeDelDurationChange,
  saveAutoDel,
  handleChangedDate,
  updateIsDeliverable,
  isWorkflowCall,
  saveSubTaskdata,
  prevAutoScheduledDel,
  isAutoSchedule,
}) => {
  const [open, setOpen] = useState(true);
  const [deliverable, setDeliverable] = useState();
  useEffect(() => {
    if (openDialog && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  useEffect(() => {
    let reSchedule = true;

    if (isAutoSchedule) {
      let prevStateIndex = prevAutoScheduledDel.findIndex(
        (deliverable) => deliverable._id === data._id
      );

      if (
        Number(data?.duration) ===
        Number(prevAutoScheduledDel[prevStateIndex]?.duration)
      ) {
        reSchedule = false;
      }
    }

    if (data) {
      let updatedData = { ...data };

      if (reSchedule) {
        dataKeys.map((value) => {
          updatedData[value.key] = 0;
          if (value.key === "duration") {
            updatedData[value.key] = moment(data.end).diff(
              moment(data.start),
              "days"
            );
          }
          return updatedData;
        });
      }

      setDeliverable(updatedData);
      if (reSchedule) {
        setInitialDuration(updatedData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClose = () => {
    if (isAutoSchedule && !isDeliverable) {
      updateIsDeliverable(true);
    } else {
      setOpen(false);
      onClose();
    }
  };

  const setInitialDuration = (updatedData) => {
    dataKeys
      .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
      .forEach((val) => {
        if (val.key !== "duration") {
          if (updatedData?.duration >= 5) {
            updatedData[val.key] = 1;
            if (
              (val.key === "approvalDuration" && !updatedData?.master) ||
              val.key === "masterDuration"
            ) {
              updatedData[val.key] =
                updatedData?.duration -
                (updatedData?.master ? Number(5) : Number(4));
            }
          } else {
            updatedData[val.key] = 0;
            if (
              (val.key === "approvalDuration" && !updatedData?.master) ||
              val.key === "masterDuration"
            ) {
              updatedData[val.key] = updatedData?.duration;
            }
          }
        }
      });
    setInitialDates(updatedData);
  };

  const setInitialDates = (updatedData) => {
    dataKeys
      .filter((x) => (!updatedData?.master ? x.key !== "master" : x.key))
      .forEach((val, index) => {
        if (val.key !== "duration") {
          if (val.cycle === "draft") {
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              start: updatedData?.start,
            };
            const endDate = moment(
              updatedData?.[val.cycle]?.["start"]?.split("T")[0]
            )
              .add("days", updatedData[val.key])
              .format("YYYY-MM-DDT00:00:00.000[Z]");
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              end: endDate,
            };
          } else {
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              start: updatedData?.[dataKeys[index - 1]?.cycle]?.end,
            };
            const endDate = moment(
              updatedData?.[val.cycle]?.["start"]?.split("T")[0]
            )
              .add("days", updatedData[val.key])
              .format("YYYY-MM-DDT00:00:00.000[Z]");
            updatedData[val.cycle] = {
              ...updatedData[val.cycle],
              end: endDate,
            };
          }
        } else {
          const endDate = moment(updatedData?.start?.split("T")[0])
            .add("days", Number(updatedData.duration))
            .format("YYYY-MM-DDT00:00:00.000[Z]");
          updatedData = {
            ...updatedData,
            end: endDate,
          };
        }
        setDeliverable(updatedData);
      });
  };

  const calculateTotal = (data, name) => {
    let duration = 0;
    dataKeys
      .filter(
        (val) =>
          (!data.master ? val.key !== "masterDuration" : val.key) &&
          val.key !== "duration"
      )
      .forEach((val) => {
        if (name !== "duration") {
          duration = Number(duration) + Number(data[val.key] || 0);
        }
      });
    data.duration = duration;
    setInitialDates(data);
  };

  const setDateChange = (startDate, endDate, key) => {
    let reSchedule = true;
    let data = { ...deliverable };
    data.scheduled = false;
    if (key === "deliverable") {
      data.start = moment.utc(startDate).format("YYYY-MM-DDT00:00:00.000[Z]");
      data.end = moment.utc(endDate).format("YYYY-MM-DDT00:00:00.000[Z]");
      data.duration = moment(endDate).diff(moment(startDate), "days");
    }

    if (isAutoSchedule) {
      let prevStateIndex = prevAutoScheduledDel.findIndex(
        (deliverable) => deliverable._id === data._id
      );

      if (
        Number(data?.duration) ===
        Number(prevAutoScheduledDel[prevStateIndex]?.duration)
      ) {
        reSchedule = false;
      }
    }
    if (reSchedule) {
      setInitialDuration(data);
    } else {
      const updatedData = [data];
      autoScheduleSubTask(updatedData);
      setDeliverable(updatedData[0]);
    }
  };

  const handleDurationChange = ({ target: { name, value, type } }) => {
    if (value >= 0) {
      let data = { ...deliverable, [name]: value };
      if (name === "duration") {
        data = {
          ...data,
          end: moment(data.start?.split("T")[0])
            .add("days", value)
            .format("YYYY-MM-DDT00:00:00.000[Z]"),
        };
        setInitialDuration(data);
      } else {
        calculateTotal(data);
      }
    }
  };

  const subTaskhandleDateChange = (startDate, endDate, key) => {
    let data = { ...deliverable };
    data[key].start = moment
      .utc(startDate)
      .format("YYYY-MM-DDT00:00:00.000[Z]");
    data[key].end = moment.utc(endDate).format("YYYY-MM-DDT00:00:00.000[Z]");
    data[`${key}Duration`] = moment
      .utc(endDate)
      .diff(moment.utc(startDate), "days");

    const startIndex = dataKeys.findIndex((obj) => obj.cycle === key);
    for (let i = startIndex + 1; i < dataKeys.length; i++) {
      const currentPhase = dataKeys[i].cycle;
      const currentDuration = dataKeys[i].key;
      if (
        currentPhase !== "deliverable" &&
        (currentPhase !== "masterDate" || data.master)
      ) {
        const previousPhaseEnd = data[dataKeys[i - 1].cycle].end;
        data[currentPhase].start = previousPhaseEnd;
        data[currentPhase].end = moment
          .utc(previousPhaseEnd)
          .add(data[currentDuration], "days")
          .format("YYYY-MM-DDT00:00:00.000[Z]");
      }
    }
    data.duration =
      Number(data.draftDuration) +
      Number(data.ifrDuration) +
      Number(data.reviewDuration) +
      Number(data.ifaDuration) +
      Number(data.approvalDuration) +
      (data.master ? Number(data.masterDuration) : 0);

    data.start = moment
      .utc(data.draft.start)
      .format("YYYY-MM-DDT00:00:00.000[Z]");
    data.end = data.master
      ? moment.utc(data.masterDate.end).format("YYYY-MM-DDT00:00:00.000[Z]")
      : moment.utc(data.approval.end).format("YYYY-MM-DDT00:00:00.000[Z]");

    setDeliverable(data);
  };

  return (
    <Dialog open={open} fullWidth className="pd-10">
      <DialogTitle>
        {!isDeliverable
          ? `Please confirm duration for ${deliverable?.name}`
          : `Please confirm duration`}
      </DialogTitle>
      <div className="pd-20">
        {!isDeliverable && (
          <Grid container columns={12} className="mb-10" spacing={2}>
            <Grid item xs={8}>
              <DateRangePicker
                label={"deliverable"}
                startDate={moment(deliverable?.start?.split("T")[0])}
                endDate={moment(deliverable?.end?.split("T")[0])}
                duration={deliverable?.duration}
                holidayList={holidayList}
                hanldeDataChange={setDateChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                name="duration"
                label="Total Duration"
                value={deliverable?.duration}
                onChange={handleDurationChange}
              />
            </Grid>
          </Grid>
        )}

        {!isDeliverable &&
          dataKeys
            .filter((x) =>
              !deliverable?.master ? x.key !== "masterDuration" : x.key
            )
            .map((column, index) => (
              <div key={index} className="mb-10">
                {column.key !== "duration" && (
                  <>
                    <div className="mb-10">{column.cycleLabel}</div>
                    <Grid container columns={12} className="mb-10" spacing={2}>
                      <Grid item xs={8}>
                        <DateRangePicker
                          label={column.cycle}
                          startDate={moment(
                            deliverable?.[column.cycle]?.start?.split("T")[0]
                          )}
                          endDate={moment(
                            deliverable?.[column.cycle]?.end?.split("T")[0]
                          )}
                          duration={deliverable?.[column.key]}
                          holidayList={holidayList}
                          hanldeDataChange={
                            !isAutoSchedule
                              ? setDateChange
                              : subTaskhandleDateChange
                          }
                          readOnly={!isAutoSchedule ? true : false}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          key={column.key + index}
                          fullWidth
                          required
                          variant="outlined"
                          type="number"
                          label={column.label}
                          name={column.key}
                          value={deliverable?.[column.key]}
                          onChange={(e) =>
                            handleDurationChange({
                              target: {
                                name: column.key,
                                value: e.target.value,
                                type: column.cycle,
                              },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
              </div>
            ))}
        {isDeliverable &&
          data.map((deliverable, index) => (
            <div key={index} className="mb-10">
              <>
                <div className="mb-10">{deliverable.name}</div>
                <Grid container columns={12} className="mb-10" spacing={2}>
                  <Grid item xs={8}>
                    <DateRangePicker
                      label={deliverable.name}
                      index={index}
                      isDeliverable={isDeliverable}
                      startDate={moment(deliverable?.start?.split("T")[0])}
                      endDate={moment(deliverable?.end?.split("T")[0])}
                      duration={deliverable?.duration}
                      hanldeDataChange={handleChangedDate}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      key={deliverable._id + index}
                      fullWidth
                      required
                      variant="outlined"
                      type="number"
                      label={"Duration"}
                      name={deliverable.name}
                      value={deliverable?.duration}
                      onChange={(e) =>
                        handeDelDurationChange(e.target.value, index, data)
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {/* <button
                      onClick={() => {
                        updateIsDeliverable(false,index);
                      }}
                    >
                      button
                    </button> */}
                    {deliverable.type !== "EXECUTION" && (
                      <IconButton
                        className="pd-0 ml-10"
                        onClick={() => {
                          updateIsDeliverable(false, index);
                        }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </>
            </div>
          ))}
      </div>
      <div>
        <div className="d-jsb-r mb-10">
          <Button variant="contained" onClick={handleClose} className="mr-10">
            Cancel
          </Button>
          <Button
            className="mr-10"
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              !isDeliverable
                ? !isAutoSchedule
                  ? onRowChange(deliverable)
                  : saveSubTaskdata(deliverable)
                : saveAutoDel(isWorkflowCall ? true : false);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(SetDuration);
