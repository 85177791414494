import React, { memo } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Divider,
  Checkbox,
  Tooltip,
  Typography,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import { noop } from "utils/constants";
import { ArrowBack } from "@material-ui/icons";
import { ReactComponent as AddCircle } from "icons/add_circle.svg";
import { ReactComponent as CloseIcon } from "icons/cross_circle.svg";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AvatarGroup } from "@material-ui/lab";

const DeliverableNoteList = ({
  permissions,
  deliverable = {},
  close = noop,
  getNamebyId = noop,
  backToDel = noop,
  handleNoteCreate = noop,
  handleNoteUpdate = noop,
  showNotesDetail = noop,
  excluded = false,
}) => {
  const handleDataChange = (index) => {
    var data = deliverable.notes[index];
    data.status = !data.status;
    data.noteId = data._id;
    handleNoteUpdate(data);
  };

  const newNote = {
    type: "ACTION",
    description: "Description",
    date: moment(deliverable.start.split("T")[0]).format("MM/DD/YY"),
    resource: [],
    status: false,
  };

  return (
    <Card>
      <CardHeader
        className="al-flx-s"
        avatar={
          <IconButton className="pd-0" onClick={() => backToDel()}>
            <ArrowBack />
          </IconButton>
        }
        title={<>{deliverable.name}&nbsp;Notes</>}
        action={
          <div>
            {!excluded && (
              <Tooltip title="Add Note">
                <IconButton onClick={() => handleNoteCreate(newNote)}>
                  {/* <AddCircleOutlineRounded /> */}
                  <AddCircle />
                </IconButton>
              </Tooltip>
            )}
            <IconButton onClick={() => close()}>
              {/* <Close /> */}
              <CloseIcon />
            </IconButton>
          </div>
        }
      />
      <Divider className="mb-5" />
      <CardContent className="max-ht-620 scroll-y">
        {deliverable.notes.length > 0 &&
          deliverable.notes.map((note, index) => (
            <Grid
              container
              columns={10}
              key={index}
              className="card-sdw mb-10"
              spacing={2}
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={2}>
                <Tooltip title={note.type}>
                  <Typography
                    className="pointer txt-elps"
                    color="primary"
                    variant="subtitle2"
                    onClick={() => showNotesDetail(index)}
                  >
                    {note.type}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                {moment(note.date.split("T")[0]).format("MM/DD/YY")}
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  disabled={excluded}
                  className="pd-0"
                  checked={note.status}
                  onChange={() => handleDataChange(index)}
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip title={note.description}>
                  <div className="txt-elps">{note.description}</div>
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <AvatarGroup max={2} className="avatar">
                  {note.resource.map((uid) => (
                    <Tooltip title={getNamebyId("users", uid)} key={uid}>
                      <Avatar
                        alt={getNamebyId("users", uid)}
                        sx={{ bgcolor: "#5A83BB" }}
                      >
                        {getNamebyId("users", uid)?.replace(/[^A-Z]/g, "")}
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Grid>
            </Grid>
          ))}
        {deliverable.notes.length === 0 && (
          <div className="mb-5">No notes available</div>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(DeliverableNoteList);
