import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { ReactComponent as SOWTracker } from "icons/SOW tracker.svg";

const SowList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SowList' */ "pages/Sows/SowList")
  )
);
const SowEdit = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SowEdit' */ "pages/Sows/SowEdit")
  )
);
const SowAdd = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'SowAdd' */ "pages/Sows/SowAdd"))
);

const sowTrackerRoutes = Object.freeze({
  id: LINKS.SOWS.TITLE,
  pathId: "sows",
  path: LINKS.SOWS.HREF,
  icon: <SOWTracker/>,
  component: SowList,
  roles: LINKS.SOWS.ROLES,
  children: [
    {
      path: LINKS.EDIT_SOW.HREF,
      name: LINKS.EDIT_SOW.TITLE,
      component: SowEdit,
      roles: LINKS.EDIT_SOW.ROLES,
      // isNotSlide: true,
    },
    {
      path: LINKS.ADD_SOW.HREF,
      name: LINKS.ADD_SOW.TITLE,
      component: SowAdd,
      roles: LINKS.ADD_SOW.ROLES,
      // isNotSlide: true,
    },
  ],
});

export default sowTrackerRoutes;
