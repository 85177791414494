import { useSelector } from 'react-redux';

const useDisabledRoutes = (routes) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const accessedFeatures = useSelector((state) => state?.modulePreference?.accessibleFeature);
  const disabledRoutes = routes.filter((route) => !route.feature || !accessedFeatures?.includes(route.feature)).map((route) => {
    if(route.children){
      const children = route.children?.filter((child) => !child.feature || !accessedFeatures?.includes(child.feature));
      return { ...route, children }
    }
    return route
  })
  return disabledRoutes.filter(({ roles }) => !roles || roles.includes(currentUser.permissions));
};

export default useDisabledRoutes;
